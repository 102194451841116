import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationItem } from '@fuse/types';
import { AuthenticationService } from 'app/main/shared/services/authentication.service';
import { SistemaModuleInfoUtil } from 'app/main/shared/services/sistema-modulo-identifier.util';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-iframe',
    templateUrl: './iframe.component.html',
    styleUrls: ['./iframe.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class IFrameComponent implements OnInit, OnDestroy {
    urlSafe: SafeResourceUrl; 
    private item: FuseNavigationItem; 
    private _unsubscribeAll: Subject<any>; 
   
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _sanitizer: DomSanitizer,
        private _authenticationService: AuthenticationService,
        private _fuseNavigationService: FuseNavigationService,
        private _changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        this._unsubscribeAll = new Subject();

        this._fuseConfigService.config = {
            layout: {
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe((p)=> {
            if (p._redirectUrl) { 
                    this.parseUrl(p._redirectUrl);
            } else {
                this._fuseNavigationService.onCurrentNavigationItemChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(this.navigate.bind(this));
            }
        });
    }

    /**
     * Monta url baseada em convensão se não for uma url absoluta
     * @param url 
     */
    parseUrl(url: string) {
        let routeToUrl: string = null;
        if (!url.startsWith("http")) {
            routeToUrl = SistemaModuleInfoUtil.reverseRouteToUrl(url);
        } else {
            routeToUrl = url;
        } 
        if (routeToUrl.indexOf('%3A%2F%2')) 
            routeToUrl = decodeURIComponent(routeToUrl);

        this.urlSafe = this._sanitizer.bypassSecurityTrustResourceUrl(routeToUrl);
        this._changeDetectorRef.markForCheck();
    }

    private navigate(newItem: FuseNavigationItem) {       
        if (newItem == null || !newItem || !newItem.menu || !newItem.menu.urlIframe || newItem.menu.urlIframe.length <= 1 || (this.item && newItem && this.item.id === newItem.id)) {
            return;
        }
        const urlIframe = `${this.tratarUrl(newItem.menu.sistema.url)}/${this.tratarUrl(newItem.menu.urlIframe)}`;
        const token = this._authenticationService.currentUserValue.token;
        const urlFinal = `${urlIframe}/?accessToken=${token}&urlPortal=${environment.URL_ME}`;        
        this.urlSafe = this._sanitizer.bypassSecurityTrustResourceUrl(urlFinal);
        this._changeDetectorRef.markForCheck();
        this.item = newItem;
    }

    private tratarUrl(url: string): string {
        return url.substring(url.startsWith('/') ? 1 : 0, url.length - (url.endsWith('/') ? 1 : 0));
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

}
