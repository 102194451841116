import { OperacaoMenu } from "../menus/operacao-menu.model";

export class PerfilMenuDTO {
    constructor(
        public idPerfil?: number,
        public idMenu?: number,
        public idModulo?: number,
        public menu?: any,
        public operacoes?: PerfilOperacaoMenuDTO[],
        public operacoesPerfil?: PerfilOperacaoMenuDTO[],
    ) {
    }

    static fromJson(json: any): PerfilMenuDTO {
        if (json === undefined || json === null) {
            return null;
        }

        return new PerfilMenuDTO(
            json.idPerfil,
            json.idMenu,
            json.idModulo,
            json.menu,
            PerfilOperacaoMenuDTO.fromJsons(json.operacoes),
            PerfilOperacaoMenuDTO.fromJsons(json.operacoesPerfil),

        );
    }

    static fromJsons(jsonArray: any): PerfilMenuDTO[] {
        const resources = [];
        if (jsonArray === undefined || jsonArray === null) {
            return [];
        } else {
            jsonArray.forEach(json => resources.push(PerfilMenuDTO.fromJson(json)));
            return resources;
        }
    }
}

export class PerfilOperacaoMenuDTO {
    constructor(
        public id?: number,
        public idOperacaoMenu?: number,
        public operacaoMenu?: OperacaoMenu,
        public operacao?: string
    ) {
    }

    static fromJson(json: any): PerfilOperacaoMenuDTO {
        if (json === undefined || json === null) {
            return null;
        }

        return new PerfilOperacaoMenuDTO(
            json.id || json.idOperacaoMenu,
            json.idOperacaoMenu || json.id,
            json.operacaoMenu && json.operacaoMenu.operacao ? json.operacaoMenu.operacao : json.operacao,
            json.operacao
        );
    }

    static fromJsons(jsonArray: any): PerfilOperacaoMenuDTO[] {
        const resources = [];
        if (jsonArray === undefined || jsonArray === null) {
            return [];
        } else {
            jsonArray.forEach(json => resources.push(PerfilOperacaoMenuDTO.fromJson(json)));
            return resources;
        }
    }
}
