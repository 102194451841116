import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
    selector: 'app-register-info-dialog',
    templateUrl: './register-info-dialog.component.html',
    styleUrls: [
        './register-info-dialog.component.scss'
    ],
})
export class RegisterInfoDialog {
    constructor(
        public dialogRef: MatDialogRef<RegisterInfoDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {

    }
   
    ok(): void {
        this.dialogRef.close();
    }

}