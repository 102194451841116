import {Perfil} from './../../pages/perfis/perfil.model';
import {Usuario} from './../../pages/usuarios/usuario.model';
import {Injectable, Injector} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';
import {PerfilUsuarioDTO} from '../models/perfil-usuario-dto.model';
import {AdicionarPerfilUsuarioModel} from '../../pages/perfis/dialog-adicionar-perfil-usuario/adicionar-perfil-usuario.model';

@Injectable({
    providedIn: 'root'
})
export class PerfilUsuarioService {

    private http: HttpClient;
    private urlResource: string = environment.URL_GATEWAY;

    constructor(
        private injector: Injector,
    ) {
        this.http = injector.get(HttpClient);
        this.urlResource += '/api/perfis-usuario';
    }

    getAllByUsuario(usuario: Usuario): Observable<PerfilUsuarioDTO[]> {
        const url = `${this.urlResource}/usuarios/${usuario.id}`;
        return this.http.get(url).pipe(
            map(this.jsonDataToPerfilUsuarioDTOList),
            catchError(this.handleError)
        );
    }

    addPerfilToUsuario(perfil: Perfil, usuario: Usuario): Observable<PerfilUsuarioDTO> {
        const dto: PerfilUsuarioDTO = {idPerfil: perfil.id, idUsuario: usuario.id};
        return this.http
            .post(this.urlResource, dto)
            .pipe(
                catchError(this.handleError),
                map(this.jsonDataToPerfilUsuarioDTO),
            );
    }

    addPerfilUsingUserLogin(adicionarPerfilUsuarioModel: AdicionarPerfilUsuarioModel): Observable<any> {
        return this.http
            .post(`${this.urlResource}/create/`, adicionarPerfilUsuarioModel)
            .pipe(catchError(this.handleError), map(this.jsonDataToAdicionarPerfilUsuarioDTO));
    }

    removePerfilFromUsuario(perfilUsuario: PerfilUsuarioDTO): Observable<any> {
        const url = `${this.urlResource}/perfis/${perfilUsuario.idPerfil}/usuarios/${perfilUsuario.idUsuario}`;
        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError)
        );
    }

    private jsonDataToPerfilUsuarioDTOList(jsonData: any[]): PerfilUsuarioDTO[] {
        const entries: PerfilUsuarioDTO[] = [];

        jsonData.forEach(element => {
            const rt = Object.assign(new PerfilUsuarioDTO(), element);
            entries.push(rt);
        });
        return entries;
    }

    private jsonDataToPerfilUsuarioDTO(jsonData: any): PerfilUsuarioDTO {
        return Object.assign(new PerfilUsuarioDTO(), jsonData);
    }

    private jsonDataToAdicionarPerfilUsuarioDTO(jsonData: any): AdicionarPerfilUsuarioModel {
        return Object.assign(new AdicionarPerfilUsuarioModel(), jsonData);
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }

}
