import { Component, Injector, ChangeDetectionStrategy, OnInit, ViewChild, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { BaseDetailComponent } from 'app/main/shared/components/base-detail/base-detail.component';
import { Validators, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { ModuloService } from '../modulo.service';
import { Modulo } from '../modulo.model';
import { Sistema } from '../../sistemas/sistema.model';
import { SistemaService } from '../../sistemas/sistema.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { MATERIAL_OUTLINE_ICONS_VALUES } from 'assets/icons/material-icons/outline/fonts/material-outline-icons';
import { MatSelect } from '@angular/material';
import { filter, debounceTime } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-modulo-detail',
    templateUrl: './modulo-detail.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class ModuloDetailComponent extends BaseDetailComponent<Modulo> implements OnInit, AfterViewInit {

    sistemas: Array<Sistema>;
    sistema: Sistema = null;

    private icones = [...MATERIAL_OUTLINE_ICONS_VALUES];
    public iconesFiltrados = [];
    public offsetIcones = 0;
    public optionsIcones = new BehaviorSubject<string[]>([]);

    iconeFiltroControl = new FormControl();

    @ViewChild('selectIcone', { static: false }) selectIcone: MatSelect;

    constructor(
        protected moduloService: ModuloService,
        private sistemaService: SistemaService,
        protected injector: Injector
    ) {
        super(injector, new Modulo(), moduloService, Modulo.fromJson);
        this.iconeFiltroControl.valueChanges.pipe(filter(filtro => filtro.length >= 3), debounceTime(500)).subscribe(() => this.recarregarIcones());
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.carregarSistemas().subscribe(
                sistemas => {
                    this.sistemas = sistemas;
                    if (this.activatedRoute.snapshot.queryParams && this.activatedRoute.snapshot.queryParams['sistemaId'] ) {
                        const sistemaId = this.activatedRoute.snapshot.queryParams['sistemaId'];
                        this.sistema = this.sistemas.find((s) => s.id == sistemaId);
                        this.buildResourceForm();
                        if (this.resource.chaveSegPerfil == null) {
                            this.form.markAsDirty();
                        }
                    }
                }
        );
        if (this.resource  && this.resource.id &&  this.resource.chaveSegPerfil == null) {
             
            this.service.update(this.form.value).subscribe(
                resource => { 
                    this.actionsForSuccess(resource)
                    this.service.getById(this.resource.id).subscribe((r)=>{ this.resource = r; this.form.patchValue(this.resource); })
                },
                error => this.actionsForError(error)
            );

        }
    }

    ngAfterViewInit(): void {
        this.selectIcone._keyManager.change.isStopped = true;
        if (this.resource.chaveSegPerfil == null) {
            this.form.markAsDirty();
        }
    }

    protected buildResourceForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            nome: [null, [Validators.required, Validators.maxLength(255)]],
            chaveSegPerfil: [null, [Validators.maxLength(255)]],
            posicao: [null, [Validators.required, Validators.min(1), Validators.maxLength(2), Validators.pattern(/^[0-9]\d*$/)]],
            icone: [null],
            descricao: [null, [Validators.maxLength(255)]],
            sistema: [ this.sistema, [Validators.required]],
            mensagem: [null, [Validators.maxLength(500)]],
            pular: [null],
            dataInicio: [{value:null, disabled:true}],
            dataFim: [null]
        });
    }

    protected afterResourceLoad() {
        this.recarregarIcones();
    }

    private recarregarIcones() {
        this.optionsIcones.next([]);
        if (this.form.value.icone) {
            this.icones = [...MATERIAL_OUTLINE_ICONS_VALUES].filter(icone => icone !== this.form.value.icone);
            this.icones.unshift(this.form.value.icone);
        }
        this.offsetIcones = 0;
        this.carregarMaisIcones();
    }

    carregarMaisIcones() {
        let filtro = this.iconeFiltroControl.value;
        if (filtro && filtro.length >= 3) {
            filtro = filtro.toLowerCase();
            this.iconesFiltrados = this.icones.filter(icone => icone.toLowerCase().indexOf(filtro.toLowerCase()) > -1);
        } else {
            this.iconesFiltrados = [...this.icones];
        }
        this.optionsIcones.next(this.optionsIcones.value.concat(this.iconesFiltrados.slice(this.offsetIcones, this.offsetIcones + 10)));
        this.offsetIcones = this.optionsIcones.value.length;
    }

    protected creationPageTitle(): string {
        return 'Cadastro de novo módulo';
    }

    protected editionPageTitle(): string {
        const resourceName = this.resource.nome || '';
        return 'Editando o módulo: ' + resourceName;
    }

    protected visualizationPageTitle(): string {
        const resourceName = this.resource.nome || '';
        return 'Visualizando o módulo: ' + resourceName;
    }

    private carregarSistemas(): Observable<any> {
        return this.sistemaService.getAll();
    }

    public get ambienteDev() {
        return environment.name && environment.name.toUpperCase().indexOf('DEV') != -1;
    }
}
