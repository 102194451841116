import { Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { PessoaFisica } from '../models/pessoa-fisica.model';

@Injectable({
    providedIn: 'root'
})
export class PessoaFisicaService {
    private http: HttpClient;
    private urlResource = environment.URL_SCORP_API;

    constructor(private injector: Injector) {
        this.http = injector.get(HttpClient);
        this.urlResource += '/api/pessoas-fisicas';
    }

    getByCpf(cpf: string): Observable<PessoaFisica> {
        const url = `${this.urlResource}/cpf/${cpf}`;

        return this.http.get(url).pipe(
            map(response => PessoaFisica.fromJson(response)),
            catchError(this.handleError.bind(this))
        );
    }

    update(pessoaFisica: PessoaFisica): Observable<PessoaFisica> {
        const url = `${this.urlResource}/${pessoaFisica.id}`;
        return this.http.put(url, pessoaFisica).pipe(
            map(() => pessoaFisica),
            catchError(this.handleError)
        );
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }
}
