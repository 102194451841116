import {Component, Inject, OnInit} from '@angular/core';
import {SnackBarService} from "../../../shared/services/snack-bar/snack-bar.service";

import {DialogConfirmacaoComponent, SistemaModuloDTOData} from "../dialog-confirmacao/dialog-confirmacao.component";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-dialog-remover-permissao-usuario',
  templateUrl: './dialog-remover-permissao-usuario.component.html',
})
export class DialogRemoverPermissaoUsuarioComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<DialogConfirmacaoComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    confirm() {
        this.dialogRef.close(true);
    }
}
