import { BaseModel } from 'app/main/shared/models/base.model';
import { Pessoa } from './pessoa.model';

export class Contato extends BaseModel {
    constructor(
        public id?: number,
        public dataInicio?: Date,
        public dataFim?: Date,
        public pessoa?: Pessoa
    ) {
        super();
    }

    static fromJson(json: any): Contato {
        if (json === undefined || json === null) {
            return null;
        }
        return new Contato(
            json.id,
            json.dataInicio,
            json.dataFim,
            Pessoa.fromJson(json.pessoa)
        );
    }
}
