import {AfterViewInit, ChangeDetectionStrategy, Component, Injector, ViewChild, ViewEncapsulation} from '@angular/core';
import {BaseDetailComponent} from 'app/main/shared/components/base-detail/base-detail.component';
import {FormControl, Validators} from '@angular/forms';
import {fuseAnimations} from '@fuse/animations';
import {SistemaService} from '../sistema.service';
import {Sistema} from '../sistema.model';
import {Usuario} from '../../usuarios/usuario.model';
import {MATERIAL_OUTLINE_ICONS_VALUES} from 'assets/icons/material-icons/outline/fonts/material-outline-icons';
import {BehaviorSubject, Observable} from 'rxjs';
import {MatSelect} from '@angular/material';
import {catchError, debounceTime, filter} from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UtilService } from 'app/main/core/services/util.service';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-sistema-detail',
    templateUrl: './sistema-detail.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class SistemaDetailComponent extends BaseDetailComponent<Sistema> implements AfterViewInit {

    usuarios: Usuario[];

    private icones = [...MATERIAL_OUTLINE_ICONS_VALUES];
    public iconesFiltrados = [];
    public offsetIcones = 0;
    public optionsIcones = new BehaviorSubject<string[]>([]);

    iconeFiltroControl = new FormControl();

    @ViewChild('selectIcone', {static: false}) selectIcone: MatSelect;
 
    constructor(
        protected sistemaService: SistemaService,
        protected injector: Injector,
        protected http: HttpClient, 
    ) {
        super(injector, new Sistema(), sistemaService, Sistema.fromJson);
        this.iconeFiltroControl.valueChanges.pipe(filter(filtro => filtro.length >= 3), debounceTime(500)).subscribe(() => this.recarregarIcones());
    }

    ngAfterViewInit(): void {
        if (this.selectIcone && this.selectIcone._keyManager)
            this.selectIcone._keyManager.change.isStopped = true;
        if (this.resource && this.resource.id && this.resource.chaveSegPerfil == null) {
            this.service.update(this.form.value).subscribe(
                resource => { 
                    this.actionsForSuccess(resource)
                    this.service.getById(this.resource.id).subscribe((r)=>{ this.resource = r; this.form.patchValue(this.resource); })
                },
                error => this.actionsForError(error)
            );
        } 
    }

    protected afterResourceLoad(): void {
        this.recarregarIcones();
    }

    protected buildResourceForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            nome:[null, [Validators.required, Validators.maxLength(255)]],
            chaveSegPerfil: [null,  [Validators.maxLength(255)]],
            posicao: [null, [Validators.required, Validators.min(1), Validators.maxLength(2), Validators.pattern(/^[0-9]\d*$/)]],
            icone: [null],
            descricao: [null, [Validators.maxLength(255)]],
            mensagem: [null, [Validators.maxLength(500)]],
            urlLogotipo: [null, [Validators.maxLength(255)]],
            url: [null, [Validators.required, Validators.maxLength(255)]],
            pular: [null],
            mostrarNoDashboard: [null],
            dataInicio: [{value: null, disabled: true}],
            dataFim: [null]
        });
    }

    private recarregarIcones() {
        this.optionsIcones.next([]);
        if (this.form.value.icone) {
            this.icones = [...MATERIAL_OUTLINE_ICONS_VALUES].filter(icone => icone !== this.form.value.icone);
            this.icones.unshift(this.form.value.icone);
        }
        this.offsetIcones = 0;
        this.carregarMaisIcones();
    }

    carregarMaisIcones() {
        let filtro = this.iconeFiltroControl.value;
        if (filtro && filtro.length >= 3) {
            filtro = filtro.toLowerCase();
            this.iconesFiltrados = this.icones.filter(icone => icone.toLowerCase().indexOf(filtro.toLowerCase()) > -1);
        } else {
            this.iconesFiltrados = [...this.icones];
        }
        this.optionsIcones.next(this.optionsIcones.value.concat(this.iconesFiltrados.slice(this.offsetIcones, this.offsetIcones + 10)));
        this.offsetIcones = this.optionsIcones.value.length;
    }

    get achouUsuarios(): boolean {
        return typeof this.usuarios !== 'undefined' && this.usuarios.length > 0;
    }

    protected creationPageTitle(): string {
        return 'Cadastro de novo sistema';
    }

    protected editionPageTitle(): string {
        const resourceName = this.resource.nome || '';
        return 'Editando o sistema: ' + resourceName;
    }

    protected visualizationPageTitle(): string {
        const resourceName = this.resource.nome || '';
        return 'Visualizando o sistema: ' + resourceName;
    }

    public get uuid() {
        return UtilService.getGuid();
    }
    public get ambienteDev() {
        return environment.name && environment.name.toUpperCase().indexOf('DEV') != -1;
    }
    
}

