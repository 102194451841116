import { Injectable, EventEmitter } from '@angular/core';
import { timer } from 'rxjs';
import { UtilService } from './util.service';
import { now, Moment } from 'moment';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material';
import { environment } from 'environments/environment';

export enum ISEVERITY {
    SUCCESS = 0,
    INFO = 1,
    WARN = 2,
    ERROR = 3
}
export interface IMESSAGE {
    message: string;
    description?: string;
    showDescription?: boolean;
    severity?: ISEVERITY;
    timeout?: number;
    created?: moment.Moment;
    visible?: boolean;
    id?: string;
}

@Injectable({
    providedIn: 'root'
})
export class CoreMessageService {
    public static INSTANCE: CoreMessageService = null;
    constructor(private _matSnackBar: MatSnackBar) {
        if (CoreMessageService.INSTANCE == null) {
            CoreMessageService.INSTANCE = this;
        } else {
            return;
        }
        this.globalEventEmitter = new EventEmitter();
        timer(CoreMessageService.TIMEOUT, 3000).subscribe(t => {
            this.checkMessages();
        });
        if (environment.name && environment.name.toUpperCase().indexOf('DEV') != -1)
            console.debug('CoreMessageService', CoreMessageService.INSTANCE);
    }

    public static TIMEOUT = 10000;

    messages: IMESSAGE[] = [];
    globalEventEmitter: EventEmitter<string> = new EventEmitter();

    add(message: IMESSAGE): void {
        message.id = UtilService.getGuid();
        message.created = moment();
        message.severity =
            message.severity != null ? message.severity : ISEVERITY.INFO;
        message.showDescription = message.showDescription || false;
        message.timeout =
            message.timeout != null
                ? message.timeout
                : CoreMessageService.TIMEOUT;
        message.visible = true;
        this.messages.push(message);
        this.globalEventEmitter.emit('update');
    }

    clear(): void {
        this.messages = [];
    }
    checkMessages(): void {
        const currentTime: Moment = moment();
        if (this.messages != null) {
            this.messages.forEach(m => {
                if (m.visible) {                     
                    this._matSnackBar.open(m.message,null, {
                         duration: m.timeout || 5000,
                         verticalPosition: 'top',
                         horizontalPosition: 'center', 
                         panelClass: m.severity.toString() 
                        });
                    m.visible = false;
                }
                if (
                    m.created != null &&
                    currentTime.diff(m.created) > m.timeout
                ) {
                    m.visible = false;
                    
                    this.globalEventEmitter.emit('update');
                }
            });
            this.messages = this.messages.filter(m => m.visible);
        }
    }

    // Emissor Global de Eventos, os controllers pode assinar para receber e tratar mensagem
    getGlobalEventEmmiter(): EventEmitter<string> {
        return this.globalEventEmitter;
    }
}
