import { HttpParams } from '@angular/common/http';
import { Component, ViewEncapsulation, Injector, OnInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { BaseListComponent } from 'app/main/shared/components/base-list/base-list.component';
import { PerfilService } from '../perfil.service';
import { Perfil } from '../perfil.model';
import { DialogClonarPerfilComponent } from '../dialog-clonar-perfil/dialog-clonar-perfil.component';

@Component({
    selector: 'app-perfil-list',
    templateUrl: './perfil-list.component.html',
    styleUrls: [ './perfil-list.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class PerfilListComponent extends BaseListComponent<Perfil> implements OnInit {
    
  descricao: string;
    
    constructor(injector: Injector, service: PerfilService) {
        super(injector, ['id', 'descricao', 'delegavel','sigla_urn', 'ativo'], service);
    }

    ngOnInit(): void {
      super.ngOnInit();
    }

    fillFilters(httpParams: HttpParams): HttpParams {
        if (typeof this.descricao !== 'undefined' && this.descricao.length > 0){
            httpParams = httpParams.set('descricao', this.descricao);
        }
        return httpParams;
    }

    clonarPerfilDialog(perfil: Perfil): void {
        const dialogRef = this.dialog.open(DialogClonarPerfilComponent, {
          width: '400px',
          data: {descricaoNovoPerfil: null, perfil: perfil}
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result){
            this.list(0);
          }
        });
      }
}
