import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {FuseConfigService} from '@fuse/services/config.service';
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'forgot-confirm',
    templateUrl: './forgot-confirm.component.html',
    styleUrls: ['./forgot-confirm.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ForgotConfirmComponent implements OnInit {
    public email: string;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private activatedRoute: ActivatedRoute
    ) {
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe(params => {
            this.email = params['email'] || 'vazio';
        });
    }

}
