import { BaseModel } from 'app/main/shared/models/base.model';
import { Sistema } from '../sistemas/sistema.model';

export class Modulo extends BaseModel {
    constructor(
        public id?: number,
        public nome?: string,
        public chaveSegPerfil?: string,
        public posicao?: number,
        public icone?: string,
        public descricao?: string,
        public sistema?: Sistema,
        public mensagem?: string,
        public pular?: boolean,
        public ativo?: boolean,
        public dataInicio?: Date,
        public dataFim?: Date
    ) {
        super();
    }
    static fromJson(json: any): Modulo {
        if (json === undefined || json === null) {
            return null;
        }
        return new Modulo(
            json.id, 
            json.nome,
            json.chaveSegPerfil,
            json.posicao,
            json.icone,
            json.descricao,
            Sistema.fromJson(json.sistema),
            json.mensagem,
            json.pular,
            json.ativo,
            json.dataInicio,
            json.dataFim,
        );
    }
}