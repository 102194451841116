import {PessoaService} from 'app/main/core/services/pessoa.service';
import {CurrentUser} from '../models/current-user.model';
import {catchError, map, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {Usuario} from '../../pages/usuarios/usuario.model';
import {environment} from '../../../../environments/environment';
import {CurrentUserToken} from "../models/current-user-token.model";
import {AuthenticatedUserService} from './authenticated-user.service';
import {Router} from '@angular/router';
import {SnackBarService} from './snack-bar/snack-bar.service';

//import * as jwt_decode from 'jwt-decode';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
    private currentUser: CurrentUser = null;

    private currentUserTokenSubject: BehaviorSubject<CurrentUserToken>;
    public currentUserToken: Observable<CurrentUserToken>;

    private urlResource = `${environment.URL_GATEWAY}/api/auth`;    
    private _redirectUrl: string = null;

    get redirectUrl() {
        return this._redirectUrl;
    }
    set redirectUrl(url: string) {
        this._redirectUrl = url;
    }


    constructor(
        private http: HttpClient,
        protected snackBarService: SnackBarService,
        private pessoaService: PessoaService,
        private router: Router,
        private authenticatedUserService: AuthenticatedUserService
    ) {
        this.currentUserTokenSubject = new BehaviorSubject<CurrentUserToken>(null);

        this.refreshUserInfo();
    }

    public get currentUserValue(): CurrentUserToken {
        this.refreshUserInfo();

        return this.currentUserTokenSubject.value;
    }

    public get currentUserDecode(): CurrentUser {
        //return this.currentUserValue ? CurrentUser.fromJson(jwt_decode(this.currentUserValue.token)) : null;
        this.refreshUserInfo();

        return this.currentUser;
    }

    public get cadastroCompleto(): Observable<boolean> {
        return  this.pessoaService.cadastroCompletoUser.pipe(
            switchMap((simplificado) =>  this.pessoaService.isCadastroCompleto().pipe(map((completo) => simplificado || completo)))
        )
    }

    private refreshUserInfo() {
        let currentUserToken: CurrentUserToken = null;
        let currentUser: CurrentUser = null;
        // console.log('refreshUserInfo',this.currentUser,'token=',this.authenticatedUserService.identityToken, 'authenticated=', this.authenticatedUserService.isAuthenticated, 'tokenValue=',this.authenticatedUserService.identityTokenValue);
        
        if (this.authenticatedUserService.isAuthenticated) {
            const identityToken = this.authenticatedUserService.identityToken;
            const identityTokenValue = this.authenticatedUserService.identityTokenValue;

            currentUserToken = new CurrentUserToken(identityTokenValue);

            currentUser = new CurrentUser(
                identityToken.sub,
                identityToken.details.nome,
                identityToken.details.servidor
            );
        }

        this.currentUserTokenSubject.next(currentUserToken);

        this.currentUserToken = this.currentUserTokenSubject.asObservable();
        this.currentUser = currentUser;

        
    }

    login(body: string): Observable<any> {
        return this
            .http
            .post<any>(this.urlResource, body)
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                //if (user && user.token) {
                if (user) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    // localStorage.setItem('currentUser', JSON.stringify(user));
                    this.refreshUserInfo();
                }
                return user;
            }));
    }

    logout(): Observable<any> {
        // remove user from local storage to log user out
        const url: string = `${this.urlResource}/logout`;

        return this.http
            .post<any>(url, null)
            .pipe(
                map(_ => {
                    //localStorage.removeItem('currentUser');
                    //localStorage.removeItem('cadastroCompleto');
                    this.currentUserTokenSubject.next(null);
                })
            );        
    }

    getUser(): Observable<Usuario> {
        return this.http.get(`${this.urlResource}/me`)
            .pipe(map(user => Object.assign(new Usuario(), user)),
                catchError(this.handleError.bind(this)));
    }

    //loginWithToken(jwt): Observable<Usuario> {
    //    if (jwt) {
    //        const userData: any = {accessToken: jwt};
    //        this.currentUserSubject.next(userData);
    //        return this.getUser();
    //    }
    //    return of(null);
    //}

    navigation(): Observable<any[]> {
        return this.http.get(`${this.urlResource}/navigation`) as Observable<any[]>;
    }

    protected handleError(error: any): Observable<any> {
        return of(
            this.router
                .navigateByUrl('/', { skipLocationChange: true })
                .then(() => this.router.navigate(['/login']))
        );        
    }

    
}
