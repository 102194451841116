import {BaseModel} from "../../shared/models/base.model";
import {Menu} from "./menu.model";
import {BaseEnum} from "../../shared/models/base.enum ";

export class OperacaoMenu extends BaseModel {
    constructor(
        public id?: number,
        public operacao?: string,
        public chaveSegPerfil?: string,
        public menu?: Menu,
        public dataInicio?: Date,
        public dataFim?: Date,
    ) {
        super();
    }

    compareFromBaseEnum(b: BaseEnum): boolean {
        return this.operacao == b.valor;
    }

    static fromJson(json: any): OperacaoMenu {
        if (json === undefined || json === null) return null;
        return new OperacaoMenu(
            json.id,
            json.operacao,
            json.chaveSegPerfil,
            Menu.fromJson(json.menu),
            json.dataInicio,
            json.dataFim
        );
    }

    static fromJsons(json: any): OperacaoMenu[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(OperacaoMenu.fromJson(j)));
            return resources;
        }
    }
}