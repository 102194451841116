import {BaseModel} from "../../../shared/models/base.model";

export class AdicionarPerfilUsuarioModel extends BaseModel{
    constructor(
        public idPerfil?: number,
        public usuarioLogin?: string,

    ) {
        super();
    }

    static fromJson(json: any): AdicionarPerfilUsuarioModel {
        if (json === undefined || json === null){
            return null;
        }
        return new AdicionarPerfilUsuarioModel(
            json.idPerfil,
            json.usuarioLogin,
        );
    }
}