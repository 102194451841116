import { HttpParams } from '@angular/common/http';
import { Component, Injector, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { BaseListComponent } from '../../../shared/components/base-list/base-list.component';
import { UsuarioApiKey } from '../api-key.model';
import { UsuarioApiKeyService } from '../api-key.service';

@Component({
    selector: 'app-api-key-list',
    templateUrl: './api-key-list.component.html',
    styleUrls: ['./api-key-list.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class UsuarioApiKeyListComponent extends BaseListComponent<UsuarioApiKey> {
    public descricao: string = '';

    constructor(
        injector: Injector,
        private usuarioApiKeyService: UsuarioApiKeyService
    ) {
        super(injector, ['id', 'dataInicio', 'situacao', 'usuarioNome', 'descricao', 'buttons'], usuarioApiKeyService);        
    }  

    ngOnInit(): void {
        super.ngOnInit();

        this.loadApiKeys();        
    }

    excluir(apiKey: UsuarioApiKey): void {
        const onSuccess = () => {
            this.loadApiKeys();

            this.snackBarService.showSuccess('Chave de API excluída com sucesso.');
        }

        this.usuarioApiKeyService
            .deleteApiKey(apiKey)
            .subscribe(
                onSuccess.bind(this),
                this.onError.bind(this)
            );
    }    

    fillFilters(httpParams: HttpParams): any {
        if (httpParams == null) {
            httpParams = new HttpParams();
        }

        httpParams = httpParams.append('descricao', this.descricao);

        return httpParams;
    }

    private loadApiKeys(): void {
        this.dataSource.list(new HttpParams());
    }

    private onError(error): void {
        this.snackBarService.showError(error || 'Oops! ocorreu um problema inesperado.');
    }

}
