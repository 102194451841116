import { Grupo } from 'app/main/pages/grupos/grupo.model';

export class GrupoUsuarioDTO {
    constructor(
        public idGrupo?: number,
        public idUsuario?: number,
        public grupo?: Grupo
    ) {}

    static fromJson(json: any): GrupoUsuarioDTO {
        if (json === undefined || json === null) {
            return null;
        }
        return new GrupoUsuarioDTO(
            json.idGrupo,
            json.idUsuario,
            Grupo.fromJson(json.grupo)
        );
    }
}
