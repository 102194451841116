import { Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {PerfilService} from '../perfil.service';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {SistemaModuloDTO} from '../sistema-modulo-dto.model';
import {PerfilMenuDTO} from '../perfil-menu-dto.model';
import {SnackBarService} from '../../../shared/services/snack-bar/snack-bar.service';

export interface SistemaModuloDTOData {
    sistemaModuloDTO: SistemaModuloDTO;
}

@Component({
    selector: 'app-dialog-clonar-perfil',
    templateUrl: './dialog-confirmacao.component.html',
    styleUrls: ['./dialog-confirmacao.component.css'],
    animations: fuseAnimations
})
export class DialogConfirmacaoComponent  {

    constructor(
        public dialogRef: MatDialogRef<DialogConfirmacaoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: SistemaModuloDTOData,
        private perfilService: PerfilService,
        private snackBarService: SnackBarService
    ){}

    onNoClick(): void {
        this.dialogRef.close(false);
    }


    confirm(): void {
        this.perfilService.vincularMenuPerfil(new PerfilMenuDTO(this.data.sistemaModuloDTO.idPerfil, this.data.sistemaModuloDTO.idMenu)).subscribe(
            () => {
                this.snackBarService.showSuccess('Registro adicionado com sucesso!');
                this.dialogRef.close(true);
            }
        );
    }


}
