import {BaseModel} from '../../shared/models/base.model';
import { BaseEnum } from 'app/main/shared/models/base.enum ';

export class Perfil extends BaseModel{
    constructor(
        public id?: number,
        public chaveSegPerfil?: string,
        public descricao?: string,
        public delegavel?: boolean,
        public dataInicio?: Date,
        public dataFim?: Date,
        public ativo?: boolean

    ) {
        super();
    }

    static fromJson(json: any): Perfil {
        if (json === undefined || json === null){
            return null;
        } 
            
        return new Perfil(
            json.id,
            json.chaveSegPerfil,
            json.descricao,
            json.delegavel,
            json.dataInicio,
            json.dataFim,
            json.ativo
        );
    }

    static fromJsons(jsonArray: any): Perfil[] {
        const resources = [];
        if (jsonArray === undefined || jsonArray === null) {
            return [];
        } else {
            jsonArray.forEach(json => resources.push(Perfil.fromJson(json)));
            return resources;
        }
    }
}
