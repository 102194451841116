import {AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import {MatSelect} from '@angular/material';
import {fuseAnimations} from '@fuse/animations';
import {BaseModel} from 'app/main/shared/models/base.model';
import {Modulo} from '../../modulos/modulo.model';
import {Sistema} from '../../sistemas/sistema.model';
import {SistemaService} from '../../sistemas/sistema.service';
import {Menu} from '../menu.model';
import {MenuService} from '../menu.service';
import {ModuloService} from './../../modulos/modulo.service';
import {MATERIAL_OUTLINE_ICONS_VALUES} from 'assets/icons/material-icons/outline/fonts/material-outline-icons';
import {BehaviorSubject} from 'rxjs';
import {debounceTime, filter, map} from 'rxjs/operators';

import {OperacaoMenu} from '../operacao-menu.model';
import {ConfirmarExclusaoDialogComponent} from "../../../shared/components/confirmar-exclusao-dialog/confirmar-exclusao-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {MatTabGroup} from "@angular/material/tabs";
import {SnackBarService} from 'app/main/shared/services/snack-bar/snack-bar.service';
import {BaseEnum} from "../../../shared/models/base.enum ";
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { environment } from 'environments/environment';

export const urlIframaValidator: ValidatorFn = (
    control: AbstractControl
): ValidationErrors | null => {
    if (!control.parent || !control) {
        return null;
    }

    const modulo = control.parent.get('modulo').value;
    const urlIframe = control.parent.get('urlIframe').value;
    
    if (modulo === null) {
        return null;
    }

    if (modulo.sistema.nome && modulo.sistema.nome.toLowerCase().includes('portal')) {
        return null;
    }

    if (urlIframe && urlIframe.length > 0 && modulo.sistema.nome && !modulo.sistema.nome.toLowerCase().includes('portal')) {
        return null;
    }

    return {urlInvalida: true};
};

@Component({
    selector: 'app-menu-detail',
    templateUrl: './menu-detail.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations
})
export class MenuDetailComponent implements AfterViewInit {
    _menu: Menu = null;

    public menus: Menu[];
    public sistemas: Sistema[];
    public modulos: Modulo[];
    private icones = [...MATERIAL_OUTLINE_ICONS_VALUES];
    public iconesFiltrados = [];
    public offsetIcones = 0;
    public optionsIcones = new BehaviorSubject<string[]>([]);

    operacoes: OperacaoMenu[] = [];

    form: FormGroup;
    sistemaControl = new FormControl(null, Validators.required);
    iconeFiltroControl = new FormControl();

    formOperacao: FormGroup;
    formOperacaoChaveSegPerfilUpdate: FormControl = new FormControl([null, [Validators.required, Validators.maxLength(255)]]);

    comparador = (a: BaseModel, b: BaseModel) =>
        a && b ? a.id === b.id : a === b;

    @ViewChild('selectIcone', {static: false}) selectIcone: MatSelect;

    @ViewChild(MatTabGroup, {static: false}) tabGroup: MatTabGroup;

    @Output() public onChange: EventEmitter<string> = new EventEmitter<string>();
    enumOperacoes: BaseEnum[];

    constructor(
        private menuService: MenuService,
        private sistemaService: SistemaService,
        private moduloService: ModuloService,
        private formBuilder: FormBuilder,
        private snackBarService: SnackBarService,
        private dialog: MatDialog
    ) {
        this.buildForm();
        this.iconeFiltroControl.valueChanges.pipe(filter(filtro => filtro.length >= 3), debounceTime(500)).subscribe(() => this.recarregarIcones());
    }

    ngAfterViewInit(): void {
        this.selectIcone._keyManager.change.isStopped = true;
        this.menuService.getEnumOperacoes().subscribe(result => {
            this.enumOperacoes = result;
        }, e => this.snackBarService.showError('Erro ao carregar enumOperacoes', e));

        console.debug('Menu.init', this._menu);
        if (this._menu && this._menu.chaveSegPerfil == null) {
            this.form.markAsDirty();
        }
    }

    private buildForm() {
        this.form = this.formBuilder.group({
            id: [null],
            modulo: [null, [Validators.required]],
            chaveSegPerfil: [null, [Validators.maxLength(255)]],
            descricao: [null, [Validators.required, Validators.maxLength(80)]],
            posicao: [
                null,
                [
                    Validators.required,
                    Validators.min(1),
                    Validators.maxLength(2),
                    Validators.pattern(/^[0-9]\d*$/)
                ]
            ],
            icone: [null],
            parent: [null],
            dataInicio: new FormControl({value: null, disabled: true}),
            dataFim: [null],
            urlRota: [null, [Validators.required, Validators.maxLength(255)]],
            urlIframe: [null, [Validators.maxLength(255), urlIframaValidator]],
            cadastrar: [this._menu ? this._menu.cadastrar : false],
            alterar: [this._menu ? this._menu.alterar : false],
            consultar: [this._menu ? this._menu.consultar : false],
            deletar: [this._menu ? this._menu.deletar : false]
        });

        this.formOperacao = this.formBuilder.group({
            id: [null],
            operacao: [null, [Validators.required, Validators.maxLength(255)]],
            chaveSegPerfil: [null, [Validators.maxLength(255)]]
        });
        
    }

    private recarregarIcones() {
        this.optionsIcones.next([]);
        if (this.form.value.icone) {
            this.icones = [...MATERIAL_OUTLINE_ICONS_VALUES].filter(icone => icone !== this.form.value.icone);
            this.icones.unshift(this.form.value.icone);
        }
        this.offsetIcones = 0;
        this.carregarMaisIcones();
    }

    carregarMaisIcones(): void {
        let filtro = this.iconeFiltroControl.value;
        if (filtro && filtro.length >= 3) {
            filtro = filtro.toLowerCase();
            this.iconesFiltrados = this.icones.filter(icone => icone.toLowerCase().indexOf(filtro.toLowerCase()) > -1);
        } else {
            this.iconesFiltrados = [...this.icones];
        }
        this.optionsIcones.next(this.optionsIcones.value.concat(this.iconesFiltrados.slice(this.offsetIcones, this.offsetIcones + 10)));
        this.offsetIcones = this.optionsIcones.value.length;
    }

    salvarOperacao(pOperacao?: OperacaoMenu): void {
        const operacao = pOperacao || OperacaoMenu.fromJson(this.formOperacao.value);
        operacao.menu = this._menu;
        if (pOperacao) {
            console.debug(`update_chaveSegPerfil.b: ${pOperacao}`)
            operacao.chaveSegPerfil = pOperacao['update_chaveSegPerfil'];
            delete pOperacao['update_chaveSegPerfil'];
            console.debug(`update_chaveSegPerfil.a: ${pOperacao}`)
        } else {
            console.debug(`update_chaveSegPerfil.operacao: ${operacao}`)
        }
            
        
        (operacao.id ? this.menuService.updateOperacao(operacao) : this.menuService.createOperacao(operacao)).subscribe(() => {
            this.form.markAsPristine();
            this.snackBarService.showSuccess('Operação salva com sucesso!');
            this.carregarOperacoes();
        }, (e) => this.snackBarService.showError('Erro ao processar sua solicitação', e));
    }

    excluirOperacao(operacao: OperacaoMenu): void {
        const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: {label: operacao.operacao}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.menuService.deleteOperacao(operacao).subscribe(
                    () => {
                        this.snackBarService.showSuccess('Operação excluída com sucesso!');
                        this.carregarOperacoes();
                    }, (e) => this.snackBarService.showError('Erro ao processar sua solicitação', e));
            }
        });
    }

    private carregarSistemasModulosEIcones() {
        if (this._menu.modulo) {
            this.sistemaControl.setValue(this._menu.modulo.sistema);
            this.moduloService
                .getAllbyIdSistema(this._menu.modulo.sistema.id)
                .subscribe(modulos => {
                    this.modulos = modulos;
                    this.form.value.modulo = this._menu.modulo;
                });
            this.menuService
                .getAllbyIdModulo(this._menu.modulo.id)
                .subscribe(menus => {
                    this.menus = menus;
                    this.form.value.parent = this._menu.parent;
                });
        }
        this.recarregarIcones();
    }

    private carregarOperacoes() {
        this.menuService.getOperacoes(this._menu.id)
        .pipe(map(operacoes=>{ operacoes.map((o)=>{ o['update_chaveSegPerfil'] = o.chaveSegPerfil; return o} ); return operacoes; }))
        .subscribe(operacoes => {
             this.operacoes = operacoes;
             console.debug('menu-detail.operacoes', this.operacoes);
        });
    }

    private carregarSistemas() {
        return this.sistemaService.getAll();
    }

    private carregarMenus() {
        return this.menuService.getAll();
    }

    onSelectSistema(event) {
        if (event.value === undefined) {
            this.modulos = [];
        } else {
            this.moduloService
                .getAllbyIdSistema(event.value.id)
                .subscribe(modulos => (this.modulos = modulos));
        }
    }

    onSelectMenuPai(event) {
        if (event.value === undefined) {
            this.menus = [];
        } else {
            this.menuService
                .getAllbyIdModulo(event.value.id)
                .subscribe(menus => (this.menus = menus));
        }
    }

    onSelectOperacao() {
        this.form.markAsDirty();
    }

    salvar(): void {
        this._menu = Menu.fromJson(this.form.value);
        const criacao = !!this._menu.id;

        if (this.form.value.parent && this._menu.id === this.form.value.parent.id) {
            this.snackBarService.showError('Não é possivel selecionar um menu pai com o mesmo identificador do filho.');
            return;
        }
        
        (!this._menu.id
                ? this.menuService.create(this._menu)
                : this.menuService.update(this._menu)
        ).subscribe(menuSalvo => {
            this.snackBarService.showSuccess('Solicitação processada com sucesso!');
            this.menuService.getById(this._menu.id).subscribe((m)=>{ this._menu = m; this.form.patchValue(this._menu); });
            this.onChange.emit('salvar');
            // this.tabGroup.selectedIndex = 1;
        }, (e) => this.snackBarService.showError('Erro ao processar sua solicitação', e));
        this.form.markAsPristine();
    }

    limpar(): void {
        this.form.reset();
    }

    excluirMenu(): void {
        const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: {label: this.menu.descricao}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.menuService.delete(this.menu.id).subscribe(
                    () => {
                        this.snackBarService.showSuccess('Menu excluído com sucesso!');
                        this.carregarOperacoes();
                    }, (e) => this.snackBarService.showError('Erro ao processar sua solicitação', e));
            }
        });
    }

    @Input()
    set menu(pMenu: Menu) {
        this.form.markAsPristine();
        if (this.tabGroup) this.tabGroup.selectedIndex = 0;
        this._menu = pMenu;
        if (this._menu != null && this._menu.id) {
            this.carregarSistemas().subscribe(sistemas => {
                this.sistemas = sistemas;

                this.menuService.getById(this._menu.id).subscribe(menu => {
                    this._menu = menu;
                    this.form.patchValue(this._menu);
                    this.carregarSistemasModulosEIcones();
                    this.carregarOperacoes();

                    if (this._menu && this._menu.id && this._menu.chaveSegPerfil == null) {
                        this.form.markAsDirty();
                        this.salvar();
                    }
                });
            });
        } else {
            this.carregarSistemas().subscribe(sistemas => {
                this.sistemas = sistemas;
                let sistemaId = pMenu && pMenu.modulo && pMenu.modulo.sistema ? pMenu.modulo.sistema.id :
                    this._menu && this._menu.modulo.sistema ? this._menu.modulo.sistema.id : null;
                if (sistemaId == null) return;
                this.moduloService.getAllbyIdSistema(sistemaId).subscribe(modulos => {
                    this.modulos = modulos;
                    this.carregarMenus().subscribe(menus => {
                        this.menus = menus;
                        this._menu = Object.assign({}, pMenu);
                        this.buildForm();
                        this.form.patchValue(this._menu);
                        this.sistemaControl.setValue(this._menu.modulo.sistema);
                    });
                });
            });
        }
    }

    get menu(): Menu {
        return this._menu;
    }

    public get ambienteDev() {
        return environment.name && environment.name.toUpperCase().indexOf('DEV') != -1;
    }


}
