import {BaseEnum} from "./base.enum ";

export abstract class BaseModel {
    id?: number;

    compare(b: BaseModel): boolean {
        return this.id == b.id;
    }

    compareFromBaseEnum(b: BaseEnum): boolean {
        return false;
    }

}
