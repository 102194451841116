import {BaseModel} from "../../../../shared/models/base.model";

export class UsuarioPerfil extends BaseModel{
    constructor(
        public cpf?: string,
        public nome?: string,
        public servidor?: boolean,
        public matricula?: string

    ) {
        super();
    }

    static fromJson(json: any): UsuarioPerfil {
        if (json === undefined || json === null){
            return null;
        }

        return new UsuarioPerfil(
            json.cpf,
            json.nome,
            json.servidor,
            json.matricula
        );
    }
}