import {Injectable, Injector} from '@angular/core';
import {ListResolver} from 'app/main/shared/resolvers/list-resolver';
import {BaseService} from 'app/main/shared/services/base.service';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Menu} from './menu.model';
import {Resolve} from "@angular/router";
import {OperacaoMenu} from "./operacao-menu.model";
import {BaseEnum} from "../../shared/models/base.enum ";

@Injectable()
export class MenuListResolver extends ListResolver<Menu> {
    constructor(service: MenuService) {
        super(service);
    }
}

@Injectable()
export class MenuListAllResolver implements Resolve<Menu[]> {
    constructor(
        private service: MenuService
    ) {
    }

    resolve(): Observable<Menu[]> {
        return this.service.getAllInclusiveExcluidos()
    }
}

@Injectable({
    providedIn: 'root'
})
export class MenuService extends BaseService<Menu> {
    constructor(protected injector: Injector) {
        super('/api/menus', injector, Menu.prototype, Menu.fromJson);
    }

    getAllbyIdModulo(idMenu?: number): Observable<Menu[]> {
        var url = this.urlResource + '/modulo/';
        if (idMenu !== undefined) {
            url += idMenu;
        }
        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResources.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    getAllInclusiveExcluidos() {
        return this.http
            .get(`${this.urlResource}/all`)
            .pipe(
                map(this.jsonToResources.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    getOperacoes(idMenu: number): Observable<OperacaoMenu[]> {
        const url = `${this.urlResource}/${idMenu}/operacoes`;
        return this.http.get(url).pipe(
            map(OperacaoMenu.fromJsons.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    createOperacao(operacao: OperacaoMenu): Observable<OperacaoMenu> {
        const url = `${this.urlResource}/${operacao.menu.id}/operacoes`;
        return this.http.post(url, operacao).pipe(
            map(OperacaoMenu.fromJson.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    updateOperacao(operacao: OperacaoMenu): Observable<OperacaoMenu> {
        const url = `${this.urlResource}/${operacao.menu.id}/operacoes/${operacao.id}`;
        return this.http.put(url, operacao).pipe(
            map(() => operacao),
            catchError(this.handleError.bind(this))
        );
    }

    deleteOperacao(operacao: OperacaoMenu): Observable<any> {
        const url = `${this.urlResource}/${operacao.menu.id}/operacoes/${operacao.id}`;
        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }

    getEnumOperacoes(): Observable<BaseEnum[]> {
        const url = `${this.urlResource}/operacoes`;
        return this.http.get(url).pipe(
            map(json => json),
            catchError(this.handleError.bind(this))
        );
    }

}
