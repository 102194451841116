import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PerfilService } from '../perfil.service';
import { Perfil } from '../perfil.model';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { SnackBarService } from 'app/main/shared/services/snack-bar/snack-bar.service';

export interface DialogData {
  perfil: Perfil;
  descricaoNovoPerfil: string;
}

@Component({
  selector: 'app-dialog-clonar-perfil',
  templateUrl: './dialog-clonar-perfil.component.html',
  styleUrls: ['./dialog-clonar-perfil.component.css']
})
export class DialogClonarPerfilComponent implements OnInit {

  formDialogClonar: FormGroup;
  IsWait: boolean = false;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<DialogClonarPerfilComponent>,
    public perfilService: PerfilService,
    public formBuilder: FormBuilder,
    protected snackBarService: SnackBarService
  ) {}

  ngOnInit(): void {
    this.formDialogClonar = this.formBuilder.group({
      descricaoNovoPerfil: [null, [Validators.required, Validators.maxLength(255)]]
    });
    this.formDialogClonar.get('descricaoNovoPerfil').setValue(this.data.descricaoNovoPerfil);
}

  onNoClick(): void {
    this.dialogRef.close();
  }

  confirm(): void {
    this.IsWait = true;
    const descricaoNovoPerfil = this.formDialogClonar.get('descricaoNovoPerfil').value;
    this.perfilService.clonarPerfil(this.data.perfil, descricaoNovoPerfil).subscribe(
      () => {
        this.IsWait = false;
        this.dialogRef.close(true);
      },
      (e) => {
        this.snackBarService.showError(
          'Não foi possível clonar o perfil.',e
        );
      }
    );
}

}
