import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector   : 'footer',
    templateUrl: './footer.component.html',
    styleUrls  : ['./footer.component.scss']
})
export class FooterComponent
{
 
    orgao : string = ' Governo do Estado de Mato Grosso';
    secretaria : string = 'Secretaria de Estado de Meio Ambiente'
 
    /**
     * Constructor
     */
    constructor(private translate: TranslateService)
    {
    }
}
