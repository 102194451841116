import {ChangeDetectionStrategy, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {fuseAnimations} from '@fuse/animations';
import {Usuario} from '../usuario.model';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from 'app/main/shared/services/authentication.service';
import {PessoaFisicaService} from 'app/main/core/services/pessoa-fisica.service';
import {PessoaFisica} from 'app/main/core/models/pessoa-fisica.model';
import {Contato} from 'app/main/core/models/contatos.model';
import {Pessoa} from 'app/main/core/models/pessoa.model';
import {MatStepper} from "@angular/material/stepper";
import { SnackBarService } from 'app/main/shared/services/snack-bar/snack-bar.service';

@Component({
    selector: 'app-usuario-profile',
    templateUrl: './usuario-profile.component.html',
    styleUrls: ['./usuario-profile.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations
})
export class UsuarioProfileComponent implements OnInit {

    @ViewChild('stepper', {static: true})
    protected stepper: MatStepper;

    usuarioForm: FormGroup;
    usuario: Usuario = null;
    pessoaFisica: PessoaFisica;

    constructor(
        protected pessoaFisicaService: PessoaFisicaService,
        protected injector: Injector,
        protected formBuilder: FormBuilder,
        protected snackBarService: SnackBarService,
        protected activatedRoute: ActivatedRoute,
        private authenticationService: AuthenticationService,
        private router: Router
    ) {}

    protected buildResourceForm(): void {
        this.usuarioForm = this.formBuilder.group({
            id: [null],
            nome: [null, [Validators.required, Validators.maxLength(200)]],
            email: [
                null,
                [
                    Validators.required,
                    Validators.maxLength(100),
                    Validators.email
                ]
            ],
            login: [null, [Validators.required]]
        });
    }

    ngOnInit(): void {
        this.getUsuario();
        this.buildResourceForm();
    }

    getUsuario(): void{
        this.authenticationService.getUser().subscribe(user => {
            if (user != null) {
                this.usuario = user;
                this.usuarioForm.patchValue(this.usuario);
                this.getPessoaFisica(this.usuario.login);
            }
        });
    }

    getPessoaFisica(cpfUsuario: string): void{
        this.pessoaFisicaService.getByCpf(cpfUsuario).subscribe(response => {
            this.pessoaFisica = response;
        });
    }

    get cpf(): string {
        return this.usuario.login;
    }

    get contato(): Contato {
        return this.pessoaFisica.pessoa.contato;
    }

    get pessoa(): Pessoa {
        return this.pessoaFisica ? this.pessoaFisica.pessoa : null;
    }

    getBaseComponetPath(): string {
        return this.activatedRoute.snapshot.parent.url[0].path;
    }

    voltar(): void {
        this.router.navigateByUrl('/', { skipLocationChange: true });
    }

    nextStep(): void{
        this.stepper.next();
    }

    previousStep(): void{
        this.stepper.previous();
    }
}
