import {Modulo} from './../modulos/modulo.model';
import {BaseModel} from 'app/main/shared/models/base.model';

export class Menu extends BaseModel {
    public _menuTree: { [key: number]: any } = null;

    constructor(
        public id?: number,
        public chaveSegPerfil?: string,
        public descricao?: string,
        public posicao?: number,
        public icone?: string,
        public dataInicio?: Date,
        public dataFim?: Date,
        public parent?: Menu,
        public menus?: any,
        public modulo?: Modulo,
        public ativo?: boolean,
        public urlIframe?: string,
        public urlRota?: string,
        public cadastrar?: boolean,
        public alterar?: boolean,
        public consultar?: boolean,
        public deletar?: boolean,
    ) {
        super();
    }

    public get menuTree() {
        return this._menuTree;
    }

    public set menuTree(t: any) {
        this._menuTree = t;
    }

    static fromJson(json: any): Menu {
        if (json === undefined || json === null) return null;

        return new Menu(
            json.id,
            json.chaveSegPerfil,
            json.descricao,
            json.posicao,
            json.icone,
            json.dataInicio !== null ? new Date(json.dataInicio) : null,
            json.dataFim !== null ? new Date(json.dataFim) : null,
            Menu.fromJson(json.parent),
            json.menus,
            json.modulo,
            json.ativo,
            json.urlIframe,
            json.urlRota,
            json.cadastrar,
            json.alterar,
            json.consultar,
            json.deletar
        );
    }
}



