import {Injectable, Injector} from '@angular/core';
import {ListResolver} from "../../shared/resolvers/list-resolver";
import {Grupo} from "../grupos/grupo.model";
import {DetailResolver} from "../../shared/resolvers/detail-resolver";
import {RoutingStateService} from "../../core/services/routing-state.service";
import {BaseService} from "../../shared/services/base.service";

@Injectable()
export class GrupoListResolver extends ListResolver<Grupo> {
    constructor(service: GrupoService) {
        super(service);
    }
}

@Injectable()
export class GrupoResolver extends DetailResolver<Grupo> {
    constructor(
        service: GrupoService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: "root"
})
export class GrupoService extends BaseService<Grupo> {

    constructor(protected injector: Injector) {
        super(
            "/api/grupos",
            injector,
            Grupo.prototype,
            Grupo.fromJson
        );
    }
}
