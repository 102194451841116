import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { LoginModule } from './login/login.module';
import { RegisterModule } from './register/register.module';
import { ForgotPasswordModule } from './forgot-password/forgot-password.module';
import { MailConfirmModule } from './mail-confirm/mail-confirm.module';
import { ResetPasswordModule } from './reset-password/reset-password.module';
import { TermoModule } from './termo/termo.module';
import { ForgotConfirmModule } from './forgot-confirm/forgot-confirm.module';
import { PasswordConfirmModule } from './password-confirm/password-confirm.module';
import { MailConfirmFinishModule } from './mail-confirm-finish/mail-confirm-finish.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { createTranslateLoader } from 'app/app.module';
import { HttpClient } from '@angular/common/http';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: '/login'
    },
    {
        path: 'login',
        loadChildren: './login/login.module#LoginModule'
    },
    {
        path: 'termo',
        loadChildren: './termo/termo.module#TermoModule'
    },
    {
        path: 'register',
        loadChildren: './register/register.module#RegisterModule'
    }
];

@NgModule({
    declarations: [],
    imports: [
        // Angular Core
        CommonModule,
        RouterModule.forChild(routes),
        MatSelectModule,
        // Fuse
        FuseSharedModule,
        TranslateModule.forChild({
            loader: {provide: TranslateLoader, useFactory: (createTranslateLoader), deps: [HttpClient]},
            
        }),
        LoginModule,
        TermoModule,
        RegisterModule,
        ForgotPasswordModule,
        MailConfirmModule,
        MailConfirmFinishModule,
        PasswordConfirmModule,
        ResetPasswordModule,
        ForgotConfirmModule
    ]
})
export class AuthModule {}
