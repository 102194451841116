import { Injectable, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { TranslateService, TranslationChangeEvent, LangChangeEvent } from '@ngx-translate/core';
import { LANGUAGES } from 'app/main/shared/utils/language.constants';

@Injectable({ providedIn: 'root'})
export class LanguageHelper implements OnDestroy {
    private transactionChangeSubscription: any;
    private langChangeSubscription: any;

    constructor(private titleService: Title, private ts: TranslateService,
        private router: Router) {
        this.init();
    }

    ngOnDestroy() {
        if (this.transactionChangeSubscription != null)
            this.transactionChangeSubscription.unsubscribe();
        if (this.langChangeSubscription != null) {
            this.langChangeSubscription.unsubscribe();
        }
    }
    public loadTranslation(lang: string, file: string) {
        if (lang == null) {
            lang = this.ts.getDefaultLang();
        }
        if (lang) {
            if (file) {
            }
        }
    }
    getService(): TranslateService {
        return this.ts;
    }

    public getDefaultLang(): string {
        return this.ts.getDefaultLang();
    }
    getAll(): Promise<any> {
        return Promise.resolve(LANGUAGES);
    }

    /**
     * Update the window title using params in the following
     * order:
     * 1. titleKey parameter
     * 2. $state.$current.data.pageTitle (current state page title)
     * 3. 'global.title'
     */
    updateTitle(titleKey?: string) {
        if (!titleKey) {
            titleKey = this.getPageTitle( this.router && this.router.routerState ? this.router.routerState.snapshot.root : null);
        }
        if (titleKey)
        this.ts.get(titleKey).subscribe(title => {
            this.titleService.setTitle(title);
        });
    }

    private init() {
        this.transactionChangeSubscription = this.ts.onTranslationChange.subscribe(
            (event: TranslationChangeEvent) => {
                console.debug('event: ', event);
                this.updateTitle();
            }
        );

        this.langChangeSubscription = this.ts.onLangChange.subscribe(
            (event: LangChangeEvent) => {
                console.debug('event: ', event);
                this.updateTitle();
            }
        );
    }

    private getPageTitle(routeSnapshot: ActivatedRouteSnapshot) {
        if (routeSnapshot == null) return "PORTAL";

        let title: string =
            routeSnapshot.data && routeSnapshot.data['pageTitle']
                ? routeSnapshot.data['pageTitle']
                : 'PORTAL';
        if (routeSnapshot.firstChild) {
            title = this.getPageTitle(routeSnapshot.firstChild) || title;
        }
        return title;
    }
}
