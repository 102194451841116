import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseNavCollapsableComponent } from './collapsable/collapsable.component';
import { FuseNavGroupComponent } from './group/group.component';
import { FuseNavItemComponent } from './item/item.component';
import { FuseNavigationComponent } from './navigation.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,

        MatIconModule,
        MatRippleModule,

        TranslateModule.forChild()
    ],
    exports: [FuseNavigationComponent],
    declarations: [
        FuseNavigationComponent,
        FuseNavGroupComponent,
        FuseNavItemComponent,
        FuseNavCollapsableComponent
    ]
})
export class FuseNavigationModule {}
