import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { FuseSharedModule } from "@fuse/shared.module";
import { MailConfirmFinishComponent } from "./mail-confirm-finish.component";

const routes = [
    {
        path: "auth/mail-confirm-finish",
        component: MailConfirmFinishComponent
    }
];

@NgModule({
    declarations: [MailConfirmFinishComponent],
    imports: [RouterModule.forChild(routes), MatIconModule, FuseSharedModule]
})
export class MailConfirmFinishModule {}
