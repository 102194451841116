import {BaseModel} from 'app/main/shared/models/base.model';
import { Contato } from './contatos.model';

export class Pessoa {
    constructor(
        public id?: number,
        public ativo?: boolean,
        public cadastroCompleto?: boolean,
        public contato?: Contato
    ) {}

    static fromJson(json: any): Pessoa {
        if (json === undefined || json === null) {
            return null;
        }
        return new Pessoa(
            json.id,
            json.ativo,
            json.cadastroCompleto,
            Contato.fromJson(json.contato)
        );
    }
}
