import { Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Pais } from '../models/pais.model';

@Injectable({
    providedIn: 'root'
})
export class PaisService {
    private http: HttpClient;
    private urlResource = environment.URL_SCORP_API;
    protected jsonToResourceFn: (json: any) => Pais;

    constructor(private injector: Injector) {
        this.http = injector.get(HttpClient);
        this.urlResource += '/api/paises';
    }

    getAll(): Observable<Pais[]> {
        const url = `${this.urlResource}`;

        return this.http
            .get(url)
            .pipe(
                map(this.jsonDataToPaisList),
                catchError(this.handleError.bind(this))
            );
    }

    private jsonDataToPaisList(jsonData: any[]): Pais[] {
        const entries: Pais[] = [];

        jsonData.forEach(element => {
            const rt = Object.assign(new Pais(), element);
            entries.push(rt);
        });
        return entries;
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }
}
