import {BaseModel} from 'app/main/shared/models/base.model';
import {Contato} from './contatos.model';
import {BaseEnum} from 'app/main/shared/models/base.enum ';

export class Email extends BaseModel {
    constructor(
        public id?: number,
        public email?: string,
        public tipo?: string,
        public contato?: Contato
    ) {
        super();
    }

    static tiposEmails: BaseEnum[] = [
        {valor: 'PRINCIPAL', descricao: 'Principal'},
        {valor: 'PESSOAL', descricao: 'Pessoal'},
        {valor: 'TRABALHO', descricao: 'Trabalho'}
    ];

    static fromJson(json: any): Email {
        if (json === undefined || json === null) {
            return null;
        }
        return new Email(
            json.id,
            json.email,
            json.tipo,
            Contato.fromJson(json.contato)
        );
    }
}
