import {Component, Inject, Injector, OnInit} from '@angular/core';
import {DialogClonarPerfilComponent} from '../dialog-clonar-perfil/dialog-clonar-perfil.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnackBarService} from '../../../shared/services/snack-bar/snack-bar.service';
import {Perfil} from '../perfil.model';
import {PerfilUsuarioService} from '../../../core/services/perfil-usuario.service';
import {AdicionarPerfilUsuarioModel} from './adicionar-perfil-usuario.model';
import {BaseListComponent} from '../../../shared/components/base-list/base-list.component';
import {UsuarioPerfil} from '../perfil-detail/perfil-dados-principais-list/usuarios-perfil.model';
import {PerfilUsuarioListService} from '../perfil-usuario-list.service';
import {HttpParams} from '@angular/common/http';

@Component({
  selector: 'app-dialog-adicionar-perfil-usuario',
  templateUrl: './dialog-adicionar-perfil-usuario.component.html',
  styleUrls: ['./dialog-adicionar-perfil-usuario.component.scss']
})

export class DialogAdicionarPerfilUsuarioComponent extends BaseListComponent<UsuarioPerfil> implements OnInit {

    formDialogAdicionar: FormGroup;
    IsWait = false;
    perfil: Perfil;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public formBuilder: FormBuilder,
                public perfilService: PerfilUsuarioService,
                public dialogRef: MatDialogRef<DialogClonarPerfilComponent>,
                protected snackBarService: SnackBarService,
                injector: Injector,
                public perfilUsuarioService: PerfilUsuarioListService) {
        super(injector, ['nome', 'cpf', 'acao'] , perfilUsuarioService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.paginator.pageSize = 50;
        this.formDialogAdicionar = this.formBuilder.group({
            pessoaIdentificacao: [null, [Validators.required, Validators.maxLength(255)]]
        });
    }

    fillFilters(httpParams: HttpParams): HttpParams {
        const pessoaIdentificacao = this.formDialogAdicionar.get('pessoaIdentificacao').value;
        if (typeof pessoaIdentificacao !== 'undefined' && pessoaIdentificacao.length > 0){
            httpParams = httpParams.set('idPerfil', this.data.perfil.id);
            httpParams = httpParams.set('descricao', pessoaIdentificacao ? pessoaIdentificacao : '');
            httpParams = httpParams.set('isPesquisaParaInsercao', '' + true);
        }
        return httpParams;
    }

    finalizarSelecao(usuario: UsuarioPerfil): void {
        this.IsWait = true;
        const login = this.formDialogAdicionar.get('pessoaIdentificacao').value;
        this.perfilService.addPerfilUsingUserLogin(AdicionarPerfilUsuarioModel.fromJson({idPerfil: this.data.perfil.id, usuarioLogin: usuario.cpf})).subscribe(
            () => {
                this.IsWait = false;
                this.snackBarService.showSuccess('Usuário adicionado ao perfil.');
                this.dialogRef.close(true);
            },
            (e) => {
                this.IsWait = false;
                this.snackBarService.showError('Não foi possível criar o perfil.', e);
            }
        );
    }

    getCpfCnpjMask(cpf_cnpj: string): string {
        return this.isCpf(cpf_cnpj) ? '000.000.000-009' : '00.000.000/0000-00';
    }

    isCpf(cpf_cnpj: string): boolean {
        return cpf_cnpj == null ? true : cpf_cnpj.length < 12 ? true : false;
    }

    pesquisar(){
        this.list(0);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}
