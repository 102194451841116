import { Component, Input, OnInit } from '@angular/core';
import { SnackBarService } from '../../services/snack-bar/snack-bar.service';

@Component({
  selector: 'app-copy-to-clipboard',
  template: `<a href="#" title="Copiar" aria-label="Copiar" [copy-clipboard]="value" (copied)="showMessage(msg)" >{{label}}<mat-icon>content_copy</mat-icon></a>`,
  styleUrls: ['./copy-to-clipboard.component.scss']
})
export class CopyToClipboardComponent implements OnInit {

  @Input() label: string='';
  @Input() value: string;
  @Input() msg: string;

  constructor(private snackBarService: SnackBarService) { 
    console.log('CopyToClipboardComponent.value', this.value);
  }

  ngOnInit() {
  }

  showMessage(msg?:string) {
    this.snackBarService.showSuccess(msg ? msg : 'Copiado');
  }

}
