export const environment = {
    production: false,
    name: 'homolog',
    hmr: false,
    URL_ME: 'http://portal-web-homolog.apps.ocp.sema.mt.gov.br',
    URL_GATEWAY: 'http://api.portal-server-homolog.apps.ocp.sema.mt.gov.br',
    URL_PORTAL_API: 'http://api.portal-server-homolog.apps.ocp.sema.mt.gov.br',
    URL_GEOPORTAL_BASE_REFERENCIA: 'http://api.geoportal-base-referencia-homolog.apps.ocp.sema.mt.gov.br',
    URL_PANDORA: 'http://api.pandora-homolog.apps.ocp.sema.mt.gov.br',
    URL_LICENCIAMENTO: 'http://licenciamento-web-homolog.apps.ocp.sema.mt.gov.br',
    URL_LICENCIAMENTO_API: 'http://api.licenciamento-server-homolog.apps.ocp.sema.mt.gov.br',
    URL_GEO: 'http://api.geoportal-server-homolog.apps.ocp.sema.mt.gov.br',
    URL_SCORP: 'http://scorp-web-homolog.apps.ocp.sema.mt.gov.br',
    URL_SCORP_API: 'http://api.scorp-server-homolog.apps.ocp.sema.mt.gov.br',
    URL_WSO2_AUTH: 'https://localhost:9445/oauth2/token',
    URL_WSO2_USER_INFO: '',
    URL_CLOUD_SERVER_PORTAL: '',
    URL_CLOUD_SERVER_EUREKA: '',
    URL_CLOUD_SERVER_AUTH: '',
    UF_PADRAO: 'MT',
    IDENTITY_COOKIE_NAME: "br.gov.mt.sema.homolog.identity",
    SITE_KEY: '08290ca9-7d5d-4a97-bc23-3393280c725a'
};
