import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule
} from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { AddNewButtonComponent } from './components/add-new-button/add-new-button.component';
import { ConfirmarExclusaoDialogComponent } from './components/confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import { ContatoWizardComponent } from './components/contato-wizard/contato-wizard.component';
import { DocumentoDialogComponent } from './components/documento-dialog/documento-dialog.component';
import { DocumentoWizardComponent } from './components/documento-wizard/documento-wizard.component';
import { PessoaFisicaWizardComponent } from './components/pessoa-fisica-wizard/pessoa-fisica-wizard.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslateModule } from '@ngx-translate/core';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import { NgxMaskModule } from 'ngx-mask';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CopyToClipboardComponent } from './components/copy-to-clipboard/copy-to-clipboard.component';
import { EnderecoWizardComponent } from './components/endereco-wizard/endereco-wizard.component';
import { MatSpinnerOverlayComponent } from './components/mat-spinner-overlay/mat-spinner-overlay.component';
import { PdfViewerDialogComponent } from "./components/pdf-viewer-dialog/pdf-viewer-dialog.component";
import { ProfilePictureComponent } from './components/profile-picture/profile-picture.component';
import { SelectSearchComponent } from './components/select-search/select-search.component.ts';
import { CopyClipboardDirective } from './directives/copy-to-clipboard.directive';
import { BaseEnumDescricaoPipe } from "./pipes/base-enum-descricao.pipe";
import { RemoverAdicionadosPipe } from "./pipes/remover-adicionados.pipe";
import { CustomSnackBarComponent } from './services/snack-bar/custom-snack-bar.component';

@NgModule({
    declarations: [
        ConfirmarExclusaoDialogComponent,
        AddNewButtonComponent,
        SelectSearchComponent,
        MatSpinnerOverlayComponent,
        ProfilePictureComponent,
        DocumentoDialogComponent,
        PessoaFisicaWizardComponent,
        EnderecoWizardComponent,
        ContatoWizardComponent,
        DocumentoWizardComponent,
        CustomSnackBarComponent,
        BaseEnumDescricaoPipe,
        RemoverAdicionadosPipe,
        CopyClipboardDirective,
        PdfViewerDialogComponent,
        CopyToClipboardComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        NgxMaskModule.forRoot({
            validation: true
        }),
        
        MatMenuModule,
        MatIconModule,
        MatCardModule,
        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatRippleModule,
        MatSelectModule,
        MatSelectInfiniteScrollModule,
        NgxMatSelectSearchModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatRadioModule,
        MatListModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MatStepperModule,
        MatTooltipModule,
        NgxExtendedPdfViewerModule, 
    ],
    exports: [
        RouterModule,
        TranslateModule,
        MatMenuModule,
        MatIconModule,
        MatCardModule,
        NgxMaskModule,
        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatRippleModule,
        MatSelectModule,
        MatSelectInfiniteScrollModule,
        NgxMatSelectSearchModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatRadioModule,
        MatListModule,
        MatStepperModule, 
        BaseEnumDescricaoPipe,
        RemoverAdicionadosPipe,
        CopyClipboardDirective,
        CopyToClipboardComponent,

        ConfirmarExclusaoDialogComponent,
        AddNewButtonComponent,
        SelectSearchComponent,
        MatSpinnerOverlayComponent,
        ProfilePictureComponent,
        DocumentoDialogComponent,
        PessoaFisicaWizardComponent,
        EnderecoWizardComponent,
        ContatoWizardComponent,
        DocumentoWizardComponent,
        PdfViewerDialogComponent, 
    ],
    entryComponents: [
        ConfirmarExclusaoDialogComponent,
        AddNewButtonComponent,
        SelectSearchComponent,
        MatSpinnerOverlayComponent,
        ProfilePictureComponent,
        DocumentoDialogComponent,
        PessoaFisicaWizardComponent,
        EnderecoWizardComponent,
        ContatoWizardComponent,
        CustomSnackBarComponent,
        PdfViewerDialogComponent, 
    ]
})
export class SharedModule {
}
