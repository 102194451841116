import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from './authentication.service';
import {DefaultGuardService} from './default-guardservice';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuardService extends DefaultGuardService implements CanActivate {

    constructor(router: Router,
                authenticationService: AuthenticationService) {
        super(router, authenticationService);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return super.isAuthenticatedAndCadastroCompleto(route, state);
    }
}
