import { Perfil } from './../../pages/perfis/perfil.model';

export class PerfilUsuarioDTO {
    constructor(
        public idUsuario?: number,
        public idPerfil?: number,
        public perfil?: Perfil
    ) {}

    static fromJson(json: any): PerfilUsuarioDTO {
        if (json === undefined || json === null) {
            return null;
        }
        return new PerfilUsuarioDTO(
            json.idUsuario,
            json.idPerfil,
            Perfil.fromJson(json.perfil)
        );
    }
}
