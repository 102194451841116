import { AfterViewInit, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { SnackBarService } from '../../../shared/services/snack-bar/snack-bar.service';
import { MenuService } from '../../menus/menu.service';
import { DialogClonarPerfilComponent } from '../dialog-clonar-perfil/dialog-clonar-perfil.component';
import { PerfilMenuDTO, PerfilOperacaoMenuDTO } from '../perfil-menu-dto.model';
import { PerfilService } from '../perfil.service';
import { SistemaModuloDTO } from '../sistema-modulo-dto.model';

@Component({
  selector: 'app-dialog-adicionar-perfil-menu',
  templateUrl: './dialog-adicionar-perfil-menu.component.html',
  styleUrls: ['./dialog-adicionar-perfil-menu.component.scss']
})

export class DialogAdicionarPerfilMenuComponent implements  AfterViewInit {
   

    formDialogAdicionar: FormGroup;
    IsWait = false;
    
    loading = new BehaviorSubject(false);

    perfisOperacoesMenus: PerfilOperacaoMenuDTO[] = [];

    constructor(@Inject(MAT_DIALOG_DATA) public sistemaModulo: SistemaModuloDTO,
                public formBuilder: FormBuilder,
                public perfilService: PerfilService,
                public menuService: MenuService,
                public dialogRef: MatDialogRef<DialogClonarPerfilComponent>,
                protected snackBarService: SnackBarService,
                private cdr: ChangeDetectorRef
    ) {
        
    }
    ngAfterViewInit(): void {
        this.loading.next(true);
        this.menuService.getOperacoes(this.sistemaModulo.idMenu).subscribe(operacoes => {
 
            operacoes.forEach(operacao => {
                let perfilOperacaoMenuDTO =  new PerfilOperacaoMenuDTO(operacao.id, operacao.id, operacao);
                perfilOperacaoMenuDTO['selecionado'] = false;
                this.perfisOperacoesMenus.push(perfilOperacaoMenuDTO);
                console.log
            });

            this.perfisOperacoesMenus.sort((a,b) => a.operacaoMenu && b.operacaoMenu ? a.operacaoMenu.operacao.localeCompare(b.operacaoMenu.operacao) : 0);
            this.loading.next(false);
        }, (e) => {
            this.snackBarService.showError('Erro ao carregar operações disponíveis para o menu',e);
            this.loading.next(false);
        });
    }

    selecionarOperacao(perfilOperacaoMenuDTO: PerfilOperacaoMenuDTO): void {
        perfilOperacaoMenuDTO['selecionado'] = !perfilOperacaoMenuDTO['selecionado'];
    }

    selecionarTodasOperacoes(): void {
        const valor = !this.todasOperacoesSelecionadas;
        this.perfisOperacoesMenus.forEach(pom => pom['selecionado'] = valor);
    }

    get todasOperacoesSelecionadas(): boolean {
        if (this.perfisOperacoesMenus.length === 0) return false;
        return this.perfisOperacoesMenus.filter(pom => pom['selecionado']).length === this.perfisOperacoesMenus.length;
    }

    get operacoesSelecionadasIndeterminadas(): boolean {
        const length = this.perfisOperacoesMenus.filter(pom => pom['selecionado']).length;
        return length > 0 && length < this.perfisOperacoesMenus.length;
    }
    onNoClick(): void {
        this.dialogRef.close();
    }

    confirm(): void {
        this.loading.next(true);
        
        let perfilMenuDTO: PerfilMenuDTO = new PerfilMenuDTO();
        perfilMenuDTO.idMenu = this.sistemaModulo.idMenu;
        perfilMenuDTO.idPerfil = this.sistemaModulo.idPerfil;
        perfilMenuDTO.operacoes = PerfilOperacaoMenuDTO.fromJsons(this.perfisOperacoesMenus.filter(pom => pom['selecionado']));
        console.debug('confirm', perfilMenuDTO, this.sistemaModulo);
        this.perfilService.salvarPerfilMenuDTO(perfilMenuDTO).subscribe(
            () => {
                this.loading.next(false);
                this.snackBarService.showSuccess('Permissões do perfil salvas com sucesso!');
                this.dialogRef.close(true);
            },
            (e) => {
                this.loading.next(false);
                this.snackBarService.showError('Erro ao processar sua solicitação',e);
                this.dialogRef.close(false);
            }
        );
    }

}
