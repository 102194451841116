import {Component, Injector, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Perfil} from '../../perfil.model';
import {BaseListComponent} from '../../../../shared/components/base-list/base-list.component';
import {HttpParams} from '@angular/common/http';
import {PerfilUsuarioListService} from '../../perfil-usuario-list.service';
import {UsuarioPerfil} from './usuarios-perfil.model';
import {fuseAnimations} from '../../../../../../@fuse/animations';
import {DialogAdicionarPerfilUsuarioComponent} from '../../dialog-adicionar-perfil-usuario/dialog-adicionar-perfil-usuario.component';
import {DialogRemoverPermissaoUsuarioComponent} from '../../dialog-remover-permissao-usuario/dialog-remover-permissao-usuario.component';

@Component({
  selector: 'perfil-dados-principais-list',
  templateUrl: './perfil-dados-principais-list.component.html',
  styleUrls: ['./perfil-dados-principais-list.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class PerfilDadosPrincipaisListComponent extends BaseListComponent<UsuarioPerfil> implements OnInit {

    @Input() perfil: Perfil;

    pesquisa: string;

    constructor(injector: Injector, public perfilUsuarioService: PerfilUsuarioListService) {
        super(injector, ['cpf', 'nome', 'servidor', 'matricula', 'acao'], perfilUsuarioService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.paginator.pageSize = 20;
        this.list(0);
    }

    fillFilters(httpParams: HttpParams): HttpParams {
        if (typeof this.perfil.descricao !== 'undefined' && this.perfil.descricao.length > 0){
            httpParams = httpParams.set('idPerfil', '' + this.perfil.id);
            httpParams = httpParams.set('descricao', this.pesquisa ? this.pesquisa : '');
            httpParams = httpParams.set('isPesquisaParaInsercao', '' + false);
        }
        return httpParams;
    }

    excluirRegistro(resource: UsuarioPerfil): void {
        const dialogRef = this.dialog.open(DialogRemoverPermissaoUsuarioComponent, {
            width: '360px',
            data: { nomeUsuario: resource.nome, nomePerfil: this.perfil.descricao}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.perfilUsuarioService.excluir(this.perfil.id, resource.cpf).subscribe(
                    (response: any) => {
                        // this.messageService.add( {message: 'Registro excluido com sucesso.', severity: ISEVERITY.SUCCESS });
                        this.snackBarService.showSuccess('Usuário removido do perfil.');
                        this.list(0);
                    },
                    e => {
                        // this.messageService.add( {message: 'N&atilde;o foi possivel excluir o registro.', severity: ISEVERITY.ERROR });
                        this.snackBarService.showError('Não foi possível excluir o usuário do perfil.', e);
                    }
                );
            }
        });
    }

    getCpfCnpjMask(cpf_cnpj: string): string {
        return this.isCpf(cpf_cnpj) ? '000.000.000-009' : '00.000.000/0000-00';
    }

    isCpf(cpf_cnpj: string): boolean {
        return cpf_cnpj == null ? true : cpf_cnpj.length < 12 ? true : false;
    }

    search() {
        this.list(0);
    }

    adicionarPerfilUsuario(): void {
        const dialogRef = this.dialog.open(DialogAdicionarPerfilUsuarioComponent, {
            width: '500px',
            data: {perfil: this.perfil}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result){
                this.list(0);
            }
        });
    }
}
