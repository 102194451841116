import {BaseModel} from 'app/main/shared/models/base.model';
import {Profissao} from './profissao.model';
import {PessoaFisica} from '../../../../core/models/pessoa-fisica.model';

export class PessoaFisicaProfissao extends BaseModel {
    constructor(
        public id?: number,
        public profissao?: Profissao,
        public pessoaFisica?: PessoaFisica
    ) {
        super();
    }

    static fromJson(json: any): PessoaFisicaProfissao {
        if (json === undefined || json === null) {
            return null;
        }
        return new PessoaFisicaProfissao(
            json.id,
            json.profissao,
            json.pessoaFisica
        );
    }
}

