import {BaseModel} from "../../shared/models/base.model";

export class DadosDocumentoVerificado extends BaseModel{
    public constructor(
        public codVerificador?: string,
        public codCRC?: string,
        public titulo?: string,
        public numero?: string,
        public validade?: Date,
        public situacao?: string,
        public interessado?: string,
        public cpfCnpj?: string,
        public uf?: string,
        public municipio?: string,
        public latImovel?: number,
        public longImovel?: number,
        public chaveArquivo?: string,
    ) {
        super()
    }


    static fromJson(json: any): DadosDocumentoVerificado {
        if (json === undefined || json === null){
            return null;
        }

        return new DadosDocumentoVerificado(
            json.codVerificador,
            json.codCRC,
            json.titulo,
            json.numero,
            json.validade,
            json.situacao,
            json.interessado,
            json.cpfCnpj,
            json.uf,
            json.municipio,
            json.latImovel,
            json.longImovel,
            json.chaveArquivo,
        );
    }
}