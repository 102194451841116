import {BaseModel} from '../../shared/models/base.model';

export class Grupo extends BaseModel{
    constructor(
        public id?: number,
        public nome?: string,
        public descricao?: string,
        public ativo?: boolean,
        public dataInicio?: Date,
        public dataFim?: Date
    ) {
        super();
    }
    static fromJson(json: any): Grupo {
        if (json === undefined || json === null){
            return null;
        } 
        return new Grupo(
            json.id,
            json.nome,
            json.descricao,
            json.ativo,
            json.dataInicio,
            json.dataFim
        );
    }
}