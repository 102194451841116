import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SharedModule} from 'app/main/shared/shared.module';
import {CoreModule} from 'app/main/core/core.module';
import {PerfilListComponent} from './perfil-list/perfil-list.component';
import {PerfilDetailComponent} from './perfil-detail/perfil-detail.component';
import {AuthGuardService} from 'app/main/shared/services/auth-guard.service';
import {PerfilListResolver, PerfilResolver} from './perfil.service';
import {AuthGuardChildService} from 'app/main/shared/services/auth-guard-child.service';
import {DialogClonarPerfilComponent} from './dialog-clonar-perfil/dialog-clonar-perfil.component';
import {DialogSelecionarOperacoesPerfilComponent} from "./dialog-selecionar-operacoes-perfil/dialog-selecionar-operacoes-perfil.component";
import { PerfilDadosPrincipaisListComponent } from './perfil-detail/perfil-dados-principais-list/perfil-dados-principais-list.component';
import {DialogConfirmacaoComponent} from './dialog-confirmacao/dialog-confirmacao.component';
import {NgxMaskModule} from "ngx-mask";
import { DialogAdicionarPerfilUsuarioComponent } from './dialog-adicionar-perfil-usuario/dialog-adicionar-perfil-usuario.component';
import { DialogRemoverPermissaoUsuarioComponent } from './dialog-remover-permissao-usuario/dialog-remover-permissao-usuario.component';
import { DialogAdicionarPerfilMenuComponent } from './dialog-adicionar-perfil-menu/dialog-adicionar-perfil-menu.component';

export const routes: Routes = [
    {
        path: 'new',
        component: PerfilDetailComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'perfis',
        component: PerfilListComponent,
        canActivate: [AuthGuardService],
        resolve: {
            data: PerfilListResolver
        }
    },
    {
        path: ':id/edit',
        canActivateChild: [AuthGuardChildService],
        component: PerfilDetailComponent,
        resolve: {
            data: PerfilResolver
        }
    },
    {
        path: ':id/detail',
        canActivateChild: [AuthGuardChildService],
        component: PerfilDetailComponent,
        resolve: {
            data: PerfilResolver
        }
    }
];

@NgModule({
    declarations: [
        PerfilListComponent,
        PerfilDetailComponent,
        DialogClonarPerfilComponent,
        DialogSelecionarOperacoesPerfilComponent,
        PerfilDadosPrincipaisListComponent,
        DialogAdicionarPerfilMenuComponent,
        DialogConfirmacaoComponent,
        DialogAdicionarPerfilUsuarioComponent,
        DialogRemoverPermissaoUsuarioComponent
    ],
    imports: [
        CoreModule,
        SharedModule,
        RouterModule.forChild(routes),
        NgxMaskModule.forRoot({
            validation: true
        }),
    ],
    entryComponents: [
        PerfilDetailComponent,
        PerfilListComponent,
        DialogClonarPerfilComponent,
        DialogSelecionarOperacoesPerfilComponent,
        DialogAdicionarPerfilMenuComponent,
        DialogConfirmacaoComponent,
        DialogAdicionarPerfilUsuarioComponent,
        DialogRemoverPermissaoUsuarioComponent
    ],
    providers: [
        PerfilResolver,
        PerfilListResolver
    ]
})
export class PerfisModule {
}
