import {Injectable, Injector} from '@angular/core';
import {ListResolver} from '../../shared/resolvers/list-resolver';
import {DetailResolver} from '../../shared/resolvers/detail-resolver';
import {RoutingStateService} from '../../core/services/routing-state.service';
import {BaseService} from '../../shared/services/base.service';
import {Perfil} from './perfil.model';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {PerfilMenuDTO} from "./perfil-menu-dto.model";
import {SistemaModuloDTO} from './sistema-modulo-dto.model';
import {Usuario} from "../usuarios/usuario.model";

export interface PerfilCloneDTO {
    idPerfilOriginal: number;
    descricaoNovoPerfil: string;
    delegavel: boolean;
}


@Injectable()
export class PerfilListResolver extends ListResolver<Perfil> {
    constructor(service: PerfilService) {
        super(service);
    }
}

@Injectable()
export class PerfilResolver extends DetailResolver<Perfil> {
    constructor(
        service: PerfilService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: 'root'
})
export class PerfilService extends BaseService<Perfil> {

    constructor(protected injector: Injector) {
        super(
            '/api/perfis',
            injector,
            Perfil.prototype,
            Perfil.fromJson
        );
    }

    clonarPerfil(perfil: Perfil, descricaoNovoPerfil: string): Observable<Perfil> {
        const url = `${this.urlResource}/clonar`;
        const dto: PerfilCloneDTO = {
            idPerfilOriginal: perfil.id,
            descricaoNovoPerfil: descricaoNovoPerfil,
            delegavel: perfil.delegavel
        };

        return this.http.post(url, dto)
            .pipe(
                catchError(this.handleError),
                map(this.jsonToResourceFn),
            );
    }

    getPerfilMenuDTOByPerfilAndModulo(idPerfil: number, idModulo: number): Observable<PerfilMenuDTO[]> {
        const url = `${this.urlResource}/${idPerfil}/menus?modulo=${idModulo}`;
        return this.http.get(url).pipe(
            map(PerfilMenuDTO.fromJsons.bind(this)),
            catchError(this.handleError)
        );
    }

    salvarPerfilMenuDTO(perfilMenuDTO: PerfilMenuDTO): Observable<PerfilMenuDTO> {
        const url = `${this.urlResource}/${perfilMenuDTO.idPerfil}/menus`;
        return this.http.post(url, perfilMenuDTO).pipe(
            map(PerfilMenuDTO.fromJson.bind(this)),
            catchError(this.handleError)
        );
    }

    getMenusAtribuidoPerfil(idPerfil: number): Observable<SistemaModuloDTO[]> {
        const url = `${this.urlResource}/menu-atribuido-perfil/${idPerfil}`;
        return this.http.get(url).pipe(
            map(SistemaModuloDTO.fromJsons.bind(this)),
            catchError(this.handleError)
        );
    }

    getMenusDisponivelParaAtribuicaoPerfil(idPerfil: number, idModulo: number): Observable<SistemaModuloDTO[]> {
        const url = `${this.urlResource}/disponivel-atribuicao-perfil/${idPerfil}/${idModulo}`;
        return this.http.get(url).pipe(
            map(SistemaModuloDTO.fromJsons.bind(this)),
            catchError(this.handleError)
        );
    }

    vincularMenuPerfil(perfilMenuDTO: PerfilMenuDTO): Observable<PerfilMenuDTO> {
        const url = `${this.urlResource}/${perfilMenuDTO.idPerfil}/vincular-menu-perfil`;
        return this.http.post(url, perfilMenuDTO).pipe(
            map(PerfilMenuDTO.fromJson.bind(this)),
            catchError(this.handleError)
        );
    }

    desvincularMenu(sistemaModuloDTO: SistemaModuloDTO): Observable<any> {
        const url = `${this.urlResource}/${sistemaModuloDTO.idPerfil}/${sistemaModuloDTO.idMenu}/desvincular-menu-perfil`;
        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }

    getPerfisAtivosDisponiveisUsuario(usuario: Usuario): Observable<Perfil[]> {
        const url = `${this.urlResource}/perfil-disponivel-usuario/${usuario.login}`;
        return this.http.get(url).pipe(
            map(Perfil.fromJsons.bind(this)),
            catchError(this.handleError)
        );
    }
}

