import {BaseModel} from 'app/main/shared/models/base.model';
import {SearchableModel} from '../../shared/components/select-search/searchable-model';

export class Pais extends BaseModel implements SearchableModel {
    constructor(
        public id?: number,
        public nome?: string,
        public nacionalidade?: string
    ) {
        super();
    }

    static fromJson(json: any): Pais {
        if (json === undefined || json === null) {
            return null;
        }
        return new Pais(
            json.id,
            json.nome,
            json.nacionalidade
        );
    }

    static compareWith = (a: Pais, b: Pais): boolean => a && b ? a.id === b.id : a === b;

    searchValue(): string {
        return `${this.nome}`;
    }

    itemLabel(): string {
        return this.nome;
    }
}
