import { Injectable, Injector, EventEmitter, OnDestroy } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { AuthServerProvider } from './auth-provider.service';
import { UtilService } from './util.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { Constants } from 'app/main/shared/utils/constants';

declare var AWS: any;
declare const aws_user_files_s3_bucket;
declare const aws_user_files_s3_bucket_region;

@Injectable()
export class AccountService implements OnDestroy {
    currentAccount: any;
    private s3: any;
    public selectedPhoto: Blob;
    public eventEmitter: EventEmitter<any>;
    private authSubscription: any;

    // resourceurls
    private URL: string = '//sigera/api/account';
    private resourceAccountProfilesByUserIdUrl =
        '//sigera/api/account-profiles/loadByUserId';

    public stateBilheteShow: any = false;

    constructor(
        public httpClient: HttpClient,
        protected injector: Injector,
        //  public storage: LocalStorageService,
        private authProvider: AuthServerProvider,
        public util: UtilService
    ) {
        this.eventEmitter = new EventEmitter();

        this.authSubscription = this.authProvider.authEventEmitter.subscribe(
            e => {
                if (e !== undefined && e === 'auth:logout') {
                    this.clearAccount();
                }
                if (e !== undefined && e === 'auth:login') {
                    this.getAccount(true).subscribe(() => {});
                }
            }
        );
    }

    ngOnDestroy() {
        if (this.authSubscription != null) {
            this.authSubscription.unsubscribe();
        }
    }
    setAccount(account): Promise<any> {
        if (account != null) {
            // this.storage.store('account', account);
            this.currentAccount = account;
            return Promise.resolve(account);
        }
        return Promise.reject(null);
    }
    getAccountLocal(): Promise<any> {
        // #todo
        return new Promise((resolve, reject) => {});
        // return this.storage.retrieve('account');
    }

    getAccount(forceRefresh?: boolean): Observable<any> {
        let userInfoUrl = Constants.URL_WSO2_USER_INFO;
        if (this.currentAccount != null && !forceRefresh) {
            return of(this.currentAccount);
        }
        if (!forceRefresh) {
            return of(this.getAccountLocal());
        }

        return this.httpClient
            .get(`${userInfoUrl}`, {
                responseType: 'json'
            })
            .pipe(
                map((res: any) => {
                    this.convertItemFromServer(res);
                    this.currentAccount = res;
                    this.setAccount(this.currentAccount);

                    return res;
                })
            );
    }

    getAccountFromToken(token): Observable<any> {
        this.authProvider.httpInterceptorDisabled = true; // #todo criar regra
        let defaultParams = new HttpParams();
        const headers: HttpHeaders = new HttpHeaders();

        if (!!token) {
            headers.append('Authorization', 'Bearer ' + token);
        }

        return this.httpClient
            .get(Constants.URL_GATEWAY + '/bm-service-uaa/api/account')
            .pipe(
                map((res: Response) => {
                    this.authProvider.httpInterceptorDisabled = false;
                    return res.json();
                })
            );
    }

    save(account: any): Observable<any> {
        let defaultParams = new HttpParams();
        const headers: HttpHeaders = new HttpHeaders();
        headers
            .append('Content-Type', 'application/json')
            .append('Accept', 'application/json');

        account = this.convertToSave(account);

        return this.httpClient
            .post(
                Constants.URL_GATEWAY + '/bm-service-uaa/api/account/update',
                account,
                {
                    responseType: 'json',
                    params: defaultParams,
                    headers: headers
                }
            )
            .pipe(
                map(res => {
                    this.convertItemFromServer(res);
                    this.currentAccount = res;
                    this.setAccount(this.currentAccount);
                    return this.currentAccount;
                })
            );
    }
    saveLocal(account: any) {
        // this.storage.store('account', account);
        this.currentAccount = account;
    }
    clearAccount() {
        // this.storage.store('account', null);
        // this.storage.clear('authenticationToken');
        this.currentAccount = null;
    }

    // fazer chamada do backend para o wso2,
    // wso2 tem endpoint de consulta de usuarios
    // https://everything1know.wordpress.com/2018/09/17/user-account-locking-with-scim-api-in-wso2-identity-server-5-4-0/
    validaEmailExistente(email: string): Observable<boolean> {
        return this.httpClient
            .get(Constants.URL_GATEWAY + '/is/verifica-email/' + email)
            .pipe(
                map((res: Response) => {
                    return false;
                })
            );
    }

    dataURItoBlob(dataURI) {
        // code adapted from: http://stackoverflow.com/questions/33486352/cant-upload-image-to-aws-s3-from-ionic-camera
        const binary = atob(dataURI.split(',')[1]);
        const array = [];
        for (let i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
    }

    convertToSave(account) {
        const copy = Object.assign({}, account);
        if (copy.cadastro && copy.cadastro.pessoaFisica) {
            if (account.cadastro.pessoaFisica.cpf)
                account.cadastro.registro = account.cadastro.pessoaFisica.cpf;

            const dnParsed = this.util.strToZonedDateTime(
                copy.cadastro.pessoaFisica.datanascimento,
                'DD/MM/YYYY'
            );
            copy.cadastro.pessoaFisica.datanascimento = dnParsed;
        }
        return copy;
    }

    private convertItemFromServer(entity: any) {
        if (entity.cadastro === undefined || entity.cadastro === null)
            entity.cadastro = {};
        if (
            entity.cadastro.pessoaFisica === undefined ||
            entity.cadastro.pessoaFisica === null
        )
            entity.cadastro.pessoaFisica = {};
        if (entity.cadastro.pessoaFisica.cpf) {
            if (entity.cadastro.pessoaFisica.cpf)
                entity.cadastro.registro = entity.cadastro.pessoaFisica.cpf;
            entity.cadastro.pessoaFisica.datanascimento = this.util.zonedDateTimeFormat(
                entity.cadastro.pessoaFisica.datanascimento,
                'DD/MM/YYYY'
            );
        }
        return entity;
    }
}
