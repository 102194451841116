import {Injectable, Injector} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {PessoaJuridica} from '../models/pessoa-juridica.model';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ResponsavelPessoaJuridica} from "../models/responsavel-pessoa-juridica.model";

@Injectable({
    providedIn: 'root'
})
export class PessoaJuridicaService  {
    protected http: HttpClient;
    urlResource = `${environment.URL_SCORP_API}/api/pessoas-juridicas`;

    constructor(protected injector: Injector) {
        this.http = injector.get(HttpClient);
    }

    getByCnpj(cnpj: string): Observable<PessoaJuridica> {
        const url = `${this.urlResource}/cnpj/${cnpj}`;

        return this.http
            .get(url)
            .pipe(
                map(json => PessoaJuridica.fromJson(json)),
                catchError(this.handleError.bind(this))
            );
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }
}

