import {BaseModel} from 'app/main/shared/models/base.model';
import {BaseEnum} from '../../shared/models/base.enum ';
import {Pessoa} from './pessoa.model';
import {Pais} from './pais.model';
import {Cargo} from './cargo.model';

export class PessoaFisica extends BaseModel {
    constructor(
        public id?: number,
        public ativo?: boolean,
        public cpf?: string,
        public nome?: string,
        public nomeSocial?: string,
        public mae?: string,
        public pai?: string,
        public naturalidade?: string,
        public dataNascimento?: Date,
        public sexo?: string,
        public estadoCivil?: string,
        public pessoa?: Pessoa,
        public rg?: string,
        public rgOrgaoEmissor?: string,
        public rgUfEmitente?: string,
        public pais?: Pais,
        public cargo?: Cargo,
        public cadastroTecnicoFederal?: string,
    ) {
        super();
    }

    static sexos: BaseEnum[] = [
        {valor: 'M', descricao: 'Masculino'},
        {valor: 'F', descricao: 'Feminino'}
    ];

    static estadosCivis: BaseEnum[] = [
        {valor: 'SOLTEIRO', descricao: 'Solteiro'},
        {valor: 'CASADO', descricao: 'Casado'},
        {valor: 'VIUVO', descricao: 'Viúvo'},
        {valor: 'DIVORCIADO', descricao: 'Divorciado'},
        {valor: 'SEPARADO_JUDICIALMENTE', descricao: 'Separado Judicialmente'}
    ];

    static fromJson(json: any): PessoaFisica {
        if (json === undefined || json === null) {
            return null;
        }
        return new PessoaFisica(
            json.id,
            json.ativo,
            json.cpf,
            json.nome,
            json.nomeSocial,
            json.mae,
            json.pai,
            json.naturalidade,
            json.dataNascimento ? new Date(json.dataNascimento) : null,
            json.sexo,
            json.estadoCivil,
            Pessoa.fromJson(json.pessoa),
            json.rg,
            json.rgOrgaoEmissor,
            json.rgUfEmitente,
            Pais.fromJson(json.pais),
            Cargo.fromJson(json.cargo),
            json.cadastroTecnicoFederal,
        );
    }
}
