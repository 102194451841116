import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {ActivatedRoute} from '@angular/router';

import {MatDialog} from '@angular/material/dialog';
import {Profissao} from './models/profissao.model';
import {AuthenticationService} from '../../services/authentication.service';
import {Usuario} from '../../../pages/usuarios/usuario.model';
import {ConfirmarExclusaoDialogComponent} from '../confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import {ProfissaoService} from './services/profissao.service';
import {PessoaFisicaProfissaoService} from './services/pessoa-fisica-profissao.service';
import {PessoaFisica} from '../../../core/models/pessoa-fisica.model';
import {PessoaFisicaProfissao} from './models/profissao-pessoa-fisica.model';
import {PessoaFisicaProfissaoDto} from './models/pessoa-fisica-profissao-dto.model';
import {PessoaFisicaService} from '../../../core/services/pessoa-fisica.service';
import {SnackBarService} from '../../services/snack-bar/snack-bar.service';


@Component({
    selector: 'app-pessoa-fisica-profissoes',
    templateUrl: './pessoas-fisica-profissoes.html',
    styleUrls: ['./pessoas-fisica-profissoes.component.scss']
})
export class PessoaFisicaProfissaoComponent implements OnInit {

    constructor(
        private formBuilder: FormBuilder,
        private pessoaFisicaService: PessoaFisicaService,
        private profissaoService: ProfissaoService,
        private pessoaFisicaProfissaoService: PessoaFisicaProfissaoService,
        private activatedRoute: ActivatedRoute,
        private snackBarService: SnackBarService,
        private dialog: MatDialog,
        private authenticationService: AuthenticationService
    ) {
    }

    public get hasProfissao(): boolean {
        return (
            typeof this.pessoaFisicaProfissaoList !== 'undefined' && this.pessoaFisicaProfissaoList.length > 0
        );
    }

    @Output() eventNext = new EventEmitter();
    @Output() eventBack = new EventEmitter();
    profissaoForm: FormGroup;
    profissoes: Profissao[];
    pessoaFisicaProfissaoList: PessoaFisicaProfissao[];
    usuario: Usuario;
    pessoaFisica: PessoaFisica;
    profissoesColumns = ['codigo', 'descricao', 'acao'];
    compareWith = Profissao.compareWith;

    ngOnInit(): any {
        this.getUsuario();
        this.buildAtividadeForm();
        this.listaProfissoes();
    }

    public buildAtividadeForm(): void {
        this.profissaoForm = this.formBuilder.group({
            profissao: [null, [Validators.required]]
        });
    }

    getUsuario(): void {
        this.authenticationService.getUser().subscribe(user => {
            if (user != null) {
                this.usuario = user;
                this.loadResource();
            }
        });
    }

    protected loadResource(): void {
        this.pessoaFisicaService.getByCpf(this.usuario.login)
            .subscribe(response => {
                this.pessoaFisica = response;
                this.loadPessoaFisicaProfissoes();
            });
    }

    listaProfissoes(): void {
        this.profissaoService
            .getAllProfissao()
            .subscribe(profissoes => {
                this.profissoes = profissoes;
            });
    }

    public addProfissao(): void {
        this.profissaoForm.markAllAsTouched();
        if (this.profissaoForm.valid) {
            if (this.possuiProfissaoAdicionada()) {
                return;
            }
            this.pessoaFisicaProfissaoService
                .vincularPessoaProfissao(new PessoaFisicaProfissaoDto(this.pessoaFisica.id, this.profissaoForm.value.profissao.id))
                .subscribe(
                    () => {
                        this.loadPessoaFisicaProfissoes();
                        this.snackBarService.showSuccess('Profissão adicionado com sucesso!');
                    },
                    (e) => this.snackBarService.showError('Erro ao adicionar Profissão.', e)
                );
            this.cleanForms();
        }
    }

    public cleanForms(): void {
        this.profissaoForm.reset();
    }

    public loadPessoaFisicaProfissoes(): void {
        this.pessoaFisicaProfissaoService
            .getProfissoesPessoa(this.pessoaFisica.id)
            .subscribe(lista => {
                this.pessoaFisicaProfissaoList = lista;
            });
    }

    public possuiProfissaoAdicionada(): boolean {
        if (
            this.pessoaFisicaProfissaoList.some(
                pessoaFisicaProfissao => pessoaFisicaProfissao.profissao.id === this.profissaoForm.get('profissao').value.id
            )
        ) {
            this.snackBarService.showAlert('Profissão já adicionada.');
            return true;
        }
        return false;
    }

    public removeProfissaoDialog(pessoaFisicaProfissao: PessoaFisicaProfissao): void {
        const dialogRef = this.dialog.open(
            ConfirmarExclusaoDialogComponent,
            {
                width: '300px',
                data: {label: pessoaFisicaProfissao.profissao.descricao}
            }
        );

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.removeProfissao(pessoaFisicaProfissao);
            }
        });
    }

    public removeProfissao(pessoaFisicaProfissao: PessoaFisicaProfissao): void {
        this.pessoaFisicaProfissaoService
            .delete(pessoaFisicaProfissao.id)
            .subscribe(
                () => {
                    this.loadPessoaFisicaProfissoes();
                    this.snackBarService.showSuccess('Profissão excluída com sucesso!');
                },
                (e) => this.snackBarService.showError('Erro ao excluir Profissão.', e)
            );
    }

    public next(): void {
        if (this.hasProfissao) {
            this.eventNext.emit();
        } else {
            this.snackBarService.showAlert('Adicione ao menos uma profissão.');
        }
    }

    public back(): void {
        this.eventBack.emit();
    }
}
