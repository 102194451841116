import { CurrentUser } from './../../../main/shared/models/current-user.model';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject, Subscription, Observable, throwError } from 'rxjs';
import { takeUntil, map, catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import {Router} from '@angular/router';
import { AuthenticationService } from 'app/main/shared/services/authentication.service';
import { SafeUrl } from '@angular/platform-browser';
import * as _ from 'lodash';
import { ImagemService } from 'app/main/core/services/image.service';
import { Imagem } from 'app/main/core/models/imagem.model';
import { userInfo } from 'os';
import { SnackBarService } from 'app/main/shared/services/snack-bar/snack-bar.service';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit, OnDestroy {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    selectedLanguage: any;
    accountSub: Subscription;
    accountInfo: CurrentUser = null;
    url: SafeUrl = '';
    isImageLoading = false;
    imagem: Imagem;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private authenticationService: AuthenticationService,
        private imagemService: ImagemService,
        protected snackBarService: SnackBarService,
        private translate: TranslateService,
        private router: Router,
    ) {
  
        this.languages = [
            {
                id: 'pt',
                title: 'Português',
                flag: 'pt_BR'
            }
        ];

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }


    changeLang(key: string) {
        this._translateService.use(key).subscribe(() => {
            this._translateService.get('global.messages.translate-changed').subscribe((message) => {
                this.snackBarService.showSuccess(message);
            })
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.accountInfo = this.authenticationService.currentUserDecode;
        
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(settings => {
                this.horizontalNavbar =
                    settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {
            id: this._translateService.currentLang
        });

        this.consultarImagem();

        // pegando a idioma da máquina do usuário
        let lang = 'pt';
        const lng = localStorage.getItem('lang');
        console.log(lng);

        if (lng) {
            lang = lng;
        } else {
            let browserLang = this.translate.getBrowserLang();
            lang = browserLang.match(/en|pt|es/) ? browserLang : 'en';
        }

        localStorage.setItem('lang', lang);

        this.translate.use(lang);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        if (this.accountSub) { this.accountSub.unsubscribe(); }
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    public consultarImagem(): void {
        if (this.accountInfo && this.accountInfo.sub){
            this.imagemService.getByUsuarioCPF(this.accountInfo.sub)
            .subscribe( img => {
              this.imagem = img,
              this.getImageFromService();
            });
        }
      }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    profile(): void {
        this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => this.router.navigate(['/profile']));
    }

    sobre(): void {
        this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => this.router.navigate(['/sobre']));
    }

    logout(): void {
        this.authenticationService.logout()
            .subscribe(_ => {
                this.router
                    .navigateByUrl('/', { skipLocationChange: true })
                    .then(() => this.router.navigate(['/login']));
            }, error => {
                    console.log(error);

                    this.snackBarService.showError('Não foi possível realizar o logout.');
            });       
    }

    private getImageFromService(): void {
        if (this.imagem.chave){
            this.isImageLoading = true;
            this.imagemService.getImage(this.imagem).subscribe(data => {
              this.createImageFromBlob(data);
              this.isImageLoading = false;
            }, error => {
              this.isImageLoading = false;
              this.snackBarService.showError('Problema ao consultar imagem de perfil!');
            });
        }
      }

    createImageFromBlob(image: Blob): void {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            this.url = reader.result;
        }, false);

        if (image) {
            reader.readAsDataURL(image);
        }
    }  

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }

   
}
