import { NgModule } from "@angular/core";

import { CoreComponentsModule } from "app/main/core/components/core-components.module";
import { RouterModule } from "@angular/router";
import { FuseSharedModule } from "@fuse/shared.module";
import { FuseSidebarModule } from "@fuse/components";
import { ContentModule } from "./components/content/content.module";
import { NavbarModule } from "./components/navbar/navbar.module";
import { LayoutComponent } from "./layout.component";
import { ToolbarModule } from './components/toolbar/toolbar.module';
import { FooterModule } from './components/footer/footer.module';

@NgModule({
    declarations: [LayoutComponent],
    imports: [
        CoreComponentsModule,
        RouterModule,
        FuseSharedModule,
        FuseSidebarModule,
        CoreComponentsModule,
        ContentModule,
        NavbarModule,
        ToolbarModule,
        FooterModule
    ],
    exports: [LayoutComponent]
})
export class LayoutModule {}
