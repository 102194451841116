import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';

import {FuseSharedModule} from '@fuse/shared.module';
import {ForgotConfirmComponent} from './forgot-confirm.component';

const routes = [
    {
        path: 'auth/forgot-confirm',
        component: ForgotConfirmComponent
    }
];

@NgModule({
    declarations: [ForgotConfirmComponent],
    imports: [RouterModule.forChild(routes), MatIconModule, FuseSharedModule]
})
export class ForgotConfirmModule {}
