import { Injectable, Injector } from '@angular/core';
import { BaseService } from 'app/main/shared/services/base.service';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Documento } from '../models/documento.model';
import { Pessoa } from '../models/pessoa.model';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DocumentoService extends BaseService<Documento> {
    constructor(protected injector: Injector) {
        super(
            '',
            injector,
            Documento.prototype,
            Documento.fromJson
        );
        this.urlResource = environment.URL_SCORP_API;
        this.urlResource += '/api/documentos';
    }

    getByPessoaId(pessoa: Pessoa): Observable<Documento[]> {
        const url = `${this.urlResource}/pessoa/${pessoa.id}`;
        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResources.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    getByPessoaIdCadastro(pessoa: Pessoa): Observable<Documento[]> {
        const url = `${this.urlResource}/pessoa/${pessoa.id}/cadastro`;
        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResources.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    createMultipart(
        documento: Documento,
        multipartFile: Blob
    ): Observable<Documento> {
        return this.http
            .post(
                this.urlResource,
                this.createFormData(documento, multipartFile)
            )
            .pipe(
                map(Documento.fromJson.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    updateMultipart(
        documento: Documento,
        multipartFile: Blob
    ): Observable<Documento> {
        const url = `${this.urlResource}/${documento.id}`;
        return this.http
            .put(url, this.createFormData(documento, multipartFile))
            .pipe(
                map(() => documento),
                catchError(this.handleError.bind(this))
            );
    }

    private createFormData(
        documento: Documento,
        multipartFile: Blob
    ): FormData {
        const formData = new FormData();
        formData.append('multipartFile', multipartFile);
        const documentoParam = new Blob([JSON.stringify(documento)], {
            type: 'application/json'
        });
        formData.append('documento', documentoParam);
        return formData;
    }

    deleteArquivo(id: number): Observable<Documento[]> {
        const url = `${this.urlResource}/${id}/arquivo`;
        return this.http
            .delete(url)
            .pipe(
                map(this.jsonToResources.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

}
