import { Injectable, Injector } from '@angular/core';
import { RoutingStateService } from 'app/main/core/services/routing-state.service';
import { BaseService } from 'app/main/shared/services/base.service';
import { ListResolver } from 'app/main/shared/resolvers/list-resolver';
import { DetailResolver } from 'app/main/shared/resolvers/detail-resolver';
import { Sistema } from './sistema.model';


@Injectable()
export class SistemaListResolver extends ListResolver<Sistema> {
    constructor(service: SistemaService) {
        super(service);
    }
}

@Injectable()
export class SistemaResolver extends DetailResolver<Sistema> {
    constructor(
        service: SistemaService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: 'root'
})
export class SistemaService extends BaseService<Sistema> {
    
    constructor(protected injector: Injector) {
        super(
            '/api/sistemas',
            injector,
            Sistema.prototype,
            Sistema.fromJson
        );
    }
}
