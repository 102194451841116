import { NgModule } from '@angular/core';
import { MatSidenavModule, MatTreeModule } from '@angular/material';
import { RouterModule, Routes } from '@angular/router';
import { CoreModule } from 'app/main/core/core.module';
import { SharedModule } from 'app/main/shared/shared.module';
import { AuthGuardService } from '../../shared/services/auth-guard.service';
import { MenuDetailComponent } from './menu-detail/menu-detail.component';
import { MenuTreeComponent } from './menu-tree/menu-tree.component';
import { MenuListResolver, MenuListAllResolver } from './menu.service';

export const routes: Routes = [
    {
        path: 'menus',
        component: MenuTreeComponent,
        canActivate: [AuthGuardService],
        resolve: {
            data: MenuListAllResolver,
        }
    },
    {
        path: 'menus-page',
        component: MenuTreeComponent,
        canActivate: [AuthGuardService],
        resolve: {
            data: MenuListResolver
        }
    }
];

@NgModule({
    declarations: [MenuDetailComponent, MenuTreeComponent],
    imports: [
        MatSidenavModule,
        MatTreeModule,
        CoreModule,
        SharedModule,
        RouterModule.forChild(routes)
    ],
    entryComponents: [
        MenuDetailComponent,
        MenuTreeComponent
    ],
    providers: [MenuListResolver,MenuListAllResolver]
})
export class MenusModule {}
