import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {fuseAnimations} from '@fuse/animations';
import {FuseConfigService} from '@fuse/services/config.service';
import * as bcrypt from 'bcryptjs';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/internal/operators';
import {AccessService} from '../access.service';
import {ValidateBrService} from 'angular-validate-br';
import {SnackBarService} from '../../../shared/services/snack-bar/snack-bar.service';

@Component({
    selector: 'reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
    resetPasswordForm: FormGroup;

    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private accessService: AccessService,
        private activatedRoute: ActivatedRoute,
        private validateBrService: ValidateBrService,
        private router: Router,
        private snackBarService: SnackBarService
    ) {
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.resetPasswordForm = this._formBuilder.group({
            login: ['', [Validators.required, this.validateBrService.cpf]],
            password: ['', Validators.required],
            passwordConfirm: [
                '',
                [Validators.required, confirmPasswordValidator]
            ]
        });

        this.resetPasswordForm
            .get('password')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.resetPasswordForm
                    .get('passwordConfirm')
                    .updateValueAndValidity();
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    onSubmit(): void {
        const salt = bcrypt.genSaltSync(12);
        const hash = bcrypt.hashSync(
            this.resetPasswordForm.get('password').value,
            salt
        );
        const login = this.resetPasswordForm.get('login').value.match(/\d+/g).join('');
        this.activatedRoute.queryParams.subscribe(params => {
            const token = params['token'];
            this.accessService.newPassword(token, login, hash).subscribe(() => {
                this.router.navigate(['/auth/password-confirm']);
            }, e => this.snackBarService.showError('Erro ao redefinir a senha.', e));
        });
    }
}

export const confirmPasswordValidator: ValidatorFn = (
    control: AbstractControl
): ValidationErrors | null => {
    if (!control.parent || !control) {
        return null;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if (!password || !passwordConfirm) {
        return null;
    }

    if (passwordConfirm.value === '') {
        return null;
    }

    if (password.value === passwordConfirm.value) {
        return null;
    }

    return {passwordsNotMatching: true};
};
