import {Injectable, Injector} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {BaseService} from '../../shared/services/base.service';
import {UsuarioPerfil} from './perfil-detail/perfil-dados-principais-list/usuarios-perfil.model';
import {catchError, map} from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class PerfilUsuarioListService extends BaseService<UsuarioPerfil> {

    constructor(
        injector: Injector,
    ) {
        super(
            '/api/perfis-usuario',
            injector,
            UsuarioPerfil.prototype,
            UsuarioPerfil.fromJson
        );
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }

    excluir(perfilId: number, cpf: string): Observable<any> {
        const url = `${this.urlResource}/perfis/${perfilId}/usuario-cpf/${cpf}`;
        return this.http
            .delete(url).pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

}

