import { Injectable, Injector } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BaseService } from 'app/main/shared/services/base.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Documento } from '../models/documento.model';
import { Imagem } from '../models/imagem.model';
import { JsonPipe } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class ImagemService extends BaseService<Imagem> {

    constructor(
        protected injector: Injector
        ) {
        super(
            '',
            injector,
            Imagem.prototype,
            Imagem.fromJson,
        );
        this.urlResource = environment.URL_GATEWAY;
        this.urlResource += '/api/imagens';
    }

    getByUsuarioCPF(cpf: string): Observable<Imagem> {
        const url = `${this.urlResource}/usuarios/${cpf}`;
        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    getImage(imagem: Imagem): Observable<Blob> {
        const url =  `${this.urlResource}/${imagem.id}/visualizar`;
        return this.http.get(url, { responseType: 'blob' });
    }

    createMultipart(
        imagem: Imagem,
        multipartFile: Blob
    ): Observable<Imagem> {
        return this.http
            .post(
                this.urlResource,
                this.createFormData(imagem, multipartFile)
            )
            .pipe(
                map(Documento.fromJson.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    updateMultipart(
        imagem: Imagem,
        multipartFile: Blob
    ): Observable<Documento> {
        const url = `${this.urlResource}/${imagem.id}`;
        return this.http
            .put(url, this.createFormData(imagem, multipartFile))
            .pipe(
                map(() => imagem),
                catchError(this.handleError.bind(this))
            );
    }

    private createFormData(
        imagem: Imagem,
        multipartFile: Blob
    ): FormData {
        const formData = new FormData();
        formData.append('multipartFile', multipartFile);
        const imagemParam = new Blob([JSON.stringify(imagem)], {
            type: 'application/json'
        });
        formData.append('imagem', imagemParam);
        return formData;
    }
}
