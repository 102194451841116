import {Injectable, Injector} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Municipio} from '../models/municipio.model';
import {environment} from 'environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class MunicipioService {

    private http: HttpClient;
    urlGeoPortalBaseReferencia: string = environment.URL_GEOPORTAL_BASE_REFERENCIA;

    constructor(private injector: Injector) {
        this.http = injector.get(HttpClient);
        this.urlGeoPortalBaseReferencia += '/api/municipios';
    }

    getAllByUf(uf: any): Observable<Municipio[]> {
        return this.http
            .get(this.urlGeoPortalBaseReferencia, {params: new HttpParams().set('uf', uf)})
            .pipe(
                map(this.jsonDataToList),
                catchError(this.handleError.bind(this))
            );
    }

    private jsonDataToList(jsonData: any[]): Municipio[] {
        const entries: Municipio[] = [];

        jsonData.forEach(element => {
            const rt = Object.assign(new Municipio(), element);
            entries.push(rt);
        });
        return entries;
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }
}
