
import { ArtefatoModel, SistemaModuleInfoModel } from "../models/sistema-module-info.model";

export abstract class SistemaModuleInfoUtil {

    public static regexSistemaERotaUrl = new RegExp(/(http[s]?)[\:](\/\/)(.+?(?=))?[\.]?(apps\.ocp\.sema\.mt\.gov\.br|sema\.mt\.gov\.br)[:]?(\d+)?(\/#\/)?(.+)?$/i);
    public static regexArtefatoNome = new RegExp(/((((api)?[a-zA-Z.]+-?[a-zA-Z.]+))(:?[-](web|api|server))([-](desenv|homolog|production))?)|([a-zA-Z.-]+)|(?=([-](web|api|server)))|([^a-zA-Z.]+)/i);
    public static routeSistemaPattern = new RegExp(/\/(.+)(\/#\/)(.+)?$/i);
    public static httpPattern = new RegExp(/(http[s]?):\/\//i);

    public static parseUrl(url: string): SistemaModuleInfoModel {
        let grupos:string[];
        if (SistemaModuleInfoUtil.regexSistemaERotaUrl.test(url)) {
            const matches: RegExpExecArray = SistemaModuleInfoUtil.regexSistemaERotaUrl.exec(url);
            if (matches) grupos  = matches; 
        }
        if (grupos) 
            return SistemaModuleInfoModel.fromKeyValue(grupos);
        return null;
    }

    public static parseArtefato(sistemaNome: string) {
        let grupos:string[];
        if (sistemaNome)
            if (SistemaModuleInfoUtil.regexArtefatoNome.test(sistemaNome)) {
                const matches = sistemaNome.match(SistemaModuleInfoUtil.regexArtefatoNome);
                if (matches) grupos  = matches; 
            }
        if (grupos) 
            return ArtefatoModel.fromKeyValue(grupos);
        return null;
    }

    /**
     * Reverte rota para url baseado no ambiente atual
     * // /licenciamento/#/atividades/licenciamento-empreendimento/suimis-atividades?teste=1&param2=teste  -> http://licenciamento-web-homolog.apps.ocp.sema.mt.gov.br/#/atividades/licenciamento-empreendimento/suimis-atividades?teste=1&param2=teste
     * @param routeUrl 
     */
    public static reverseRouteToUrl(routeUrl: string) {
        const locationUrl = document.location.href;
        const protocol = locationUrl.match(SistemaModuleInfoUtil.httpPattern);

        if (SistemaModuleInfoUtil.routeSistemaPattern.test(routeUrl)) {
            const matchesRoute = routeUrl.match(SistemaModuleInfoUtil.routeSistemaPattern);
            const matchesLocationEnvInfo = locationUrl.match(SistemaModuleInfoUtil.regexSistemaERotaUrl); 
            const sistemaInfo = SistemaModuleInfoModel.fromKeyValue(matchesLocationEnvInfo);
            const routeInfo = SistemaModuleInfoModel.routeFromKeyValue(matchesRoute);
            const routeSistemaNome = matchesRoute[1]; 
            const env = sistemaInfo.artefatoInfo.env;
            let envContext = env == 'prod' ? `${sistemaInfo.artefatoInfo.serverOrWeb == 'server' ? 'api' : ''}` : `${sistemaInfo.artefatoInfo.serverOrWeb ? '-' + sistemaInfo.artefatoInfo.serverOrWeb : ''}${sistemaInfo.artefatoInfo.env ? '-' + sistemaInfo.artefatoInfo.env : ''}`;
           return  `${protocol[1]}://${routeSistemaNome ? routeSistemaNome : ''}${envContext}.${sistemaInfo.domainPattern}${routeInfo.hash || ''}/#/${routeInfo.rota}`
        }
        return null;
        
        
    }
    
    
}