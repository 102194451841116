import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { FuseNavigation, FuseNavigationItem } from '@fuse/types';
import { TranslateService } from '@ngx-translate/core';
import { PortalSecurityService } from '@sema-security-angular/portal-security';
import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationPortugues } from 'app/navigation/i18n/pt';
import { locale as navigationTurkish } from 'app/navigation/i18n/tr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CoreMessageService } from './main/core/services/core-message.service';
import { RoutingStateService } from './main/core/services/routing-state.service'; 
import { SistemaModuleInfoModel } from './main/shared/models/sistema-module-info.model';
import { AuthenticatedUserService } from './main/shared/services/authenticated-user.service';
import { AuthenticationService } from './main/shared/services/authentication.service';
import { SistemaModuleInfoUtil } from './main/shared/services/sistema-modulo-identifier.util';


@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;

    private DISCONNECTED = 'DISCONNECTED';
    private CONNECTED = 'CONNECTED';
    private loadingNavigation = false;

    private _unsubscribeAll: Subject<any>;

    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private _authenticationService: AuthenticationService,
        private _authenticatedUserService: AuthenticatedUserService,
        _routingState: RoutingStateService, 
        private route: ActivatedRoute,
        private router: Router,
        private translateService: TranslateService,
    ) {
        _routingState.loadRouting();

        this._fuseNavigationService.register(this.DISCONNECTED, [{ id: 'logar', title: 'Logar', type: 'item', url: 'login' }]);
        this._fuseNavigationService.setCurrentNavigation(this.DISCONNECTED);

        // Add languages
        this._translateService.addLangs(['pt-br','pt','en','es']);

        // Set the default language
        this._translateService.setDefaultLang('pt-br');

        // browser lang
        this.translateService.use(translateService.getBrowserLang() || 'pt');
        

        // Set the navigation translations
        // this._fuseTranslationLoaderService.loadTranslations(
        //     navigationPortugues,
        //     navigationEnglish,
        //     navigationTurkish
        // );

        // Use a language
        this._translateService.use('pt');

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
 
    }

    ngOnInit(): void {
        this.route.params.subscribe((p => {
            if (p.returnUrl) this.redirectUrl(p.returnUrl);  
            
        }));

        this.route.queryParams.subscribe((p => {
            if (p.returnUrl) this.redirectUrl(p.returnUrl); 
        }));


        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config: any) => {
                this.fuseConfig = config;

                // Boxed
                if (this.fuseConfig.layout.width === 'boxed') {
                    this.document.body.classList.add('boxed');
                } else {
                    this.document.body.classList.remove('boxed');
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (className.startsWith('theme-')) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });

        this._authenticationService.currentUserToken.pipe(takeUntil(this._unsubscribeAll)).subscribe(usuario => {
            if (usuario && this._fuseNavigationService.getCurrentNavigationKey() !== this.CONNECTED) {
                if (!this.loadingNavigation){
                    this.initializeNavigation();
                }
            } else if (!usuario && this._fuseNavigationService.getCurrentNavigationKey() === this.CONNECTED) {
                this._fuseNavigationService.unregister(this.CONNECTED);
                this._fuseNavigationService.setCurrentNavigation(this.DISCONNECTED);
            }
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    private redirectUrl(url: string) {
        if (this._authenticationService.redirectUrl == null)
            this._authenticationService.redirectUrl = url;
       
        
        if (this._authenticationService.redirectUrl  != null) { 
            
            const urlSistemaSema = SistemaModuleInfoUtil.parseUrl(this._authenticationService.redirectUrl ); 
      
            if (urlSistemaSema) {
                if (this._authenticatedUserService.isAuthenticated && this._authenticatedUserService.identityToken ) {
                        this._authenticationService.getUser().subscribe(()=> {
                            if (urlSistemaSema && urlSistemaSema.urlCompleta) {
                                if (urlSistemaSema.artefatoInfo.name == 'portal' || urlSistemaSema.artefatoInfo.name == 'local')  {
                                    // previne portal dentro do portal (navega rota portal)
                                    return this.router.navigateByUrl( urlSistemaSema.rota, { replaceUrl: true} )
                                        .then(_=>this._authenticationService.redirectUrl = null);
                                } 
                                return this.router.navigate(['/pages'], { queryParams: { _redirectUrl: urlSistemaSema.urlCompleta}})
                                    .then(_=>this._authenticationService.redirectUrl = null);

                            }
                            this.router.navigateByUrl('/dashboard')
                                .then(_=>this._authenticationService.redirectUrl = null);
                        },()=> {
                            this.router.navigateByUrl(`/login?returnUrl=${urlSistemaSema ? urlSistemaSema.urlCompleta : '/'}`)
                                .then(_=>this._authenticationService.redirectUrl = null);
                        }); 
                } else {
                    this.router.navigateByUrl(`/login?returnUrl=${urlSistemaSema ? urlSistemaSema.urlCompleta : '/'}`);
                }
                
            } else {
                // url externa
                if (this._authenticationService.redirectUrl.startsWith('http')) {
                    this.document.defaultView.location.href = this._authenticationService.redirectUrl ;
                } else {
                    // rota
                    let urlRota = SistemaModuleInfoUtil.reverseRouteToUrl(this._authenticationService.redirectUrl );
                    const urlSistemaSema = SistemaModuleInfoUtil.parseUrl(urlRota );
                   
                    if (this._authenticatedUserService.isAuthenticated && this._authenticatedUserService.identityToken ) { 
                            this._authenticationService.getUser().subscribe(()=> {
                                if (urlRota) {
                                    if (urlSistemaSema.artefatoInfo.name == 'portal' || urlSistemaSema.artefatoInfo.name == 'local')  {
                                        // previne portal dentro do portal (navega rota portal)
                                        return this.router.navigateByUrl( urlSistemaSema.rota )
                                            .then(_=>this._authenticationService.redirectUrl = null);
                                    } 
                                    return this.router.navigate(['/pages'], { queryParams: { _redirectUrl: encodeURIComponent(urlRota)}})
                                        .then(_=>this._authenticationService.redirectUrl = null);
                                }
                                this.router.navigateByUrl('/dashboard').then(_=>this._authenticationService.redirectUrl = null);
                            },()=> {
                                this.router.navigateByUrl(`/login?returnUrl=${ urlRota ? encodeURIComponent(urlRota) : '/' }`, { replaceUrl: true})
                                    .then(_=>this._authenticationService.redirectUrl = null);
                            });
                    } else {
                        this.router.navigateByUrl(`/login?returnUrl=${  urlRota ? encodeURIComponent(urlRota) : '/'  }`, { replaceUrl: true});
                    }
                    
                }
            }
        
        }
    }
 
    toggleSidebarOpen(key: any): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    private initializeNavigation(): void {
        this.loadingNavigation = true;
        this._fuseSplashScreenService.show();
        this._authenticationService.navigation().subscribe(((sistemas: any[]) => {
            let navigation = this.sistemasToFuseNavigation(sistemas);
            navigation = this.jumpAndSort(navigation);

            if (this.CONNECTED !== this._fuseNavigationService.getCurrentNavigationKey()) {
                this._fuseNavigationService.register(this.CONNECTED, navigation);
                this._fuseNavigationService.setCurrentNavigation(this.CONNECTED);
            }

            this.loadingNavigation = false;
            this._fuseSplashScreenService.hide();
        }));
    }

    private jumpAndSort(fuseNavigations: FuseNavigation[]): FuseNavigation[] {
        let retorno = [];
        fuseNavigations.forEach(fuseNavigation => {
            if (fuseNavigation.children && fuseNavigation.children.length > 0) {
                fuseNavigation.children = this.jumpAndSort(fuseNavigation.children);
            }
            if (fuseNavigation.jump) {
                if (fuseNavigation.children && fuseNavigation.children.length > 0) {
                    retorno = retorno.concat(fuseNavigation.children);
                }
            } else {
                retorno = retorno.concat(fuseNavigation);
            }
        });
        retorno = retorno.sort((a, b) => a.order > b.order ? 1 : -1);
        return retorno;
    }

    private sistemasToFuseNavigation(sistemas: any[]): FuseNavigation[] {
        return sistemas.map(sistema => {
            const fuseNavigation: FuseNavigation = {
                id: sistema.id,
                title: sistema.nome,
                type: 'collapsable',
                icon: sistema.icone || 'public',
                jump: sistema.pular || false,
                order: sistema.posicao || 0
            };
            if (sistema.modulos) {
                fuseNavigation.children = this.modulosToFuseNavigationItem(sistema.modulos, sistema);
            }
            return fuseNavigation;
        });
    }

    private modulosToFuseNavigationItem(modulos: any[], sistema: any): FuseNavigation[] {
        return modulos.map(modulo => {
            const fuseNavigation: FuseNavigation = {
                id: modulo.id,
                title: modulo.nome,
                type: 'collapsable',
                icon: modulo.icone || 'public',
                jump: modulo.pular || false,
                order: modulo.posicao || 0
            };
            if (modulo.menus && modulo.menus.length > 0) {
                fuseNavigation.children = this.menusToFuseNavigationItem(modulo.menus, sistema);
            }
            return fuseNavigation;
        });
    }

    private menusToFuseNavigationItem(menus: any[], sistema: any): FuseNavigationItem[] {
        return menus.map(menu => {
            const fuseNavigationItem: FuseNavigationItem = {
                id: menu.id,
                title: menu.descricao,
                type: menu.menus && menu.menus.length > 0 ? 'collapsable' : 'item',
                icon: menu.icone || 'public',
                order: menu.posicao || 0
            };
            if (menu.menus && menu.menus.length > 0) {
                fuseNavigationItem.children = this.menusToFuseNavigationItem(menu.menus, sistema);
            } else {
                menu.sistema = sistema;
                fuseNavigationItem.menu = menu;
            }
            return fuseNavigationItem;
        });
    }

}
