import {ChangeDetectionStrategy, Component, ViewChild} from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import {MatStepper} from '@angular/material/stepper';

@Component({
    selector: 'app-usuario-wizard',
    templateUrl: './usuario-wizard.component.html',
    styleUrls: ['./usuario-wizard.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations
})
export class UsuarioWizardComponent {
    @ViewChild('stepper', {static: true})
    protected stepper: MatStepper;

    constructor(private _fuseConfigService: FuseConfigService) {
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: false
                },
                footer: {
                    hidden: false
                },
                sidepanel: {
                    hidden: false
                }
            }
        };
    }

    nextStep(): void{
        this.stepper.next();
    }

    previousStep(): void{
        this.stepper.previous();
    }

}
