import { ChangeDetectorRef, Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseNavigationItem } from '@fuse/types';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'fuse-nav-item',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.scss']
})
export class FuseNavItemComponent implements OnInit, OnDestroy {

    @HostBinding('class')
    classes = 'nav-item';

    @Input()
    item: FuseNavigationItem;

    @ViewChild('link', { static: false })
    linkElement: ElementRef;

    private _unsubscribeAll: Subject<any>;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _router: Router
    ) {
        this._unsubscribeAll = new Subject();

        this._fuseNavigationService.onCurrentNavigationItemChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(item => {
            if (item && this.linkElement) {
                if (item.id === this.item.id){
                    this.linkElement.nativeElement.classList.add('active');
                    this.linkElement.nativeElement.classList.add('accent');
                } else {
                    this.linkElement.nativeElement.classList.remove('active');
                    this.linkElement.nativeElement.classList.remove('accent');
                }
            }
        });
    }

    navigate(ev): void {
        ev.preventDefault();
        const menu = this.item.menu;
        if (menu.sistema.nome.toLowerCase().includes('portal')) {
            this._router.navigateByUrl(this.tratarUrl(menu.urlRota), { skipLocationChange: true });
        } else if (menu.urlIframe && menu.urlIframe.length > 1) {
            this._router.navigateByUrl('iframe', { skipLocationChange: true });
        } else {
            console.error('A url do iframe do menu ', menu.descricao, ' do sistema ', menu.sistema.nome, ' não foi definida.');
            this._fuseNavigationService.setCurrentNavigationItem(null);
            this._router.navigateByUrl('/error/error-404', { skipLocationChange: true, replaceUrl: true });
            return;
        }
        this._fuseNavigationService.setCurrentNavigationItem(this.item);
    }

    private tratarUrl(url: string): string {
        return url.substring(url.startsWith('/') ? 1 : 0, url.length - (url.endsWith('/') ? 1 : 0));
    }

    ngOnInit(): void {
        merge(this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this._changeDetectorRef.markForCheck();
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
