import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ResponsavelPessoaJuridica} from '../../../../../shared/models/responsavel-pessoa-juridica.model';
import {Representante} from '../../../../../shared/models/representante.model';
import {BaseModel} from '../../../../../shared/models/base.model';
import {RepresentanteService} from '../../../../../shared/services/representante.service';
import {SnackBarService} from '../../../../../shared/services/snack-bar/snack-bar.service';

@Component({
    selector: 'app-dialog-editar-vinculo-pf-pj',
    templateUrl: './dialog-editar-vinculo-pf-pj.component.html',
    styleUrls: ['./dialog-editar-vinculo-pf-pj.component.scss']
})
export class DialogEditarVinculoPfPjComponent implements OnInit {

    tiposRepresentantes = Representante.tiposRepresentante;
    comparador = (a: BaseModel, b: BaseModel) =>
        a && b ? a.id === b.id : a === b
    constructor(
        private dialogRef: MatDialogRef<DialogEditarVinculoPfPjComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Representante,
        private responsavelService: RepresentanteService,
        private snackBarService: SnackBarService
    ) {
    }

    ngOnInit(): void {
    }

    confirm(): void {
        this.responsavelService
            .update(this.data).subscribe(
            () => {
                this.snackBarService.showSuccess('Resposável  adicionado com sucesso!');
                this.dialogRef.close(true);
            },
            error => {
                this.snackBarService.showError('Erro ao adicionar resposável.', error);
            }
        );
    }
}
