import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { PortalSecurityModule } from '@sema-security-angular/portal-security';
import { environment } from 'environments/environment';
import { AuthenticatedUserService } from '../shared/services/authenticated-user.service';
import { CoreComponentsModule } from './components/core-components.module';
import { ApplicationStateService } from './services/application-state.service';
import { LanguageHelper } from './services/language-helper.service';
import { UtilService } from './services/util.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        TranslateModule,
        FuseSharedModule,
        FontAwesomeModule,
        CoreComponentsModule,
        PortalSecurityModule,
    ],
    exports: [ CommonModule, PortalSecurityModule, FuseSharedModule, FontAwesomeModule, CoreComponentsModule ],
    providers: [
        UtilService,
        LanguageHelper,
        ApplicationStateService,
        AuthenticatedUserService
    ],
    declarations: []
})
export class CoreModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: CoreModule
        };
    }
}
