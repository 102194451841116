export enum MaterialOutlineIcons {
    _3d_rotation,
    _4k,
    _360,
    accessibility,
    accessibility_new,
    accessible,
    accessible_forward,
    access_alarm,
    access_alarms,
    access_time,
    account_balance,
    account_balance_wallet,
    account_box,
    account_circle,
    ac_unit,
    adb,
    add,
    add_alarm,
    add_alert,
    add_a_photo,
    add_box,
    add_circle,
    add_circle_outline,
    add_comment,
    add_location,
    add_photo_alternate,
    add_shopping_cart,
    add_to_home_screen,
    add_to_photos,
    add_to_queue,
    adjust,
    airline_seat_flat,
    airline_seat_flat_angled,
    airline_seat_individual_suite,
    airline_seat_legroom_extra,
    airline_seat_legroom_normal,
    airline_seat_legroom_reduced,
    airline_seat_recline_extra,
    airline_seat_recline_normal,
    airplanemode_active,
    airplanemode_inactive,
    airplay,
    airport_shuttle,
    alarm,
    alarm_add,
    alarm_off,
    alarm_on,
    album,
    all_inbox,
    all_inclusive,
    all_out,
    alternate_email,
    android,
    announcement,
    apps,
    archive,
    arrow_back,
    arrow_back_ios,
    arrow_downward,
    arrow_drop_down,
    arrow_drop_down_circle,
    arrow_drop_up,
    arrow_forward,
    arrow_forward_ios,
    arrow_left,
    arrow_right,
    arrow_right_alt,
    arrow_upward,
    art_track,
    aspect_ratio,
    assessment,
    assignment,
    assignment_ind,
    assignment_late,
    assignment_return,
    assignment_returned,
    assignment_turned_in,
    assistant,
    assistant_photo,
    atm,
    attachment,
    attach_file,
    attach_money,
    audiotrack,
    autorenew,
    av_timer,
    backspace,
    backup,
    ballot,
    bar_chart,
    battery_alert,
    battery_charging_full,
    battery_full,
    battery_std,
    battery_unknown,
    beach_access,
    beenhere,
    block,
    bluetooth,
    bluetooth_audio,
    bluetooth_connected,
    bluetooth_disabled,
    bluetooth_searching,
    blur_circular,
    blur_linear,
    blur_off,
    blur_on,
    book,
    bookmark,
    bookmarks,
    bookmark_border,
    border_all,
    border_bottom,
    border_clear,
    border_horizontal,
    border_inner,
    border_left,
    border_outer,
    border_right,
    border_style,
    border_top,
    border_vertical,
    branding_watermark,
    brightness_1,
    brightness_2,
    brightness_3,
    brightness_4,
    brightness_5,
    brightness_6,
    brightness_7,
    brightness_auto,
    brightness_high,
    brightness_low,
    brightness_medium,
    broken_image,
    brush,
    bubble_chart,
    bug_report,
    build,
    burst_mode,
    business,
    business_center,
    cached,
    cake,
    calendar_today,
    calendar_view_day,
    call,
    call_end,
    call_made,
    call_merge,
    call_missed,
    call_missed_outgoing,
    call_received,
    call_split,
    call_to_action,
    camera,
    camera_alt,
    camera_enhance,
    camera_front,
    camera_rear,
    camera_roll,
    cancel,
    cancel_presentation,
    card_giftcard,
    card_membership,
    card_travel,
    casino,
    cast,
    cast_connected,
    cast_for_education,
    category,
    center_focus_strong,
    center_focus_weak,
    change_history,
    chat,
    chat_bubble,
    chat_bubble_outline,
    check,
    check_box,
    check_box_outline_blank,
    check_circle,
    check_circle_outline,
    chevron_left,
    chevron_right,
    child_care,
    child_friendly,
    chrome_reader_mode,
    class,
    clear,
    clear_all,
    close,
    closed_caption,
    cloud,
    cloud_circle,
    cloud_done,
    cloud_download,
    cloud_off,
    cloud_queue,
    cloud_upload,
    code,
    collections,
    collections_bookmark,
    colorize,
    color_lens,
    comment,
    commute,
    compare,
    compare_arrows,
    compass_calibration,
    computer,
    confirmation_number,
    contacts,
    contact_mail,
    contact_phone,
    contact_support,
    control_camera,
    control_point,
    control_point_duplicate,
    copyright,
    create,
    create_new_folder,
    credit_card,
    crop,
    crop_3_2,
    crop_5_4,
    crop_7_5,
    crop_16_9,
    crop_din,
    crop_free,
    crop_landscape,
    crop_original,
    crop_portrait,
    crop_rotate,
    crop_square,
    dashboard,
    data_usage,
    date_range,
    dehaze,
    delete,
    delete_forever,
    delete_outline,
    delete_sweep,
    departure_board,
    description,
    desktop_access_disabled,
    desktop_mac,
    desktop_windows,
    details,
    developer_board,
    developer_mode,
    devices,
    devices_other,
    device_hub,
    device_unknown,
    dialer_sip,
    dialpad,
    directions,
    directions_bike,
    directions_boat,
    directions_bus,
    directions_car,
    directions_railway,
    directions_run,
    directions_subway,
    directions_transit,
    directions_walk,
    disc_full,
    dns,
    dock,
    domain,
    domain_disabled,
    done,
    done_all,
    done_outline,
    donut_large,
    donut_small,
    drafts,
    drag_handle,
    drag_indicator,
    drive_eta,
    duo,
    dvr,
    edit,
    edit_attributes,
    edit_location,
    eject,
    email,
    enhanced_encryption,
    equalizer,
    error,
    error_outline,
    euro_symbol,
    event,
    event_available,
    event_busy,
    event_note,
    event_seat,
    ev_station,
    exit_to_app,
    expand_less,
    expand_more,
    explicit,
    explore,
    explore_off,
    exposure,
    exposure_neg_1,
    exposure_neg_2,
    exposure_plus_1,
    exposure_plus_2,
    exposure_zero,
    extension,
    face,
    fastfood,
    fast_forward,
    fast_rewind,
    favorite,
    favorite_border,
    featured_play_list,
    featured_video,
    feedback,
    fiber_dvr,
    fiber_manual_record,
    fiber_new,
    fiber_pin,
    fiber_smart_record,
    file_copy,
    filter,
    filter_1,
    filter_2,
    filter_3,
    filter_4,
    filter_5,
    filter_6,
    filter_7,
    filter_8,
    filter_9,
    filter_9_plus,
    filter_b_and_w,
    filter_center_focus,
    filter_drama,
    filter_frames,
    filter_hdr,
    filter_list,
    filter_none,
    filter_tilt_shift,
    filter_vintage,
    find_in_page,
    find_replace,
    fingerprint,
    first_page,
    fitness_center,
    flag,
    flare,
    flash_auto,
    flash_off,
    flash_on,
    flight,
    flight_land,
    flight_takeoff,
    flip,
    flip_to_back,
    flip_to_front,
    folder,
    folder_open,
    folder_shared,
    folder_special,
    font_download,
    format_align_center,
    format_align_justify,
    format_align_left,
    format_align_right,
    format_bold,
    format_clear,
    format_color_reset,
    format_indent_decrease,
    format_indent_increase,
    format_italic,
    format_line_spacing,
    format_list_bulleted,
    format_list_numbered,
    format_list_numbered_rtl,
    format_paint,
    format_quote,
    format_shapes,
    format_size,
    format_strikethrough,
    format_textdirection_l_to_r,
    format_textdirection_r_to_l,
    format_underlined,
    forum,
    forward,
    forward_5,
    forward_10,
    forward_30,
    free_breakfast,
    fullscreen,
    fullscreen_exit,
    functions,
    gamepad,
    games,
    gavel,
    gesture,
    get_app,
    gif,
    golf_course,
    gps_fixed,
    gps_not_fixed,
    gps_off,
    grade,
    gradient,
    grain,
    graphic_eq,
    grid_off,
    grid_on,
    group,
    group_add,
    group_work,
    g_translate,
    hd,
    hdr_off,
    hdr_on,
    hdr_strong,
    hdr_weak,
    headset,
    headset_mic,
    healing,
    hearing,
    help,
    help_outline,
    highlight,
    highlight_off,
    high_quality,
    history,
    home,
    horizontal_split,
    hotel,
    hot_tub,
    hourglass_empty,
    hourglass_full,
    how_to_reg,
    how_to_vote,
    http,
    https,
    image,
    image_aspect_ratio,
    image_search,
    important_devices,
    import_contacts,
    import_export,
    inbox,
    indeterminate_check_box,
    info,
    input,
    insert_chart,
    insert_chart_outlined,
    insert_comment,
    insert_drive_file,
    insert_emoticon,
    insert_invitation,
    insert_link,
    insert_photo,
    invert_colors,
    invert_colors_off,
    iso,
    keyboard,
    keyboard_arrow_down,
    keyboard_arrow_left,
    keyboard_arrow_right,
    keyboard_arrow_up,
    keyboard_backspace,
    keyboard_capslock,
    keyboard_hide,
    keyboard_return,
    keyboard_tab,
    keyboard_voice,
    kitchen,
    label,
    label_important,
    label_off,
    landscape,
    language,
    laptop,
    laptop_chromebook,
    laptop_mac,
    laptop_windows,
    last_page,
    launch,
    layers,
    layers_clear,
    leak_add,
    leak_remove,
    lens,
    library_add,
    library_books,
    library_music,
    linear_scale,
    line_style,
    line_weight,
    link,
    linked_camera,
    link_off,
    list,
    list_alt,
    live_help,
    live_tv,
    local_activity,
    local_airport,
    local_atm,
    local_bar,
    local_cafe,
    local_car_wash,
    local_convenience_store,
    local_dining,
    local_drink,
    local_florist,
    local_gas_station,
    local_grocery_store,
    local_hospital,
    local_hotel,
    local_laundry_service,
    local_library,
    local_mall,
    local_movies,
    local_offer,
    local_parking,
    local_pharmacy,
    local_phone,
    local_pizza,
    local_play,
    local_post_office,
    local_printshop,
    local_see,
    local_shipping,
    local_taxi,
    location_city,
    location_disabled,
    location_off,
    location_on,
    location_searching,
    lock,
    lock_open,
    looks,
    looks_3,
    looks_4,
    looks_5,
    looks_6,
    looks_one,
    looks_two,
    loop,
    loupe,
    low_priority,
    loyalty,
    mail,
    mail_outline,
    map,
    markunread,
    markunread_mailbox,
    maximize,
    meeting_room,
    memory,
    menu,
    merge_type,
    message,
    mic,
    mic_none,
    mic_off,
    minimize,
    missed_video_call,
    mms,
    mobile_friendly,
    mobile_off,
    mobile_screen_share,
    mode_comment,
    monetization_on,
    money,
    money_off,
    monochrome_photos,
    mood,
    mood_bad,
    more,
    more_horiz,
    more_vert,
    motorcycle,
    mouse,
    move_to_inbox,
    movie,
    movie_creation,
    movie_filter,
    multiline_chart,
    music_note,
    music_off,
    music_video,
    my_location,
    nature,
    nature_people,
    navigate_before,
    navigate_next,
    navigation,
    near_me,
    network_check,
    network_locked,
    new_releases,
    next_week,
    nfc,
    note,
    notes,
    note_add,
    notifications,
    notifications_active,
    notifications_none,
    notifications_off,
    notifications_paused,
    notification_important,
    not_interested,
    not_listed_location,
    no_encryption,
    no_meeting_room,
    no_sim,
    offline_bolt,
    offline_pin,
    ondemand_video,
    opacity,
    open_in_browser,
    open_in_new,
    open_with,
    outlined_flag,
    pages,
    pageview,
    palette,
    panorama,
    panorama_fish_eye,
    panorama_horizontal,
    panorama_vertical,
    panorama_wide_angle,
    pan_tool,
    party_mode,
    pause,
    pause_circle_filled,
    pause_circle_outline,
    pause_presentation,
    payment,
    people,
    people_outline,
    perm_camera_mic,
    perm_contact_calendar,
    perm_data_setting,
    perm_device_information,
    perm_identity,
    perm_media,
    perm_phone_msg,
    perm_scan_wifi,
    person,
    personal_video,
    person_add,
    person_add_disabled,
    person_outline,
    person_pin,
    person_pin_circle,
    pets,
    phone,
    phonelink,
    phonelink_erase,
    phonelink_lock,
    phonelink_off,
    phonelink_ring,
    phonelink_setup,
    phone_android,
    phone_bluetooth_speaker,
    phone_callback,
    phone_forwarded,
    phone_in_talk,
    phone_iphone,
    phone_locked,
    phone_missed,
    phone_paused,
    photo,
    photo_album,
    photo_camera,
    photo_filter,
    photo_library,
    photo_size_select_actual,
    photo_size_select_large,
    photo_size_select_small,
    picture_as_pdf,
    picture_in_picture,
    picture_in_picture_alt,
    pie_chart,
    pin_drop,
    place,
    playlist_add,
    playlist_add_check,
    playlist_play,
    play_arrow,
    play_circle_filled,
    play_circle_filled_white,
    play_circle_outline,
    play_for_work,
    plus_one,
    poll,
    polymer,
    pool,
    portable_wifi_off,
    portrait,
    power,
    power_input,
    power_off,
    power_settings_new,
    pregnant_woman,
    present_to_all,
    print,
    print_disabled,
    priority_high,
    public,
    publish,
    query_builder,
    question_answer,
    queue,
    queue_music,
    queue_play_next,
    radio,
    radio_button_checked,
    radio_button_unchecked,
    rate_review,
    receipt,
    recent_actors,
    record_voice_over,
    redeem,
    redo,
    refresh,
    remove,
    remove_circle,
    remove_circle_outline,
    remove_from_queue,
    remove_red_eye,
    remove_shopping_cart,
    reorder,
    repeat,
    repeat_one,
    replay,
    replay_5,
    replay_10,
    replay_30,
    reply,
    reply_all,
    report,
    report_off,
    report_problem,
    restaurant,
    restaurant_menu,
    restore,
    restore_from_trash,
    restore_page,
    ring_volume,
    room,
    room_service,
    rotate_90_degrees_ccw,
    rotate_left,
    rotate_right,
    rounded_corner,
    router,
    rowing,
    rss_feed,
    rv_hookup,
    satellite,
    save,
    save_alt,
    scanner,
    scatter_plot,
    schedule,
    school,
    score,
    screen_lock_landscape,
    screen_lock_portrait,
    screen_lock_rotation,
    screen_rotation,
    screen_share,
    sd_card,
    sd_storage,
    search,
    security,
    select_all,
    send,
    sentiment_dissatisfied,
    sentiment_satisfied,
    sentiment_satisfied_alt,
    sentiment_very_dissatisfied,
    sentiment_very_satisfied,
    settings,
    settings_applications,
    settings_backup_restore,
    settings_bluetooth,
    settings_brightness,
    settings_cell,
    settings_ethernet,
    settings_input_antenna,
    settings_input_component,
    settings_input_composite,
    settings_input_hdmi,
    settings_input_svideo,
    settings_overscan,
    settings_phone,
    settings_power,
    settings_remote,
    settings_system_daydream,
    settings_voice,
    share,
    shop,
    shopping_basket,
    shopping_cart,
    shop_two,
    short_text,
    show_chart,
    shuffle,
    shutter_speed,
    signal_cellular_0_bar,
    signal_cellular_4_bar,
    signal_cellular_alt,
    signal_cellular_connected_no_internet_4_bar,
    signal_cellular_no_sim,
    signal_cellular_null,
    signal_cellular_off,
    signal_wifi_0_bar,
    signal_wifi_4_bar,
    signal_wifi_4_bar_lock,
    signal_wifi_off,
    sim_card,
    skip_next,
    skip_previous,
    slideshow,
    slow_motion_video,
    smartphone,
    smoke_free,
    smoking_rooms,
    sms,
    sms_failed,
    snooze,
    sort,
    sort_by_alpha,
    spa,
    space_bar,
    speaker,
    speaker_group,
    speaker_notes,
    speaker_notes_off,
    speaker_phone,
    spellcheck,
    star,
    stars,
    star_border,
    star_half,
    star_rate,
    stay_current_landscape,
    stay_current_portrait,
    stay_primary_landscape,
    stay_primary_portrait,
    stop,
    stop_screen_share,
    storage,
    store,
    store_mall_directory,
    straighten,
    streetview,
    strikethrough_s,
    style,
    subdirectory_arrow_left,
    subdirectory_arrow_right,
    subject,
    subscriptions,
    subtitles,
    subway,
    supervised_user_circle,
    supervisor_account,
    surround_sound,
    swap_calls,
    swap_horiz,
    swap_horizontal_circle,
    swap_vert,
    swap_vertical_circle,
    switch_camera,
    switch_video,
    sync,
    sync_disabled,
    sync_problem,
    system_update,
    tab,
    tablet,
    tablet_android,
    tablet_mac,
    table_chart,
    tab_unselected,
    tag_faces,
    tap_and_play,
    terrain,
    textsms,
    texture,
    text_fields,
    text_format,
    text_rotate_up,
    text_rotate_vertical,
    text_rotation_down,
    text_rotation_none,
    theaters,
    thumbs_up_down,
    thumb_down,
    thumb_down_alt,
    thumb_up,
    thumb_up_alt,
    timelapse,
    timeline,
    timer,
    timer_3,
    timer_10,
    timer_off,
    time_to_leave,
    title,
    toc,
    today,
    toggle_off,
    toggle_on,
    toll,
    tonality,
    touch_app,
    toys,
    track_changes,
    traffic,
    train,
    tram,
    transfer_within_a_station,
    transform,
    transit_enterexit,
    translate,
    trending_down,
    trending_flat,
    trending_up,
    trip_origin,
    tune,
    turned_in,
    turned_in_not,
    tv,
    tv_off,
    unarchive,
    undo,
    unfold_less,
    unfold_more,
    unsubscribe,
    update,
    usb,
    verified_user,
    vertical_align_bottom,
    vertical_align_center,
    vertical_align_top,
    vertical_split,
    vibration,
    videocam,
    videocam_off,
    videogame_asset,
    video_call,
    video_label,
    video_library,
    view_agenda,
    view_array,
    view_carousel,
    view_column,
    view_comfy,
    view_compact,
    view_day,
    view_headline,
    view_list,
    view_module,
    view_quilt,
    view_stream,
    view_week,
    vignette,
    visibility,
    visibility_off,
    voicemail,
    voice_chat,
    voice_over_off,
    volume_down,
    volume_mute,
    volume_off,
    volume_up,
    vpn_key,
    vpn_lock,
    wallpaper,
    warning,
    watch,
    watch_later,
    waves,
    wb_auto,
    wb_cloudy,
    wb_incandescent,
    wb_iridescent,
    wb_sunny,
    wc,
    web,
    web_asset,
    weekend,
    whatshot,
    where_to_vote,
    widgets,
    wifi,
    wifi_lock,
    wifi_off,
    wifi_tethering,
    work,
    work_off,
    work_outline,
    wrap_text,
    youtube_searched_for,
    zoom_in,
    zoom_out,
    zoom_out_map
}

export const MATERIAL_OUTLINE_ICONS_VALUES: any[] = Object.keys(MaterialOutlineIcons)
.filter(key=> isNaN(Number(key)))
.map(key => key.startsWith('_') ? key.substring(1) : key);
