import { BaseModel } from 'app/main/shared/models/base.model';
import {Pessoa} from "../../core/models/pessoa.model";

export class PessoaJuridica extends BaseModel {
    constructor(
        public id?: number,
        public ativo?: boolean,
        public cnpj?: string,
        public nomeFantasia?: string,
        public razaoSocial?: string,
        public inscricaoMunicipal?: string,
        public inscricaoEstadual?: string,
        public naturezaJuridica?: string,
        public porteEmpresa?: string,
        public dataConstituicao?: Date,
        public dataCriacao?: Date,
        public pessoa?: Pessoa,
        public pessoalFisicaCpf?: string
    ) {
        super();
    }

    static fromJson(json: any): PessoaJuridica {
        if (json === undefined || json === null) {
            return null;
        }
        return new PessoaJuridica(
            json.id, 
            json.ativo,
            json.cnpj,
            json.nomeFantasia,
            json.razaoSocial,
            json.inscricaoMunicipal,
            json.inscricaoEstadual,
            json.naturezaJuridica,
            json.porteEmpresa,
            json.dataConstituicao,
            json.dataCriacao,
            Pessoa.fromJson(json.pessoa),
            json.pessoalFisicaCpf
        );
    }

    static fromJsons(jsonArray: any): PessoaJuridica[] {
        const resources = [];
        if (jsonArray === undefined || jsonArray === null) {
            return [];
        } else {
            jsonArray.forEach(resource => resources.push(PessoaJuridica.fromJson(resource)));
            return resources;
        }
    }
}
