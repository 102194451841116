import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CoreModule } from 'app/main/core/core.module';
import { SharedModule } from 'app/main/shared/shared.module';
import { SistemaDetailComponent } from './sistema-detail/sistema-detail.component';
import { SistemaListComponent } from './sistema-list/sistema-list.component';
import { SistemaListResolver, SistemaResolver } from './sistema.service';
import { PortalSecurityAuthGuardPerfilService } from '@sema-security-angular/portal-security';
import { AuthGuardService } from 'app/main/shared/services/auth-guard.service';

export const routes: Routes = [
    {
        path: 'new',
        component: SistemaDetailComponent
    },
    {
        path: 'sistemas',
        component: SistemaListComponent,
        resolve: {
            data: SistemaListResolver
        }
    },
    {
        path: ':id/edit',
        component: SistemaDetailComponent,
        resolve: {
            data: SistemaResolver
        },
        canActivate: [AuthGuardService,PortalSecurityAuthGuardPerfilService],
    },
    {
        path: ':id/detail',
        component: SistemaDetailComponent,
        resolve: {
            data: SistemaResolver
        }
    }
];

@NgModule({
    declarations: [SistemaListComponent, SistemaDetailComponent],
    imports: [CoreModule, SharedModule,RouterModule.forChild(routes), CommonModule, ],
    entryComponents: [SistemaDetailComponent, SistemaListComponent],
    providers: [SistemaResolver, SistemaListResolver]
})
export class SistemasModule {}
