import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CoreModule } from 'app/main/core/core.module';
import { SharedModule } from 'app/main/shared/shared.module';
import { DashboardComponent, EllipsisPipe } from './dashboard.component';
import { MatCardModule, MatGridListModule } from '@angular/material';
import {AuthGuardService} from '../../shared/services/auth-guard.service';
export const routes: Routes = [
    {
        path: '',
        component: DashboardComponent,
        canActivate: [AuthGuardService]
    }
];

@NgModule({
    declarations: [DashboardComponent, EllipsisPipe],
    imports: [
        CoreModule,
        SharedModule,
        RouterModule.forChild(routes),
        MatGridListModule,
        MatCardModule,
    ],
    entryComponents: [DashboardComponent]
})
export class DashboardModule {}
