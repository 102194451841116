import { BaseModel } from 'app/main/shared/models/base.model';
import * as moment from 'moment';

export const EnumSituacaoUsuarioApiKey = {
    ATIVADA: 'Ativada', 
    DESATIVADA: 'Desativada',

    [Symbol.iterator]: function* () {
        for (const key of Object.keys(this)) {
            yield { valor: key, descricao: this[key] };
        }
    }
}

export class UsuarioApiKey extends BaseModel {
    constructor(        
        public id?: number,
        public usuarioId?: number,
        public usuarioNome?: string,
        public dataInicio?: string,        
        public descricao?: string,
        public situacao?: string        
    ) {
        super();
    }

    static fromJson(json: any): UsuarioApiKey {
        json = json || {};        

        let dataInicio = json.dataInicio;

        if (moment.utc(dataInicio, moment.ISO_8601, true).isValid()) {
            dataInicio = moment.utc(dataInicio).local().format('DD/MM/YYYY HH:MM');
        }

        return new UsuarioApiKey(
            json.id,
            json.usuarioId,
            json.usuarioNome,
            dataInicio,            
            json.descricao,
            json.situacao
        );
    }

    static fromIterable(json: any): Array<UsuarioApiKey> {
        const collection = new Array<UsuarioApiKey>();

        if (json && typeof json[Symbol.iterator] === 'function') {
            for (const value of json) {
                if (value) {
                    collection.push(UsuarioApiKey.fromJson(value));
                }
            }
        }

        return collection;
    }
}