import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {fuseAnimations} from '@fuse/animations';
import {FuseConfigService} from '@fuse/services/config.service';
import {AccessService} from './../access.service';
import {SnackBarService} from '../../../shared/services/snack-bar/snack-bar.service';

@Component({
    selector: 'mail-confirm-finish',
    templateUrl: './mail-confirm-finish.component.html',
    styleUrls: ['./mail-confirm-finish.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class MailConfirmFinishComponent implements OnInit {

    public aguardando = true;
    public sucesso: boolean;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private activatedRoute: ActivatedRoute,
        private accessService: AccessService
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe(params => {
            const token = params['token'];
            this.accessService.ativar(token).subscribe(() => {
                this.sucesso = true;
                this.aguardando = false;
            }, e => {
                this.aguardando = false;
            });
        });
    }
}
