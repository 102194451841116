export class PessoaFisicaProfissaoDto {
    constructor(
        public pessoaFisicaId?: number,
        public profissaoId?: number,
    ) {
    }

    static fromJson(json: any): PessoaFisicaProfissaoDto {
        if (json === undefined || json === null) {
            return null;
        }
        return new PessoaFisicaProfissaoDto(
            json.pessoaFisicaId,
            json.profissaoId
        );
    }
}

