export class CurrentUserToken {
    constructor(
        public token?: string,
    ) {
    }

    static fromJson(json: any): CurrentUserToken {
        if (json === undefined || json === null) {
            return null;
        }
        let us: CurrentUserToken;
        us = Object.assign({}, json);
        return us;
    }
}
