import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatSelectModule} from '@angular/material/select';
import {RouterModule, Routes} from '@angular/router';
import {FuseSharedModule} from '@fuse/shared.module';
import {AuthGuardService} from '../shared/services/auth-guard.service';
import {SharedModule} from '../shared/shared.module';
import {ApiKeysModule} from './api-keys/api-keys.module';
import {AuthModule} from './auth/auth.module';
import {DashboardModule} from './dashboard/dashboard.module';
import {GruposModule} from './grupos/grupos.module';
import {IFrameComponent} from './iframe/iframe.component';
import {MenusModule} from './menus/menus.module';
import {ModulosModule} from './modulos/modulos.module';
import {PerfisModule} from './perfis/perfis.module';
import {SistemasModule} from './sistemas/sistemas.module';
import {UsuariosModule} from './usuarios/usuarios.module';
import {SobreComponent} from './sobre/sobre.component';
import {VerificarDocumentoModule} from "./verificar-documento/verificar-documento.module";
import { PortalSecurityAuthGuardPerfilService } from '@sema-security-angular/portal-security';

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: './auth/auth.module#AuthModule',
        canActivate: [AuthGuardService,PortalSecurityAuthGuardPerfilService]
    },
    {
        path: 'dashboard',
        loadChildren: './dashboard/dashboard.module#DashboardModule',
        canActivate: [AuthGuardService,PortalSecurityAuthGuardPerfilService]
    },
    {
        path: 'grupos',
        loadChildren: './grupos/grupos.module#GruposModule',
        canActivate: [AuthGuardService,PortalSecurityAuthGuardPerfilService]
    },
    {
        path: 'perfis',
        loadChildren: './perfis/perfis.module#PerfisModule',
        canActivate: [AuthGuardService,PortalSecurityAuthGuardPerfilService]
    },
    {
        path: 'menus',
        loadChildren: './menus/menus.module#MenusModule',
        canActivate: [AuthGuardService,PortalSecurityAuthGuardPerfilService]
    },
    {
        path: 'modulos',
        loadChildren: './modulos/modulos.module#ModulosModule',
        canActivate: [AuthGuardService,PortalSecurityAuthGuardPerfilService],
    },
    {
        path: 'sistemas',
        loadChildren: './sistemas/sistemas.module#SistemasModule',
        canActivate: [AuthGuardService,PortalSecurityAuthGuardPerfilService],
    },
    {
        path: 'usuarios',
        loadChildren: './usuarios/usuarios.module#UsuariosModule',
        canActivate: [AuthGuardService,PortalSecurityAuthGuardPerfilService]
    },
    {
        path: 'api-keys',
        loadChildren: './api-keys/api-keys.module#ApiKeysModule',
        canActivate: [AuthGuardService,PortalSecurityAuthGuardPerfilService]
    },
    {
        path: 'sobre',
        component: SobreComponent,
        canActivate: [AuthGuardService,PortalSecurityAuthGuardPerfilService]
    },
    {
        path: 'iframe',
        component: IFrameComponent,
        canActivate: [AuthGuardService,PortalSecurityAuthGuardPerfilService]
    },
    {
        path: 'pages',
        component: IFrameComponent
    },
    // otherwise redirect to dashboard
    { path: '**', redirectTo: 'dashboard' }
];

@NgModule({
    declarations: [SobreComponent],
    imports: [
        // Angular Core
        CommonModule,
        RouterModule.forChild(routes),
        MatSelectModule,

        // Fuse
        FuseSharedModule,

        DashboardModule,
        GruposModule,
        PerfisModule,
        SistemasModule,
        MenusModule,
        ModulosModule,
        UsuariosModule,
        ApiKeysModule,
        AuthModule,
        VerificarDocumentoModule,
        SharedModule, 
    ]
})
export class PagesModule {}
