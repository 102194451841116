import { ChangeDetectionStrategy, Component, Injector, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { ReplaySubject, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { BaseDetailComponent } from '../../../shared/components/base-detail/base-detail.component';
import { BaseEnum } from '../../../shared/models/base.enum ';
import { Usuario } from '../../usuarios/usuario.model';
import { UsuarioService } from '../../usuarios/usuario.service';
import { EnumSituacaoUsuarioApiKey, UsuarioApiKey } from '../api-key.model';
import { UsuarioApiKeyService } from '../api-key.service';
import * as moment from 'moment';

@Component({
    selector: 'app-api-key-detail',
    templateUrl: './api-key-detail.component.html',
    styleUrls: ['./api-key-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class UsuarioApiKeyDetailComponent extends BaseDetailComponent<UsuarioApiKey> implements OnDestroy {
    situacoes: Array<BaseEnum> = Array.from(EnumSituacaoUsuarioApiKey);

    usuariosFilterControl = new FormControl();    

    usuariosFiltered = new ReplaySubject<Array<Usuario>>(1);

    isSearching = false;

    private onDestroy = new Subject<void>();    

    private isSaving: boolean = false;

    constructor(
        protected injector: Injector,        
        private usuarioApiKeyService: UsuarioApiKeyService,
        private usuarioService: UsuarioService
    ) {
        super(injector, new UsuarioApiKey(), usuarioApiKeyService, UsuarioApiKey.fromJson);        
    }

    get isCancelarDisabled(): boolean {
        return this.isSaving;
    }

    get isProprietarioDisabled(): boolean {
        return this.isSomenteVisualizacao || this.currentAction !== 'new';
    }

    get isSalvarDisabled(): boolean {
        return this.form.invalid || this.form.pristine || this.isSomenteVisualizacao || this.isSaving;
    }

    private get usuarioId(): number {
        return this.form.get('usuario').value;
    }

    private get descricao(): string {
        const value = this.form.get('descricao').value;

        return value && value.length > 0 ? value.trim() : '';
    }

    private get hoje(): string {
        return moment().format('DD/MM/YYYY HH:MM');
    }    

    private get situacao(): string {
        const value = this.form.get('situacao').value;

        return value && value.length > 0 ? value : null;
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.listenUsuariosSearch();
    }

    ngOnDestroy(): void {
        this.onDestroy.next();

        this.onDestroy.complete();
    }

    buildResourceForm(): void {
        this.form = this.formBuilder.group({
            situacao: [null, [Validators.required]],
            usuario: [null, [Validators.required]],
            descricao: [null, [Validators.required, Validators.maxLength(255)]],
            dataInicio: [this.hoje]
        });
    }    

    salvar(): void {
        if (this.usuarioId
         && this.descricao
         && this.situacao
         && (this.currentAction === 'new' || this.currentAction === 'edit')) {
            const apiKey = this.currentAction === 'new'
                ? new UsuarioApiKey()
                : this.resource;

            const message = this.currentAction === 'new'
                ? 'Chave de API criada e enviada ao e-mail do usuário.'
                : 'Chave de API atualizada com sucesso.'

            const method = this.currentAction === 'new'
                ? 'createApiKey'
                : 'updateApiKey'

            apiKey.descricao = this.descricao;
            apiKey.situacao = this.situacao;
            apiKey.usuarioId = this.usuarioId;

            this.isSaving = true;

            this.usuarioApiKeyService
                [method](apiKey)
                .subscribe(
                    _ => {
                        this.isSaving = false;

                        this.snackBarService.showSuccess(message);

                        this.voltar();
                    },
                    error => {
                        this.isSaving = false;

                        this.onError(error);
                    }                    
                );
        }
    }

    protected loadResource(): void {
        if (this.currentAction !== 'new') {
            this.activatedRoute.params.subscribe(p => {
                this.activatedRoute.queryParams.subscribe(q => {
                    this.usuarioService
                        .getById(q["usuarioId"])
                        .subscribe(u => {
                            const usuario = Usuario.fromJson(u);

                            this.resource = UsuarioApiKey.fromJson(q);

                            this.usuariosFiltered.next([usuario]);

                            this.form.patchValue({
                                descricao: q.descricao,
                                situacao: q.situacao,
                                dataInicio: q.dataInicio,
                                usuario: usuario.id
                            });
                        }, this.onError.bind(this));
                });
            });
        }
    }

    protected getBaseComponetPath(): string {
        return this.activatedRoute.snapshot.url[0].path;
    }

    protected setCurrentAction(): void {
        if (this.activatedRoute.snapshot.url[1] && this.activatedRoute.snapshot.url[1].path === 'new') {
            this.currentAction = 'new';
        } else if (this.activatedRoute.snapshot.url[2] && this.activatedRoute.snapshot.url[2].path === 'edit') {
            this.currentAction = 'edit';
        } else {
            this.currentAction = 'detail';
        }
    }

    protected creationPageTitle(): string {
        return 'Nova chave de API';
    }

    protected editionPageTitle(): string {
        return 'Editando a chave de API';
    }

    protected visualizationPageTitle(): string {
        return 'Visualizando a chave de API';
    }

    private listenUsuariosSearch(): void {
        const failed = error => {
            this.isSearching = false;

            this.onError(error);
        };

        const succeeded = usuarios => {
            this.isSearching = false;

            this.usuariosFiltered.next(usuarios);
        };

        this.usuariosFilterControl.valueChanges
            .pipe(
                filter(search => search && search.length > 2),
                tap(_ => this.isSearching = true),
                takeUntil(this.onDestroy),
                map(search => this.usuarioService.search(search)),
                takeUntil(this.onDestroy)
            ).subscribe(
                o => o.subscribe(succeeded, failed),
                failed
            );            
    } 

    private onError(error): void {
        console.log(error);

        this.snackBarService.showError('Oops! ocorreu um problema inesperado.');
    }

    
}