import {Component, Injector, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Grupo} from '../../grupo.model';
import {BaseListComponent} from '../../../../shared/components/base-list/base-list.component';
import {HttpParams} from '@angular/common/http';
import {GrupoUsuariosListService} from '../../grupo-usuarios-list.service';
import {UsuarioGrupo} from './usuario-grupo.model';
import {fuseAnimations} from '../../../../../../@fuse/animations';
import {DialogRemoverPermissaoUsuarioComponent} from '../../dialog-remover-permissao-usuario/dialog-remover-permissao-usuario.component';
import { DialogAdicionarGrupoUsuarioComponent } from '../../dialog-adicionar-grupo-usuario/dialog-adicionar-grupo-usuario.component';

@Component({
  selector: 'grupo-dados-usuarios-list',
  templateUrl: './grupo-dados-usuarios-list.component.html',
  styleUrls: ['./grupo-dados-usuarios-list.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class GrupoDadosUsuariosListComponent extends BaseListComponent<UsuarioGrupo> implements OnInit {

    @Input() grupo: Grupo;

    pesquisa: string;

    constructor(injector: Injector, public grupoUsuariosService: GrupoUsuariosListService) {
        super(injector, ['cpf', 'nome', 'servidor', 'matricula', 'acao'], grupoUsuariosService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.paginator.pageSize = 20;
        this.list(0);
    }

    fillFilters(httpParams: HttpParams): HttpParams {
        if (typeof this.grupo.descricao !== 'undefined' && this.grupo.descricao.length > 0){
            httpParams = httpParams.set('idGrupo', '' + this.grupo.id);
            httpParams = httpParams.set('descricao', this.pesquisa ? this.pesquisa : '');
            httpParams = httpParams.set('isPesquisaParaInsercao', '' + false);
        }
        return httpParams;
    }

    excluirRegistro(resource: UsuarioGrupo): void {
        const dialogRef = this.dialog.open(DialogRemoverPermissaoUsuarioComponent, {
            width: '360px',
            data: { nomeUsuario: resource.nome, nomeGrupo: this.grupo.descricao}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.grupoUsuariosService.excluir(this.grupo.id, resource.usuarioId).subscribe(
                    (response: any) => {
                        this.snackBarService.showSuccess('Usuário removido do grupo.');
                        this.list(0);
                    },
                    e => {
                        this.snackBarService.showError('Não foi possível excluir o usuário do grupo.', e);
                    }
                );
            }
        });
    }

    getCpfCnpjMask(cpf_cnpj: string): string {
        return this.isCpf(cpf_cnpj) ? '000.000.000-009' : '00.000.000/0000-00';
    }

    isCpf(cpf_cnpj: string): boolean {
        return cpf_cnpj == null ? true : cpf_cnpj.length < 12 ? true : false;
    }

    search() {
        this.searchOnGrupo(0);
    }

    adicionarGrupoUsuario(): void {
        const dialogRef = this.dialog.open(DialogAdicionarGrupoUsuarioComponent, {
            width: '700px',
            data: {grupo: this.grupo}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result){
                this.list(0);
            }
        });
    }
}
