import {BaseModel} from 'app/main/shared/models/base.model';
import { Contato } from './contatos.model';
import { BaseEnum } from 'app/main/shared/models/base.enum ';

export class Telefone extends BaseModel {
    constructor(
        public id?: number,
        public ddd?: string,
        public ddi?: string,
        public tipo?: string,
        public numero?: string,
        public contato?: Contato
    ) {
        super();
    }

    static tiposTelefones: BaseEnum[] = [
        { valor: 'CELULAR', descricao: 'Celular' },
        { valor: 'TRABALHO', descricao: 'Trabalho' },
        { valor: 'CASA', descricao: 'Casa' }
    ];
    
    static fromJson(json: any): Telefone {
        if (json === undefined || json === null) {
            return null;
        }
        return new Telefone(
            json.id,
            json.ddd ? json.ddd.match(/\d+/g).join('') : null,
            json.ddi ? json.ddi.match(/\d+/g).join('') : null,
            json.tipo,
            json.numero ? json.numero.match(/\d+/g).join('') : null,
            Contato.fromJson(json.contato)
        );
    }
}
