import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SharedModule} from 'app/main/shared/shared.module';
import {CoreModule} from 'app/main/core/core.module';
import {ModuloDetailComponent} from './modulo-detail/modulo-detail.component';
import {ModuloListComponent} from './modulo-list/modulo-list.component';
import {ModuloListResolver, ModuloResolver} from './modulo.service';
import {AuthGuardChildService} from '../../shared/services/auth-guard-child.service';
import {AuthGuardService} from '../../shared/services/auth-guard.service';
import { PortalSecurityAuthGuardPerfilService } from '@sema-security-angular/portal-security';

export const routes: Routes = [
    {
        path: 'new',
        component: ModuloDetailComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'modulos',
        component: ModuloListComponent,
        canActivate: [AuthGuardService, PortalSecurityAuthGuardPerfilService],
        resolve: {
            data: ModuloListResolver
        },
        data: {
            securityId: ['ff387543-e08d-4408-afb5-759efaab3ba4'],
            securityMessage: 'Acesso negado ao Menu Módulos'
        }
    },
    {
        path: ':id/edit',
        canActivateChild: [AuthGuardChildService],
        component: ModuloDetailComponent,
        resolve: {
            data: ModuloResolver
        },
        data: {
            securityId: 'ff387543-e08d-4408-afb5-759efaab3ba4'
        }
    },
    {
        path: ':id/detail',
        canActivateChild: [AuthGuardChildService],
        component: ModuloDetailComponent,
        resolve: {
            data: ModuloResolver
        }
    }
];

@NgModule({
    declarations: [ModuloListComponent, ModuloDetailComponent],
    imports: [CoreModule, SharedModule, RouterModule.forChild(routes)],
    entryComponents: [ModuloDetailComponent, ModuloListComponent],
    providers: [ModuloResolver, ModuloListResolver]
})
export class ModulosModule {}
