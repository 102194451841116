import {AuthGuardWizardService} from './main/shared/services/auth-guard-wizard.service';
import {CommonModule, DatePipe} from '@angular/common';
import {HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatPaginatorIntl} from '@angular/material';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule, Routes} from '@angular/router';
import {FuseProgressBarModule, FuseSidebarModule} from '@fuse/components';
import {FuseModule} from '@fuse/fuse.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {AppComponent} from 'app/app.component';
import {fuseConfig} from 'app/fuse-config';
import {LayoutModule} from 'app/layout/layout.module';
import {PageErrorsModule} from 'app/main/errors/page-errors.module';
import 'hammerjs';
import {environment} from '../environments/environment';
import {CoreModule} from './main/core/core.module';
import {AccountService} from './main/core/services/account.service';
import {Principal} from './main/core/services/principal.service';
import {RoutingStateService} from './main/core/services/routing-state.service';
import {IFrameModule} from './main/pages/iframe/iframe.module';
import {PagesModule} from './main/pages/pages.module';
import {ErrorInterceptor} from './main/shared/interceptors/error.interceptor';
import {AuthGuardService} from './main/shared/services/auth-guard.service';
import {getPtBrPaginatorIntl} from './main/shared/utils/ptBr-paginator-intl';
import {faCheckSquare, faMinus, faPlus, faSquare} from '@fortawesome/free-solid-svg-icons';
import {InjectionToken, NgModule} from '@angular/core';
import {MOMENT_DATE_FORMATS, MomentDateAdapter} from './moment-date-adapter';
import {CookieService} from 'ngx-cookie-service';
import {AuthenticationInterceptor} from './interceptors/authentication.interceptor';
import {LoadingModule} from './main/shared/components/loading/loading.module';
import {LoadingInterceptor} from './main/shared/components/loading/loading.interceptor';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {MaskPipe} from 'ngx-mask';
import {PortalSecurityModule }  from '@sema-security-angular/portal-security';
import { UtilService } from './main/core/services/util.service';
import { ISEVERITY } from './main/core/services/core-message.service';  
export const envSecurity = new InjectionToken<any>("ENV_SECURITY");
export const envSecurityMessageService = new InjectionToken<any>("ENV_SECURITY_MESSAGE_SERVICE");

import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function createTranslateLoader(http?: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export  function portalSecurityMessageService(msg, opt)  {
    console.error('PortalWeb.PortalSecurity.message',msg,opt);
    UtilService.showMessage(msg,{ ...opt, severity: ISEVERITY.ERROR});
};

const appRoutes: Routes = [
    {
        path: '',
        loadChildren: './main/pages/pages.module#PagesModule'
    }
];

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        RouterModule.forRoot(appRoutes, {
            useHash: true,
            enableTracing: false
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory:  (createTranslateLoader),
                deps: [HttpClient]
            },
            defaultLanguage: 'pt'
        }),

        FontAwesomeModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        LoadingModule,

        // App modules
        IFrameModule,
        LayoutModule,
        PageErrorsModule,
        CoreModule.forRoot(),
        PortalSecurityModule.forRoot(environment, {
            URL_SECURITY_PORTAL_API: environment.URL_GATEWAY,
            URL_SECURITY_ENDPOINT_USER_INFO: '/api/auth/me',
            URL_SECURITY_ENDPOINT_PERFIS:'/api/perfis-usuario/me',
            URL_SECURITY_ENDPOINT_GRUPOS: '/api/grupos-usuario/me',
            URL_SECURITY_ENDPOINT_MENUS: '/api/menus/me',
            URL_SECURITY_ENDPOINT_OPERACOES: '/api/menus/me/operacoes/chaves',
            URL_SECURITY_ENDPOINT_USER_RESOURCES: '/api/perfis-usuario/me/uuids',
            URL_SECURITY_ENDPOINT_USER_RESOURCES_VALIDATE: '/api/perfis-usuario/me/uuids/validate',
            MESSAGE_FUNCTION: portalSecurityMessageService
        }),
        PagesModule
    ],
    exports: [PagesModule],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true},
        {provide: MatPaginatorIntl, useValue: getPtBrPaginatorIntl()},
        {provide: MAT_DATE_LOCALE, useValue: 'pt-BR'},
        {provide: MAT_DATE_FORMATS, useValue: MOMENT_DATE_FORMATS},
        {provide: DateAdapter, useClass: MomentDateAdapter},
        {provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true},
        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        AuthGuardService,
        AuthGuardWizardService,
        RoutingStateService,
        AccountService,
        Principal,
        CookieService,
        DatePipe,
        MaskPipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(library: FaIconLibrary ) {
        console.log('[portal] ambiente de ' + environment.name);
        // fontawesome
        library.addIcons(faSquare, faCheckSquare, faPlus, faMinus);
    }
}
