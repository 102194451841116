import { Injectable } from '@angular/core';
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {DefaultGuardService} from './default-guardservice';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardChildService extends DefaultGuardService implements CanActivateChild {

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | boolean {
        return this.isAuthenticated(route,state);
    }
}