import {Injectable, Injector} from '@angular/core';

import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Usuario} from './usuario.model';
import {ListResolver} from '../../shared/resolvers/list-resolver';
import {DetailResolver} from '../../shared/resolvers/detail-resolver';
import {RoutingStateService} from '../../core/services/routing-state.service';
import {BaseService} from '../../shared/services/base.service';
import {HttpParams} from '@angular/common/http';

@Injectable()
export class UsuarioListResolver extends ListResolver<Usuario> {
    constructor(service: UsuarioService) {
        super(service);
    }
}

@Injectable()
export class UsuarioResolver extends DetailResolver<Usuario> {
    constructor(
        service: UsuarioService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: 'root'
})
export class UsuarioService extends BaseService<Usuario> {

    constructor(protected injector: Injector) {
        super(
            '/api/usuarios',
            injector,
            Usuario.prototype,
            Usuario.fromJson
        );
    }

    getByLogin(login: string): Observable<Usuario> {
        const url = `${this.urlResource}/login/${login}`;
        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    search(query: string, size: number = 10): Observable<Array<Usuario>> {
        const httpParams = new HttpParams({
            fromObject: {
                descricao: query,
                size: String(size)
            }
        });

        return this.http.get(`${this.urlResource}/search`, {params: httpParams})
            .pipe(
                map(this.jsonToResources.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    updateUsuarioSimplificado(login: string): Observable<void> {
        const url = `${this.urlResource}/alterar-usuario-simplificado/${login}`;
        return this.http
            .put(url, login)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }
}
