import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CoreModule } from 'app/main/core/core.module';
import { AuthGuardWizardService } from 'app/main/shared/services/auth-guard-wizard.service';
import { SharedModule } from 'app/main/shared/shared.module';
import { PessoaFisicaProfissaoComponent } from '../../shared/components/pessoas-fisica-profissoes/pessoas-fisica-profissoes.component';
import { AuthGuardChildService } from '../../shared/services/auth-guard-child.service';
import { AuthGuardService } from '../../shared/services/auth-guard.service';
import { UsuarioDetailComponent } from './usuario-detail/usuario-detail.component';
import { UsuarioListComponent } from './usuario-list/usuario-list.component';
import { UsuarioProfileComponent } from './usuario-profile/usuario-profile.component';
import { UsuarioWizardComponent } from './usuario-wizard/usuario-wizard.component';
import { UsuarioListResolver, UsuarioResolver } from './usuario.service';
import { EmpresasComponent } from './usuario-profile/empresas/empresas.component';
import { DialogEditarVinculoPfPjComponent } from './usuario-profile/empresas/dialog-editar-vinculo-pf-pj/dialog-editar-vinculo-pf-pj.component';

export const routes: Routes = [
    {
        path: 'new',
        canActivate: [AuthGuardService],
        component: UsuarioDetailComponent
    },
    {
        path: 'usuarios',
        canActivate: [AuthGuardService],
        component: UsuarioListComponent,
        resolve: {
            data: UsuarioListResolver
        }
    },
    {
        path: 'wizard',
        canActivate: [AuthGuardWizardService],
        component: UsuarioWizardComponent,
        resolve: {
            data: UsuarioResolver
        }
    },
    {
        path: 'profile',
        canActivate: [AuthGuardService],
        component: UsuarioProfileComponent,
        resolve: {
            data: UsuarioResolver
        }
    },
    {
        path: ':id/edit',
        canActivateChild: [AuthGuardChildService],
        component: UsuarioDetailComponent,
        resolve: {
            data: UsuarioResolver
        }
    },
    {
        path: ':id/detail',
        canActivateChild: [AuthGuardChildService],
        component: UsuarioDetailComponent,
        resolve: {
            data: UsuarioResolver
        }
    }
];

@NgModule({
    declarations: [
        UsuarioListComponent,
        UsuarioDetailComponent,
        UsuarioProfileComponent,
        UsuarioWizardComponent,        
        PessoaFisicaProfissaoComponent,
        EmpresasComponent,
        DialogEditarVinculoPfPjComponent
    ],
    imports: [
        CoreModule,
        SharedModule,
        RouterModule.forChild(routes)
    ],
    entryComponents: [
        UsuarioDetailComponent,
        UsuarioListComponent,
        UsuarioProfileComponent,
        UsuarioWizardComponent,        
        PessoaFisicaProfissaoComponent,
        EmpresasComponent,
        DialogEditarVinculoPfPjComponent
    ],
    providers: [
        UsuarioResolver,
        UsuarioListResolver
    ]
})
export class UsuariosModule {}
