import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CoreModule } from '../../core/core.module';
import { SharedModule } from '../../shared/shared.module';
import { UsuarioApiKeyDetailComponent } from './api-key-detail/api-key-detail.component';
import { UsuarioApiKeyListComponent } from './api-key-list/api-key-list.component';
import { UsuarioApiKeyListResolver, UsuarioApiKeyResolver } from './api-key.service';

export const routes: Routes = [
    {
        path: 'api-keys',
        component: UsuarioApiKeyListComponent
    },
    {
        path: 'api-keys/new',        
        component: UsuarioApiKeyDetailComponent
    },
    {
        path: 'api-keys/:id/edit',        
        component: UsuarioApiKeyDetailComponent
    },
    {
        path: 'api-keys/:id/detail',        
        component: UsuarioApiKeyDetailComponent
    }
];

@NgModule({
    declarations: [        
        UsuarioApiKeyListComponent,
        UsuarioApiKeyDetailComponent        
    ],
    imports: [
        CoreModule,
        SharedModule,
        RouterModule.forChild(routes)
    ],
    entryComponents: [        
        UsuarioApiKeyListComponent,
        UsuarioApiKeyDetailComponent
    ],
    providers: [        
        UsuarioApiKeyResolver,
        UsuarioApiKeyListResolver
    ]
})
export class ApiKeysModule { }