import {BaseModel} from 'app/main/shared/models/base.model';
import {EnumUf} from 'app/main/shared/models/uf.enum';
import {SearchableModel} from '../../shared/components/select-search/searchable-model';

export class Municipio extends BaseModel implements SearchableModel {

    constructor(
        public nome?: string,
        public uf?: EnumUf,
        public codigoIbge?: number
    ) {
        super();
    }

    static fromJson(json: any): Municipio {
        if (json === undefined || json === null) {
            return null;
        }
        return new Municipio(
            json.nome,
            json.uf,
            json.codigoIbge
        );
    }

    static compareWith = (a: Municipio, b: Municipio): boolean => a && b ? a.codigoIbge === b.codigoIbge : a === b;

    searchValue(): string {
        return `${this.nome}`;
    }

    itemLabel(): string {
        return this.nome;
    }
}
