import { BaseModel } from 'app/main/shared/models/base.model';
import { Role } from './role';
import { Pais } from 'app/main/core/models/pais.model';
import { Municipio } from 'app/main/core/models/municipio.model';
import { TipoLogradouro } from 'app/main/core/models/tipo-logradouro.model';

export class Usuario extends BaseModel {
    constructor(
        public id?: number,
        public login?: string,
        public nome?: string,
        public email?: string,
        public role?: Role,                
        public cadSimplificado?: boolean, 
        public pais?: Pais,
        public passaporte?: string ,
        public rg?: string ,
        public dataNasciment?: string,
        public estadoCivil?: string ,
        public sexo?: string ,
        public mae?: string ,
        public numTelefoneContato?: string ,
        public cep?: string ,
        public uf?: string ,
        public municipioDTO?: Municipio,
        public bairro?: string ,
        public logradouro?: string,
        public tipoLogradouro?: TipoLogradouro,
        public numero?: string ,
        public complemento?: string ,
        public ddd?: string,
        public ddi?: string
    ) {
        super();
    }

    static fromJson(json: any): Usuario {
        if (json === undefined || json === null) { return null; }
        let us: Usuario = new Usuario();
        us = Object.assign({}, json);
        us.login = us.login ? us.login.match(/\d+/g).join('') : null;
        return us;
    }
}
