import { SistemaModuleInfoUtil } from "../services/sistema-modulo-identifier.util";

export class ArtefatoModel {
    constructor(
        public fullName: string,
        public name?: string,
        public isApi?: boolean,
        public serverOrWeb?: string,
        public env?:string,
    ) {} 
     
    static fromKeyValue(groups: Array<string> | {[key: string]: string}):ArtefatoModel {
        const keys:string[] = (groups instanceof Array) ? groups : Object.keys(groups);
        if (keys.length > 0) {
            const fullName: string =  groups[0];
            const sistema:string =  keys.length >= 9 ?  groups[9] != null ? groups[9] : groups[3] : groups[3];
            const isApi:boolean = keys.length >= 6 ? groups[6] ? groups[6].toUpperCase() == 'API' || groups[6].toUpperCase() == 'SERVER' : false : false;
            const serverOrWeb:string = keys.length >= 6 ? groups[6] != null ? groups[6] : 'web' : 'server';
            const env:string = keys.length >= 8 ? groups[8] != null ? groups[8] : 'prod' : 'prod';
            return new ArtefatoModel(fullName,sistema,isApi,serverOrWeb,env);
        }
        return null; 
     }
}

export class SistemaModuleInfoModel {
 
    constructor(
        public sistema?: string,
        public porta?: string,
        public hash?: string,
        public rota?: string,
        public urlCompleta?: string,
        public isApi?: boolean,
        public env?: string,
        public domainPattern?: string,
        public artefatoInfo?: ArtefatoModel,
    ) {}
 
    static fromKeyValue(groups: Array<string> | {[key: string]: string}):SistemaModuleInfoModel {
        const keys:string[] = (groups instanceof Array) ? groups : Object.keys(groups);
        if (keys.length > 3) {
            const sistema:string = groups[3];
            const porta:string = groups[5];
            const hash:string = keys.find((k)=>k=='/#/');
            const rota:string = keys.length >= 6 ? (`/${(groups[6] == '/#/' ? ( keys.length >= 7 ? groups[7] : null ) : groups[7])}`) : null;
            const isApi:boolean = sistema != null ? sistema.toUpperCase().indexOf("API") != -1 || sistema.toUpperCase().indexOf("SERVER") != -1 : false;
            const env:string = keys.length >= 9 ? groups[9] : null;
            const domain:string = keys.length >= 4 ? groups[4] : null;
            return new SistemaModuleInfoModel(sistema,porta,hash,rota ? rota.replace('//','/') : null,groups[0],isApi, env, domain, SistemaModuleInfoUtil.parseArtefato(sistema));
        }
        return null; 
    }

    static routeFromKeyValue(groups: Array<string> | {[key: string]: string}):SistemaModuleInfoModel {
        const keys:string[] = (groups instanceof Array) ? groups : Object.keys(groups);
        if (keys.length > 3) {
            const sistema:string = groups[1];
            const rota:string = groups[3];
            return new SistemaModuleInfoModel(sistema,null,null,rota ? rota.replace('//','/') : null,groups[0],null, null, null, SistemaModuleInfoUtil.parseArtefato(sistema));
        }
        return null; 
     }
}
