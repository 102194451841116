import {BaseModel} from "../../../../shared/models/base.model";

export class UsuarioGrupo extends BaseModel{
    constructor(
        public pessoaFisicaId?: number,
        public cpf?: string,
        public nome?: string,
        public servidor?: boolean,
        public matricula?: string,
        public usuarioId?: number
    ) {
        super();
    }

    static fromJson(json: any): UsuarioGrupo {
        if (json === undefined || json === null){
            return null;
        }

        return new UsuarioGrupo(
            json.pessoaFisicaId,
            json.cpf,
            json.nome,
            json.servidor,
            json.matricula,
            json.usuarioId
        );
    }
}