import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { TermoComponent } from './termo.component';
import { Location } from '@angular/common';
import {SharedModule} from "../../../shared/shared.module";

export const routes: Routes = [
    {
        path: 'auth/termo',
        component: TermoComponent
    }
];

@NgModule({
    declarations: [
        TermoComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        SharedModule
    ]
})
export class TermoModule {
    location: Location;

    constructor(location: Location) {
        this.location = location;
    }

    voltar(): any {
        this.location.back();
        console.log( 'goBack()...' );
    }
}
