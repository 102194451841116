import {AfterViewInit, ChangeDetectorRef, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {PerfilService} from '../perfil.service';
import {PerfilMenuDTO, PerfilOperacaoMenuDTO} from '../perfil-menu-dto.model';
import {BehaviorSubject} from 'rxjs';
import {MenuService} from '../../menus/menu.service';
import {fuseAnimations} from '../../../../../@fuse/animations';
import { SnackBarService } from 'app/main/shared/services/snack-bar/snack-bar.service';

@Component({
    selector: 'app-dialog-clonar-perfil',
    templateUrl: './dialog-selecionar-operacoes-perfil.component.html',
    styleUrls: ['./dialog-selecionar-operacoes-perfil.component.css'],
    animations: fuseAnimations
})
export class DialogSelecionarOperacoesPerfilComponent implements AfterViewInit {

    loading = new BehaviorSubject(false);

    perfisOperacoesMenus: PerfilOperacaoMenuDTO[] = [];
    perfilId: any;
    sistemaId: any;
    moduloId: any;
    menuId: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public perfilMenuDTO: PerfilMenuDTO,
        private dialogRef: MatDialogRef<DialogSelecionarOperacoesPerfilComponent>,
        private perfilService: PerfilService,
        private menuService: MenuService,
        private snackBarService: SnackBarService,
        private cdr: ChangeDetectorRef
    ) {
    }

    ngAfterViewInit(): void {
        this.loading.next(true);
        this.menuService.getOperacoes(this.perfilMenuDTO.idMenu).subscribe(operacoes => {
            if (operacoes && operacoes.length > 0) {
                this.perfisOperacoesMenus = [...this.perfilMenuDTO.operacoesPerfil];
    
                // console.log('perfisOperacoesMenu ', this.perfisOperacoesMenus);
                // console.log('perfisOperacoesMenu Perfil', this.perfilMenuDTO);
                // console.log('perfisOperacoesMenu MenuOperacoes ', operacoes);
                
                this.sistemaId = operacoes.find((o)=>true).menu.modulo.sistema.id;
                this.moduloId = operacoes.find((o)=>true).menu.modulo.id;
                this.menuId = operacoes.find((o)=>true).menu.id;
                

                operacoes.forEach(operacao => {
                    let perfilOperacaoMenuDTO = this.perfisOperacoesMenus.find(pom => pom.id === operacao.id);
                    if (perfilOperacaoMenuDTO) {
                        perfilOperacaoMenuDTO['selecionado'] = true;
                    } else {
                        perfilOperacaoMenuDTO = new PerfilOperacaoMenuDTO(operacao.id, operacao.id, operacao);
                        perfilOperacaoMenuDTO['selecionado'] = false;
                        this.perfisOperacoesMenus.push(perfilOperacaoMenuDTO);
                    }
                });
                this.perfisOperacoesMenus.sort((a,b) => a.operacaoMenu && b.operacaoMenu ? a.operacaoMenu.operacao.localeCompare(b.operacaoMenu.operacao) : 0);
            } else {
                this.snackBarService.showAlert('Menu não possui operações cadastradas');
            }
                
                this.loading.next(false);
                this.cdr.detectChanges();
        }, (e) => {
            this.snackBarService.showError('Erro ao carregar operações disponíveis para o menu',e);
            this.loading.next(false);
        });
    }

    selecionarOperacao(perfilOperacaoMenuDTO: PerfilOperacaoMenuDTO): void {
        perfilOperacaoMenuDTO['selecionado'] = !perfilOperacaoMenuDTO['selecionado'];
    }

    selecionarTodasOperacoes(): void {
        const valor = !this.todasOperacoesSelecionadas;
        this.perfisOperacoesMenus.forEach(pom => pom['selecionado'] = valor);
    }

    get todasOperacoesSelecionadas(): boolean {
        if (this.perfisOperacoesMenus.length === 0) return false;
        return this.perfisOperacoesMenus.filter(pom => pom['selecionado']).length === this.perfisOperacoesMenus.length;
    }

    get operacoesSelecionadasIndeterminadas(): boolean {
        const length = this.perfisOperacoesMenus.filter(pom => pom['selecionado']).length;
        return length > 0 && length < this.perfisOperacoesMenus.length;
    }
    onNoClick(): void {
        this.dialogRef.close();
    }

    confirm(): void {
        this.loading.next(true);
        
        this.perfilMenuDTO.operacoes = PerfilOperacaoMenuDTO.fromJsons(this.perfisOperacoesMenus.filter(pom => pom['selecionado']));
        
        this.perfilService.salvarPerfilMenuDTO(this.perfilMenuDTO).subscribe(
            () => {
                this.loading.next(false);
                this.snackBarService.showSuccess('Operações do menu salvas com sucesso!');
                this.dialogRef.close({ sistemaId: this.sistemaId, moduloId: this.moduloId, menuId: this.menuId, perfilId: this.perfilMenuDTO.idPerfil});
            },
            (e) => {
                this.loading.next(false);
                this.snackBarService.showError('Erro ao processar sua solicitação',e);
                this.dialogRef.close(false);
            }
        );
    }


}
