import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';

import {FuseSharedModule} from '@fuse/shared.module';
import {RegisterComponent} from './register.component';
import {RegisterService} from './register.service';
import {MailConfirmModule} from '../mail-confirm/mail-confirm.module';
import { NgxMaskModule } from 'ngx-mask';
import { MatDatepickerModule, MatOptionModule, MatSelectModule } from '@angular/material';
import { SharedModule } from 'app/main/shared/shared.module';
import { RegisterInfoDialog } from './register-info-dialog/register-info-dialog.component';

export const routes: Routes = [
    {
        path: 'register',
        component: RegisterComponent,
        resolve: {}
    }
];

@NgModule({
    declarations: [
        RegisterComponent,
        RegisterInfoDialog
    ],
    imports     : [
        RouterModule.forChild(routes),
        NgxMaskModule.forRoot({
            validation: true
        }),
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        FuseSharedModule,
        MailConfirmModule,
        MatSelectModule,
        MatOptionModule,
        MatDatepickerModule,
        SharedModule
    ],
    exports:[
        RegisterInfoDialog
    ],
    entryComponents: [
        RegisterInfoDialog
    ],
    providers :[RegisterService]
})
export class RegisterModule
{
}
