import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Injectable } from '@angular/core';
import {
    MatTreeFlatDataSource,
    MatTreeFlattener
} from '@angular/material/tree';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { BehaviorSubject } from 'rxjs';
import { Menu } from '../../menus/menu.model';
import { Modulo } from 'app/main/pages/modulos/modulo.model';
import { MenuService } from 'app/main/pages/menus/menu.service';

export class MenuNode {
    children: MenuNode[];
    hasChilds: boolean;
    label: string;
    itemValue: any;
    tipo: string; // sistema,modulo,menu,menuitem
}

export class MenuFlatNode {
    label: string;
    level: number;
    itemValue: any;
    hasChilds: boolean;
    expandable: boolean;
}

@Injectable()
export class MenuDatabase {
    sistemas: any;

    dataValue: MenuNode[];
    dataChange = new BehaviorSubject<MenuNode[]>([]);

    get data(): MenuNode[] {
        return this.dataValue; 
    }

    set data(d: MenuNode[]) {
        this.dataValue = d;
    }
    
    constructor(private menuService: MenuService) { }

    public initialize(jsonData: any) {
        let treeData = Object.assign([], jsonData);
        this.sistemas = this.buildSistemasTree(treeData, null, null, null);
        let data: MenuNode[] = [];

        if (this.sistemas != null) {
            data = this.buildNodeTree(this.sistemas, 0);
        }
 
        this.dataChange.next(data);
    }

    reload(data) {
        if (data)
            return this.dataChange.next(data);
        this.menuService.getAllInclusiveExcluidos().subscribe((menus)=> {
            this.initialize(menus);
        });
    }

    buildNodeTree(obj: { [key: string]: any }, level: number): MenuNode[] {
        return Object.keys(obj).reduce<MenuNode[]>((accumulator, key) => {
            const value = obj[key];
            const node = new MenuNode();
            node.label = value.nome || value.descricao;
            node.itemValue = value; 
            node.tipo = value.tipo; 
             
            if (value != null) {
                if ( typeof value === 'object' && (value.modulos || value._menuTree)) {
                    node.hasChilds = (value.modulos && Object.keys(value.modulos).length > 0) || (value._menuTree && Object.keys(value._menuTree).length > 0);
                    node.children = this.buildNodeTree( value.modulos || value._menuTree, level + 1 );
                } else {
                    node.label = value.descricao;
                }
            } 
            return accumulator.concat(node);
        }, []);
    }

    buildSistemasTree(
        menuItens: Array<Menu>,
        sistemas: { [key: string]: any },
        modulos: { [key: string]: any },
        itensMenu: { [key: string]: any }
    ): { [key: string]: any } {
        if (sistemas == null) sistemas = {};
        if (modulos == null) modulos = {};
        if (itensMenu == null) itensMenu = {};

        let nodes: MenuNode[] = [];
 
        if (menuItens) {
           
            // cria arvore 
            Object.keys(menuItens).forEach( k  => {
                let item = menuItens[k];
                
                if  (item.posicao == 0 && item.descricao == '___root') {
                    item.tipo='___root';
                }
                let parentMenu = item.parent != null
                ? {
                      tipo: item.tipo || 'menu',
                      id: item.parent.id,
                      modulo: item.modulo,
                      nome: item.parent.nome
                          ? item.parent.nome.toUpperCase()
                          : null,
                      descricao: item.parent.descricao
                          ? item.parent.descricao.toUpperCase()
                          : '---',
                      url: item.url
                  }
                : null;
                
                let itemMenu = {
                    tipo: item.tipo || (parentMenu != null ? 'subMenu' : 'menu'),
                    id: item.id,
                    nome: item.descricao ? item.descricao.toUpperCase() : '---',
                    descricao: item.descricao  ? item.descricao.toUpperCase() : '---',
                    posicao: item.posicao,
                    modulo: item.modulo,
                    parentId: parentMenu != null ? parentMenu.id : null,
                    url: item.url,
                    _menuTree: {}
                };

                let sistema = {
                    tipo:'sistema',
                    id: null,
                    nome: null,
                    descricao: null,
                    url: item.url,
                    modulos: {}
                };
                let modulo = { tipo:'modulo', id: null, sistema: null, nome: null, descricao: null, url: null };

                // modulos e sistemas
                if (item.modulo) {
                    modulo = {
                        tipo:'modulo', 
                        id: item.modulo.id,
                        sistema: { id: item.modulo.sistema.id, nome: item.modulo.sistema.nome },
                        nome: item.modulo.nome
                            ? item.modulo.nome.toUpperCase()
                            : null,
                        descricao: item.modulo.descricao
                            ? item.modulo.nome.toUpperCase()
                            : '---',
                         url: item.url
                         
                    };

                    if (modulos[modulo.id] == null) modulos[modulo.id] = modulo;
                    if (modulos[modulo.id]._menuTree == null)
                        modulos[modulo.id]._menuTree = {};
 

                    if (item.modulo && item.modulo.sistema) {
                        sistema = {
                            tipo:'sistema',
                            id: item.modulo.sistema.id,
                            nome: item.modulo.sistema.nome
                                ? item.modulo.sistema.nome.toUpperCase()
                                : null,
                            descricao: item.modulo.sistema.descricao
                                ? item.modulo.sistema.descricao.toUpperCase()
                                : '---',
                            url: item.url,
                            modulos: {}
                        };

                        if (sistemas[sistema.id] == null)
                            sistemas[sistema.id] = sistema;
                        if (sistemas[sistema.id].modulos == null)
                            sistemas[sistema.id].modulos = {};

                        sistemas[sistema.id].modulos[modulo.id] =
                            modulos[modulo.id];
                    }
                }
            
                
                let menuRaiz = this.encontraOuCriaMenuRaiz(item, modulos[item.modulo.id], menuItens);
                  
               
                
            });
             
            for (let i = 0; i < menuItens.length; i++) {
                this.encontraOuCriaParents(menuItens[i], null, null, modulos[menuItens[i].modulo.id], menuItens, 0);
                 
            } 
 
            
        }

        return sistemas;
    }
     
     
    private encontraOuCriaMenuRaiz(item: any, modulo: any, menuItens: any[]) {
        item = this.encontraItemReferencia(item, menuItens);
        if (item.parent)
           return this.encontraOuCriaMenuRaiz(item.parent, modulo, menuItens);
        let menuRaiz = null;
        Object.keys(modulo._menuTree).forEach((menuId) => {
               if (menuId == item.id) {
                   menuRaiz = modulo._menuTree[menuId];
               }
        });
        if (menuRaiz == null) {
            modulo._menuTree[item.id] = item;
            menuRaiz = item;
            menuRaiz.menuTree = {};
            menuRaiz.tipo = 'menu';
        }
        
        return menuRaiz;
    }
    
    
    private encontraOuCriaParents(item, child, menuRaiz, modulo, menuItens, level: number = 0) {
        item = this.encontraItemReferencia(item, menuItens);
        if (item) { 
            if (item.descricao == '___root') item.tipo = '___root';
            if (item['menuTree'] == null) item['menuTree'] = {};
            if (child)  item['menuTree'][child.id] = child;
            
        }
        
        if (item['parent'] !== null) {
            parent = this.encontraItemReferencia(item['parent'], menuItens);
            if (parent !==  null) { 
                item.tipo = 'subMenu';
                item['parent'] = parent;
                this.encontraOuCriaParents(parent, item, menuRaiz, modulo, menuItens, level++);
            } else {
                item.tipo = 'menu';
            }
        }
             
    }
     
    private encontraItemReferencia(item: any, menuItens: any[]): any {
            let reference: any = null;
           for (let i = 0; i < menuItens.length; i++) {
               if (menuItens[i].id == item.id) {
                   reference = menuItens[i];
                   i=menuItens.length;
               }
           }
           return reference;
           
    } 

    //      /** Add an item to to-do list */
    //      insertItem(parent: MenuItemNode, name: string) {
    //        if (parent.children) {
    //          parent.children.push({label: name} as MenuItemNode);
    //          this.dataChange.next(this.data);
    //        }
    //      }
    //
    //      updateItem(node: MenuItemNode, name: string) {
    //        node.label = name;
    //        this.dataChange.next(this.data);
    //      }

    public filter(filterText: string): MenuNode {
        let treeNode = this.buildNodeTree(this.sistemas, 0);
        let rootNode: MenuNode = {
            label: '_root',
            hasChilds: true,
            itemValue: { descricao: '_root', nome: '_root' },
            children: treeNode,
            tipo: '_root'
        };
        let filteredNodes: MenuNode = this.filterTreeRecursion(
            filterText,
            rootNode
        );
        // Notify the change.
        this.dataChange.next(filteredNodes ? filteredNodes.children || [] : []);
        return filteredNodes;
    }
    private filterTreeRecursion(
        searchedText: string,
        node?: MenuNode
    ): MenuNode {
        //if not - continue to check for it's children
        if (node.children && node.children.length > 0) {
            let newParentNode: MenuNode = {
                children: [],
                itemValue: node.itemValue,
                hasChilds: true,
                label: node.label,
                tipo: 'menu'
            };

            //going through all node's children
            for (let i = 0; i < node.children.length; i++) {
                let resNode = this.filterTreeRecursion(
                    searchedText,
                    node.children[i]
                );
                if (resNode !== undefined) {
                    newParentNode.children.push(resNode); //if there is a result, it's asearched node or a forefather of the searched node - so we add it
                }
            }

            if (newParentNode.children.length > 0) {
                return newParentNode;
            }
        } else {
            // if node's name includes the searched string - returns it
            if (
                node.label &&
                node.label
                    .toLocaleLowerCase()
                    .includes(searchedText.toLocaleLowerCase())
            ) {
                return node;
            }
        }
    }
}

@Component({
    selector: 'app-menu-tree',
    templateUrl: 'menu-tree.component.html',
    styleUrls: ['menu-tree.component.scss'],
    animations: fuseAnimations,
    providers: [MenuDatabase]
})
export class MenuTreeComponent {

    menuEdit: Menu = null;
    flatNodeMap = new Map<MenuFlatNode, MenuNode>();
    nestedNodeMap = new Map<MenuNode, MenuFlatNode>();
    selectedParent: MenuFlatNode | null = null;
    newItemName = '';

    treeControl: FlatTreeControl<MenuFlatNode>;

    treeFlattener: MatTreeFlattener<MenuNode, MenuFlatNode>;

    treeDataSource: MatTreeFlatDataSource<MenuNode, MenuFlatNode>;

    checklistSelection = new SelectionModel<MenuFlatNode>(true);

    constructor(
        protected activatedRoute: ActivatedRoute,
        private _database: MenuDatabase
    ) {
        this.treeFlattener = new MatTreeFlattener(
            this.transformer,
            this.getLevel,
            this.isExpandable,
            this.getChildren
        );
        this.treeControl = new FlatTreeControl<MenuFlatNode>(
            this.getLevel,
            this.isExpandable
        );
        this.treeDataSource = new MatTreeFlatDataSource(
            this.treeControl,
            this.treeFlattener
        );
    } 

    ngOnInit(): void {
        this.activatedRoute.data.subscribe(response => {
            
            this._database.initialize(response.data.content ? response.data.content : response.data);
        });

        this._database.dataChange.subscribe(data => {
            this.treeDataSource.data = data;
        });
    }
 
    public criarMenu(item: any) {
         
        let edit: Menu = new Menu();
        if (item.tipo === 'modulo') {  // cria menu no modulo
           
             edit.modulo = Modulo.fromJson(item);
        }
        if (item.tipo === 'menu' || item.tipo === 'subMenu') {  // cria sub menu do menu
            edit.modulo = item.modulo;
            edit.parent = item;
        }
        
        this.menuEdit = edit;
 
    }
    
    public editarMenu(menu: any) {
        this.menuEdit = menu;
    }
    public onMenuDetailChange($event) {
        if ($event && $event == 'salvar') {
            this._database.reload(null);
        }
    }
    

    getLevel = (node: MenuFlatNode) => node.level;

    isExpandable = (node: MenuFlatNode) => node.expandable;

    getChildren = (node: MenuNode): MenuNode[] => node.children;

    hasChild = (_: number, _nodeData: MenuFlatNode) => {
       return _nodeData.expandable &&  _nodeData.itemValue.tipo !== '___root';
    };
    hasChildNoRoot = (_: number, _nodeData: MenuFlatNode) => {
       return _nodeData.expandable &&  _nodeData.itemValue.tipo !== '___root';
    }; 
    hasRoot = (_: number, _nodeData: MenuFlatNode) => {
        return  _nodeData.itemValue.tipo == '___root';
    }; 


    hasNoContent = (_: number, _nodeData: MenuFlatNode) =>
        _nodeData.label === '';

    transformer = (node: MenuNode, level: number) => {
        const existingNode = this.nestedNodeMap.get(node);
        const flatNode =
            existingNode && existingNode.label === node.label
                ? existingNode
                : new MenuFlatNode();

        flatNode.label = node.label;
        flatNode.level = level;
        flatNode.expandable = !!node.children;
        flatNode.itemValue = node.itemValue;
        flatNode.hasChilds = (node.itemValue.modulos && Object.keys(node.itemValue.modulos).length > 0) || (node.itemValue._menuTree && Object.keys(node.itemValue._menuTree).length > 0);
                            (node.itemValue.menus && node.itemValue.menus.length > 0);

        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node, flatNode);
        return flatNode;
    };

    /** Whether all the descendants of the node are selected. */
    descendantsAllSelected(node: MenuFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected = descendants.every(child =>
            this.checklistSelection.isSelected(child)
        );
        return descAllSelected;
    }

    /** Whether part of the descendants are selected */
    descendantsPartiallySelected(node: MenuFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const result = descendants.some(child =>
            this.checklistSelection.isSelected(child)
        );
        return result && !this.descendantsAllSelected(node);
    }

    /** Toggle the to-do item selection. Select/deselect all the descendants node */
    todoItemSelectionToggle(node: MenuFlatNode): void {
        this.checklistSelection.toggle(node);
        const descendants = this.treeControl.getDescendants(node);
        this.checklistSelection.isSelected(node)
            ? this.checklistSelection.select(...descendants)
            : this.checklistSelection.deselect(...descendants);

        // Force update for the parent
        descendants.every(child => this.checklistSelection.isSelected(child));
        this.checkAllParentsSelection(node);
    }

    /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
    todoLeafItemSelectionToggle(node: MenuFlatNode): void {
        this.checklistSelection.toggle(node);
        this.checkAllParentsSelection(node);
    }

    /* Checks all the parents when a leaf node is selected/unselected */
    checkAllParentsSelection(node: MenuFlatNode): void {
        let parent: MenuFlatNode | null = this.getParentNode(node);
        while (parent) {
            this.checkRootNodeSelection(parent);
            parent = this.getParentNode(parent);
        }
    }

    /** Check root node checked state and change it accordingly */
    checkRootNodeSelection(node: MenuFlatNode): void {
        const nodeSelected = this.checklistSelection.isSelected(node);
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected = descendants.every(child =>
            this.checklistSelection.isSelected(child)
        );
        if (nodeSelected && !descAllSelected) {
            this.checklistSelection.deselect(node);
        } else if (!nodeSelected && descAllSelected) {
            this.checklistSelection.select(node);
        }
    }

    /* Get the parent node of a node */
    getParentNode(node: MenuFlatNode): MenuFlatNode | null {
        const currentLevel = this.getLevel(node);

        if (currentLevel < 1) {
            return null;
        }

        const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControl.dataNodes[i];

            if (this.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }

    filterChanged(filterText: string) {
        // let filteredNodes: MenuItemNode[] = this._database.filter(filterText);
        this._database.filter(filterText);
        // this._database.reload(filteredNodes);

        if (filterText) {
            this.treeControl.expandAll();
        } else {
            this.treeControl.collapseAll();
        }
    }

    /** Select the category so we can insert the new item. */
    //      addNewItem(node: MenuItemFlatNode) {
    //        const parentNode = this.flatNodeMap.get(node);
    //        this._database.insertItem(parentNode!, '');
    //        this.treeControl.expand(node);
    //      }
    //
    //      /** Save the node to database */
    //      saveNode(node: MenuItemFlatNode, itemValue: string) {
    //        const nestedNode = this.flatNodeMap.get(node);
    //        this._database.updateItem(nestedNode!, itemValue);
    //      }
}
