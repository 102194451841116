import { Component, Input, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Imagem } from 'app/main/core/models/imagem.model';
import { ImagemService } from 'app/main/core/services/image.service';
import { Usuario } from 'app/main/pages/usuarios/usuario.model';
import { SafeUrl } from '@angular/platform-browser';
import * as _ from 'lodash';
import { SnackBarService } from '../../services/snack-bar/snack-bar.service';

@Component({
  selector: 'app-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.scss']
})
export class ProfilePictureComponent implements AfterViewInit  {

  @Input() usuario: Usuario;
  imagem: Imagem;
  url: SafeUrl = '';
  isImageLoading = false;

  constructor(
    protected imagemService: ImagemService,
    protected dialog: MatDialog,
    protected snackBarService: SnackBarService
 ) {}

  ngAfterViewInit(): void{
    this.consultarImagem();
  }

  public consultarImagem(): void {
    this.imagemService.getByUsuarioCPF(this.usuario.login)
    .subscribe( img => {
      this.imagem = img,
      this.getImageFromService();
    });
  }

  getImageFromService(): void {
    if (this.imagem.chave){
      this.isImageLoading = true;
      this.imagemService.getImage(this.imagem).subscribe(data => {
        this.createImageFromBlob(data);
        this.isImageLoading = false;
      }, error => {
        this.isImageLoading = false;
        this.snackBarService.showError('Problema ao consultar imagem de perfil!');
      });
    }
  }

  createImageFromBlob(image: Blob): void {  
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.url = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  onSelectFile(fileInput): void {
    if (fileInput.target.files && fileInput.target.files[0]) {

      const MAX_SIZE = 20971520;
      const ALLOWED_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];

      if (fileInput.target.files[0].size > MAX_SIZE) {
        this.snackBarService.showAlert('Tamanho máximo da imagem é de ' + MAX_SIZE / 1000 + 'Mb');
        return;
     }
      if (!_.includes(ALLOWED_TYPES, fileInput.target.files[0].type)) {
        this.snackBarService.showAlert('Somente imagens do tipo ( JPEG | JPG | PNG ) são permitidas');
        return;
     }

      const reader = new FileReader();

      reader.readAsDataURL(fileInput.target.files[0]);
      const file = fileInput.target.files[0];
      this.updateImagem(file);

      reader.onload = (f: any) => {
        this.url = f.target.result;
      };
    }
  }

  private updateImagem(multipartFile): void {
     if (this.imagem.id === null){
        this.imagemService.updateMultipart(this.imagem, multipartFile).subscribe( () => {
            this.snackBarService.showSuccess('Imagem atualizada com sucesso!');
        },
            (e) => this.snackBarService.showError('Erro ao atualizar imagem.',e)
        );
      }else{
          this.imagemService.createMultipart(this.imagem, multipartFile).subscribe( () => {
              this.snackBarService.showSuccess('Imagem inserida com sucess');
          },
              (e) => this.snackBarService.showError('Erro ao inserir imagem.',e)
          );
    }
  }

}

