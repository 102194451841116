import {Component, OnInit} from '@angular/core';
import {FuseConfigService} from '../../../../@fuse/services/config.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SnackBarService} from '../../shared/services/snack-bar/snack-bar.service';
import {ActivatedRoute} from '@angular/router';
import {DadosDocumentoVerificado} from './dados-documento-verificado.model';
import {DatePipe} from '@angular/common';
import {MaskPipe} from 'ngx-mask';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {DocumentoProcessoLicenciamentoService} from '../../core/services/documento-processo-licenciamento.service';
import {PdfViewerDialog} from '../../shared/components/pdf-viewer-dialog/pdf-viewer-dialog.model';
import { environment } from 'environments/environment';

@Component({
    selector: 'verificar-documento',
    templateUrl: './verificar-documento.component.html',
    styleUrls: ['./verificar-documento.component.scss']
})
export class VerificarDocumentoComponent implements OnInit {

    siteKey: string = environment.SITE_KEY;
    
    dadosDocumento: DadosDocumentoVerificado = null;

    form: FormGroup = new FormGroup({
        codVerificador: new FormControl(null, Validators.required),
        codCRC: new FormControl(null, Validators.required),
        captcha: new FormControl(null, Validators.required)
    });
    colunasInfo: InterfaceColunaInfo[] = [];
    colsBreakpoint = 2;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private snackBarService: SnackBarService,
        private activatedRoute: ActivatedRoute,
        public datePipe: DatePipe,
        public maskPipe: MaskPipe,
        public breakpointObserver: BreakpointObserver,
        public documentoProcessoLicenciamentoService: DocumentoProcessoLicenciamentoService,
        public pdfViewerDialog: PdfViewerDialog,
    ) {
        breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
            this.colsBreakpoint = result.matches ? 1 : 2;
        });
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe(params => {
            if (params['verificador'] || params['CRC']) {
                this.form.controls.codVerificador.patchValue(params['verificador']);
                this.form.controls.codCRC.patchValue(params['CRC']);
                this.pesquisar();
            }
        });
    }

    pesquisar(): void {
        if (this.form.controls.captcha.invalid) {
             this.snackBarService.showAlert('Favor validar o Captcha');
        } else
        if (this.form.invalid) {
            this.snackBarService.showAlert('Código Verificador ou Código CRC está invalido, favor verificar!');
        } else {
            this.documentoProcessoLicenciamentoService.verificarDocumento(this.form.controls.codVerificador.value,
                this.form.controls.codCRC.value, this.form.controls.captcha.value).subscribe(result => {
                this.dadosDocumento = result;
                this.processarColunasInfo(this.dadosDocumento);
            }, e => this.snackBarService.showError('Erro ao buscar documento/titulo', e));
        }
    }

    processarColunasInfo(dados: DadosDocumentoVerificado): void {
        this.colunasInfo = [
            {titulo: 'Título', valor: dados.titulo},
            {titulo: 'Número', valor: dados.numero},
            {titulo: 'Validade', valor: this.datePipe.transform(dados.validade, 'dd/MM/yyyy')},
            {titulo: 'Situação', valor: dados.situacao},
            {titulo: 'Interessado', valor: dados.interessado},
            {titulo: 'CPF/CNPJ', valor: this.maskPipe.transform(dados.cpfCnpj, 'CPF_CNPJ')},
            {titulo: 'UF', valor: dados.uf},
            {titulo: 'Município', valor: dados.municipio},
            // {titulo: 'Latitude do Imóvel', valor: dados.latImovel.toString()},
            // {titulo: 'Longitude do Imóvel', valor: dados.longImovel.toString()},
        ];
    }

    async download() {
        const blob = await this.documentoProcessoLicenciamentoService.readPDFByChave(this.dadosDocumento.chaveArquivo).toPromise();
        this.pdfViewerDialog.show(blob);
    }

    novaPesquisa(): void {
        this.form.patchValue({
            codVerificador: null,
            codCRC: null,
            captcha: null
        });
        this.form.markAsUntouched();
        this.form.markAsPristine();
        this.form.updateValueAndValidity();
        this.dadosDocumento = null;
    }
}

export interface InterfaceColunaInfo {
    titulo: string;
    valor: string;
}
