import { Usuario } from './../../pages/usuarios/usuario.model';
import { Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { GrupoUsuarioDTO } from '../models/grupo-usuario-dto.model';
import { Grupo } from 'app/main/pages/grupos/grupo.model';

@Injectable({
    providedIn: 'root'
})
export class GrupoUsuarioService {

    private  http: HttpClient;
    private  urlResource: string = environment.URL_GATEWAY;

    constructor(
        private injector: Injector,
    ) {
        this.http = injector.get(HttpClient);
        this.urlResource += '/api/grupos-usuario';
    }

    getAllByUsuario(usuario: Usuario): Observable<GrupoUsuarioDTO[]> {
        const url = `${this.urlResource}/usuarios/${usuario.id}`;
        return this.http.get(url).pipe(
            map(this.jsonDataToGrupoUsuarioDTOList),
            catchError(this.handleError)
        );
    }

    addGrupoToUsuario(grupo: Grupo, usuario: Usuario): Observable<GrupoUsuarioDTO> {
        const dto: GrupoUsuarioDTO = {idGrupo: grupo.id, idUsuario: usuario.id };
        return this.http
        .post(this.urlResource, dto)
        .pipe(
            catchError(this.handleError),
            map(this.jsonDataToGrupoUsuarioDTO),
        );
    }

    removeGrupoFromUsuario(grupoUsuario: GrupoUsuarioDTO): Observable<any> {
        const url = `${this.urlResource}/grupos/${grupoUsuario.idGrupo}/usuarios/${grupoUsuario.idUsuario}`;
        return this.http.
            delete(url).
            pipe(
                map(() => null),
                catchError(this.handleError)
            );
    }

    private jsonDataToGrupoUsuarioDTOList(jsonData: any[]): GrupoUsuarioDTO[]{
        const entries: GrupoUsuarioDTO[] = [];
    
        jsonData.forEach(element => {
          const rt = Object.assign(new GrupoUsuarioDTO(), element);
          entries.push(rt);
        });
        return entries;
      }

    private jsonDataToGrupoUsuarioDTO(jsonData: any): GrupoUsuarioDTO {
        return Object.assign(new GrupoUsuarioDTO(), jsonData);
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }

}
