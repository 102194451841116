import { Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Grupo } from '../grupos/grupo.model';
import { GrupoPerfilDTO } from './grupo-perfil-dto.model';
import { Perfil } from '../perfis/perfil.model';

@Injectable({
    providedIn: 'root'
})
export class GrupoPerfilService {

    private  http: HttpClient;
    private  urlResource: string = environment.URL_GATEWAY;

    constructor(
        private injector: Injector,
    ) {
        this.http = injector.get(HttpClient);
        this.urlResource += '/api/grupo-perfis';
    }

    getAllByGrupo(grupo: Grupo): Observable<GrupoPerfilDTO[]> {
        const url = `${this.urlResource}/grupos/${grupo.id}`;
        return this.http.get(url).pipe(
            map(this.jsonDataToGrupoPerfilDtoList),
            catchError(this.handleError)
        )
    }

    addPerfilToGrupo(grupo: Grupo, perfil: Perfil): Observable<GrupoPerfilDTO> {
       
        const dto: GrupoPerfilDTO = {idGrupo: grupo.id, idPerfil: perfil.id };

        return this.http
        .post(this.urlResource, dto)
        .pipe(
            catchError(this.handleError),
            map(this.jsonDataToGrupoPerfilDto),
        );
    }

    removePerfilFromGrupo(perfil: GrupoPerfilDTO): Observable<any> {
        const url = `${this.urlResource}/grupos/${perfil.idGrupo}/perfis/${perfil.idPerfil}`;
        return this.http.
            delete(url).
            pipe(
                map(() => null),
                catchError(this.handleError)
            );
    }

    private jsonDataToGrupoPerfilDtoList(jsonData: any[]): GrupoPerfilDTO[]{
        const entries: GrupoPerfilDTO[] = [];
    
        jsonData.forEach(element => {
          const rt = Object.assign(new GrupoPerfilDTO(), element);
          entries.push(rt);
        });
        return entries;
      }

    private jsonDataToGrupoPerfilDto(jsonData: any): GrupoPerfilDTO {
        return Object.assign(new GrupoPerfilDTO(), jsonData);
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }

}
