import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Inject, OnInit, Pipe, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { environment } from 'environments/environment';
import { Sistema } from '../sistemas/sistema.model';
import { SistemaService } from '../sistemas/sistema.service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {
    sistemas: Array<Sistema>;

    constructor(protected sistemaService: SistemaService,
        protected httpClient: HttpClient,
        protected router: Router,
        @Inject(DOCUMENT) private document: Document) {
    }

    ngOnInit(): void {
        this.sistemaService
            .getAll()
            .subscribe(sistemas => (this.sistemas = sistemas.filter(s => s.mostrarNoDashboard)));
    }

    public testeAuditoriaHeader() {
        console.log('testeAuditoria');
        this.httpClient.get(`${environment.URL_GATEWAY}/api/test/headers`, { withCredentials: true }).subscribe((res) => {
            console.log('Resultado: ', res);
        });
    }

    navigate(sistema: Sistema) {
        if (sistema) {
            if (sistema.url && ( sistema.url.indexOf('#') != -1  ||  sistema.url.indexOf('sema.mt.gov.br') != -1)) {
                this.router.navigateByUrl(`/pages?_redirectUrl=${sistema.url}`);
                return;
            }
        }
        this.document.location.href = sistema.url;
    }
}


@Pipe({
    name: 'ellipsis'
})
export class EllipsisPipe {
    transform(val, args) {
        if (args === undefined) {
            return val;
        }

        if (val.length > args) {
            return val.substring(0, args) + '...';
        } else {
            return val;
        }
    }
}
