import {BaseEnum} from '../../shared/models/base.enum ';
import {PessoaFisica} from '../../core/models/pessoa-fisica.model';
import {PessoaLicenciamento} from './pessoa-licenciamento.model';

export class Representante {
    constructor(
        public tipoRepresentante?: string,
        public representadoId?: number,
        public representado?: PessoaLicenciamento,
        public representanteId?: number,
        public representante?: PessoaFisica,
        public ativo?: boolean,
    ) {
    }

    static tiposRepresentante: BaseEnum[] = [
        {
            valor: 'REPRESENTANTE_LEGAL',
            descricao: 'Representante legal'
        },
        {
            valor: 'RESPONSAVEL_LEGAL',
            descricao: 'Responsavel legal'
        },
        {
            valor: 'SOCIO_COTISTA',
            descricao: 'Sócio cotista'
        }

    ];

    static fromJson(json: any): Representante {
        if (json === undefined || json === null) {
            return null;
        }
        return new Representante(
            json.tipoRepresentante,
            json.representadoId,
            json.representado,
            json.representanteId,
            PessoaFisica.fromJson(json.representante),
            json.ativo
        );
    }

    static fromJsons(jsonArray: any): Representante[] {
        const resources = [];
        if (jsonArray === undefined || jsonArray === null) {
            return [];
        } else {
            jsonArray.forEach(resource => resources.push(this.fromJson(resource)));
            return resources;
        }
    }

    static criarRepresentate(tipoRepresentante, representadoId, representanteId): Representante {
        if (tipoRepresentante === undefined || tipoRepresentante === null) {
            return null;
        }
        return new Representante(null, null, null,
            tipoRepresentante,
            representadoId,
            representanteId
        );
    }

}
