import { BaseModel } from 'app/main/shared/models/base.model';
import { BaseEnum } from 'app/main/shared/models/base.enum ';

export class DocumentoTemplate extends BaseModel {
    constructor(
        public id?: number,
        public descricao?: string,
        public tipoPessoa?: string,
        public tamanhoDocumento?: string,
        public tamanhoPagina?: string
    ) {
        super();
    }

    static tiposPessoa: BaseEnum[] = [
        { valor: 'PF', descricao: 'Pessoa Física' },
        { valor: 'PJ', descricao: 'Pessoa Jurídica' },
        { valor: 'EX', descricao: 'Extrangeiro' },
        { valor: 'TD', descricao: 'Todos' }
    ];

    static tamanhosDocumento: BaseEnum[] = [
        { valor: 'TREZENTOS_KB', descricao: '300 kilobyte (kB)' },
        { valor: 'UM_MEGA', descricao: '1 megabyte (MB)' },
        { valor: 'DOIS_MEGA', descricao: '2 megabyte (MB)' },
        { valor: 'TRES_MEGA', descricao: '3 megabyte (MB)' },
        { valor: 'QUATRO_MEGA', descricao: '4 megabyte (MB)' },
        { valor: 'CINCO_MEGA', descricao: '5 megabyte (MB)' },
        { valor: 'DEZ_MEGA', descricao: '10 megabyte (MB)' },
        { valor: 'QUINZE_MEGA', descricao: '15 megabyte (MB)' }
    ];

    static tamanhosPagina: BaseEnum[] = [
        { valor: 'TREZENTOS_KB', descricao: '300 kilobyte (kB)' },
        { valor: 'UM_MEGA', descricao: '1 megabyte (MB)' },
        { valor: 'DOIS_MEGA', descricao: '2 megabyte (MB)' },
        { valor: 'TRES_MEGA', descricao: '3 megabyte (MB)' },
        { valor: 'QUATRO_MEGA', descricao: '4 megabyte (MB)' },
        { valor: 'CINCO_MEGA', descricao: '5 megabyte (MB)' }
    ];

    static fromJson(json: any): DocumentoTemplate {
        if (json === undefined || json === null){
            return null;
        } 
        return new DocumentoTemplate(
            json.id,
            json.descricao,
            json.tipoPessoa,
            json.tamanhoDocumento,
            json.tamanhoPagina
        );
    }
}
