import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { MatDialog } from '@angular/material';
import { ConfirmarExclusaoDialogComponent } from '../confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import { Pessoa } from 'app/main/core/models/pessoa.model';
import { Documento } from 'app/main/core/models/documento.model';
import { DocumentoService } from 'app/main/core/services/documento.service';
import { AuthenticationService } from '../../services/authentication.service';
import { Usuario } from 'app/main/pages/usuarios/usuario.model';
import { PessoaService } from 'app/main/core/services/pessoa.service';
import { CurrentUser } from '../../models/current-user.model';
import { Router } from '@angular/router';
import { DocumentoDialogComponent } from '../documento-dialog/documento-dialog.component';
import { Constants } from '../../utils/constants';
import {DocumentoTemplate} from '../../../core/models/documento-template.model';
import { SnackBarService } from '../../services/snack-bar/snack-bar.service';
import { UsuarioService } from 'app/main/pages/usuarios/usuario.service';

@Component({
    selector: 'app-documento-wizard',
    templateUrl: './documento-wizard.component.html',
})
export class DocumentoWizardComponent implements OnInit {
    @Output() eventNext = new EventEmitter();
    @Output() eventBack = new EventEmitter();
    pessoa: Pessoa;
    usuario: Usuario;
    currentUser: CurrentUser;
    documentos: Documento[];
    documentosValido = false;
    constructor(
        private pessoaService: PessoaService,
        protected documentoService: DocumentoService,
        protected dialog: MatDialog,
        protected snackBarService: SnackBarService,
        private authenticationService: AuthenticationService,
        private router: Router,
        private usuarioService: UsuarioService
    ) {}

    ngOnInit(): void {
        this.getUsuario(); 
    }

    getUsuario(): void{
        this.currentUser = this.authenticationService.currentUserDecode;
        this.loadResource();
    }

    protected loadResource(): void {
        this.pessoaService.getByPessoaFisicaCpf(this.currentUser.sub)
        .subscribe(response => {
            this.pessoa = response;
            this.listarDocumentos();
        });
    }

    public listarDocumentos(): void {
        this.documentoService
            .getByPessoaIdCadastro(this.pessoa)
            .subscribe(documentos => {
                this.documentos = documentos;
                this.documentosValido = this.documentos.every(item => item.opcional || item.nome != null);
            });
    }

    public documentoDialog(documento?: Documento): void {
        const dialogRef = this.dialog.open(DocumentoDialogComponent, {
            width: '600px',
            data:
                documento === undefined
                    ? new Documento(null, null, null, null, this.pessoa)
                    : documento
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.listarDocumentos();
            }
        });
    }

    updateDocumento(multipartFile, documento?: Documento): void {
        if (documento.id) {
            this.documentoService.updateMultipart(documento, multipartFile).subscribe(() => {
                    this.listarDocumentos();
                },
                (e) => {
                    this.snackBarService.showError(
                        'Não foi possível salvar o registro.', e);
                }
            );
        } else {
             this.documentoService.createMultipart(documento, multipartFile).subscribe(() => {
                    this.listarDocumentos();
                },
                (e) => {
                    this.snackBarService.showError(
                        'Não foi possível salvar o registro.', e);
                }
            );
        }
    }

    public excluirDocumentoDialog(documento: Documento): void {
        const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: { label: documento.id }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.excluirDocumento(documento);
            }
        });
    }

    public excluirDocumento(documento: Documento): void {
        this.documentoService.deleteArquivo(documento.id).subscribe(() => {
            this.snackBarService.showSuccess('Registro excluido com sucesso.');
            this.listarDocumentos();
        });
    }

    public visualizarDocumento(documento: Documento): void {
        window.open(
            this.documentoService.urlResource +
                '/' +
                documento.id +
                '/visualizar'
        );
    }

    public downloadDocumento(documento: Documento): void {
        window.open(
            this.documentoService.urlResource + '/' + documento.id + '/download'
        );
    }

    public get isCompleted(): boolean {
        if (this.documentos){
            const filterObj = this.documentos.filter(doc => {
                if (!doc.chave){
                    return true;
                }
            });
            return filterObj.length === 0;
        }
        return false;
    }

    concluir(): void {
        console.debug('concluir', this.isCompleted);
        if (this.isCompleted){
            this.completarCadastro();
            this.updateUsuarioSimplificado();
        }else {
            this.snackBarService.showAlert('Anexe todos os documentos.');
        }
    }

    protected updateUsuarioSimplificado(): void {
        const login = this.currentUser.sub;
        this.usuarioService.updateUsuarioSimplificado(login)
        .subscribe(response => {
            console.log('usuario...', response);
        });
    }

    protected completarCadastro(): void {
        console.debug('completarCadastro', Pessoa.fromJson(this.pessoa));
        this.pessoaService.completarCadastro(Pessoa.fromJson(this.pessoa)).subscribe(
            () => {
                this.snackBarService.showSuccess('Cadastro concluido com sucesso!');
                this.completarCadastroStorage();
            },
            (e) => this.snackBarService.showError('Erro ao processar sua solicitação', e)
        );
    }

    completarCadastroStorage(): any {
        console.debug('completarCadastroStorage')
        this.pessoaService.getCadastroCompleto(this.authenticationService.currentUserDecode.sub).subscribe(
            pessoa => {
                console.debug('getCadastroCompleto', pessoa);
                if (pessoa && pessoa.cadastroCompleto){
                    this.router.navigateByUrl('/dashboard', { skipLocationChange: false });
                } else {
                    this.router.navigate([Constants.PATH_WIZARD], { skipLocationChange: true });
                }
            }
        );
    }

    public formatarLabelTamanhoDoc(tamanho, opcaoTamanho): string {
        if (opcaoTamanho === 'DOC'){
            DocumentoTemplate.tamanhosDocumento.forEach(t => { if (t.valor === tamanho){  tamanho = t.descricao; }});
            return tamanho;
        }
        DocumentoTemplate.tamanhosPagina.forEach(t => { if (t.valor === tamanho){  tamanho = t.descricao; }});
        return tamanho;
    }

    public back(): void {
        this.eventBack.emit();
    }

    next(): void {
        this.eventNext.emit();
    }
}
