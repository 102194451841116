import { HttpParams } from '@angular/common/http';
import { Component, ViewEncapsulation, Injector } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { BaseListComponent } from 'app/main/shared/components/base-list/base-list.component';
import { ModuloService } from '../modulo.service';
import { Modulo } from '../modulo.model';

@Component({
    selector: 'app-modulo-list',
    templateUrl: './modulo-list.component.html',
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class ModuloListComponent extends BaseListComponent<Modulo> {
    
    nome: string;
    
    constructor(injector: Injector, service: ModuloService) {
        super(injector, ['id', 'nome', 'descricao', 'sistema', 'ativo'], service);
    }

    fillFilters(httpParams: HttpParams): HttpParams {
        if (typeof this.nome !== 'undefined' && this.nome.length > 0){
            httpParams = httpParams.set('nome', this.nome);
        }
        return httpParams;
    }
}
