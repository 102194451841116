import {Injectable, Injector} from '@angular/core';
import {Representante} from '../models/representante.model';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {PessoaFisica} from "../../core/models/pessoa-fisica.model";

@Injectable({
    providedIn: 'root'
})
export class RepresentanteService {
    protected http: HttpClient;
    urlResource = `${environment.URL_SCORP_API}/api/representantes`;

    constructor(protected injector: Injector) {
        this.http = injector.get(HttpClient);
    }

    create(responsavel: Representante): Observable<Representante[]> {
        return this.http
            .post(this.urlResource, responsavel)
            .pipe(
                map(response => Representante.fromJson(response)),
                catchError(this.handleError.bind(this))
            );
    }

    update(responsavel: Representante): Observable<Representante> {
        return this.http
            .put(this.urlResource, responsavel)
            .pipe(
                map(response => Representante.fromJson(response)),
                catchError(this.handleError.bind(this))
            );
    }

    getRepresentantes(idPessoa): Observable<Representante[]> {
        const url = `${this.urlResource}/pessoa/${idPessoa}`;

        return this.http
            .get(url)
            .pipe(
                map(response => Representante.fromJsons(response)),
                catchError(this.handleError.bind(this))
            );
    }

    getAllByRepresentante(pessoaFisicaId: number): Observable<Representante[]> {
        const url = `${this.urlResource}/representante/${pessoaFisicaId}`;

        return this.http.get(url).pipe(
                map(response => Representante.fromJsons(response)),
                catchError(this.handleError.bind(this))
            );
    }

    desativaRepresentante(idRepresentado, idRepresentante): Observable<any> {
        const url = `${this.urlResource}/${idRepresentado}/${idRepresentante}`;
        return this.http.
        delete(url).
        pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }

    ativaRepresentante(idRepresentado, idRepresentante): Observable<any> {
        const url = `${this.urlResource}/${idRepresentado}/${idRepresentante}`;
        return this.http.put(url, {}).
        pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }
    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }
}

