import { HttpParams } from "@angular/common/http";
import { Component, ViewEncapsulation, Injector } from "@angular/core";
import { fuseAnimations } from "@fuse/animations";
import { BaseListComponent } from "app/main/shared/components/base-list/base-list.component";
import { SistemaService } from '../sistema.service';
import { Sistema } from '../sistema.model';

@Component({
    selector: "app-sistema-list",
    templateUrl: "./sistema-list.component.html",
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class SistemaListComponent extends BaseListComponent<Sistema> {
    
    nome: string;
    
    constructor(injector: Injector, service: SistemaService) {
        super(injector, ["id", "nome", "descricao", "ativo"], service);
    }

    fillFilters(httpParams: HttpParams) {
        if (typeof this.nome !== "undefined" && this.nome.length > 0)
            httpParams = httpParams.set("nome", this.nome);
        return httpParams;
    }
}
