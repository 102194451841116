import { Injectable, Injector } from "@angular/core";
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RoutingStateService } from '../../core/services/routing-state.service';
import { DetailResolver } from '../../shared/resolvers/detail-resolver';
import { ListResolver } from '../../shared/resolvers/list-resolver';
import { BaseService } from '../../shared/services/base.service';
import { UsuarioApiKey } from './api-key.model';

@Injectable()
export class UsuarioApiKeyListResolver extends ListResolver<UsuarioApiKey> {
    constructor(service: UsuarioApiKeyService) {
        super(service);
    }
}

@Injectable()
export class UsuarioApiKeyResolver extends DetailResolver<UsuarioApiKey> {
    constructor(
        service: UsuarioApiKeyService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({ providedIn: "root" })
export class UsuarioApiKeyService extends BaseService<UsuarioApiKey> {
    constructor(
        protected injector: Injector,        
    ) {
        super(
            '/api/usuarios/api-keys',
            injector,
            UsuarioApiKey.prototype,
            UsuarioApiKey.fromJson
        );
    }

    public createApiKey(apiKey: UsuarioApiKey): Observable<UsuarioApiKey> {
        return this.http
            .post(this.urlResource, apiKey)
            .pipe(
                catchError(this.handleError.bind(this))
            );
    }

    public updateApiKey(apiKey: UsuarioApiKey): Observable<UsuarioApiKey> {
        const url = `${this.urlResource}/${apiKey.id}`;

        return this.http
            .put(url, apiKey)
            .pipe(
                catchError(this.handleError.bind(this))
            );
    }

    public deleteApiKey(apiKey: UsuarioApiKey): Observable<any> {
        const url = `${this.urlResource}/${apiKey.id}`;

        return this.http
            .delete(url)
            .pipe(
                map(() => null),
                catchError(this.handleError.bind(this))
            );
    }    
}