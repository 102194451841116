import { Component, OnInit, Injector, ViewEncapsulation } from '@angular/core';
import { BaseDetailComponent } from 'app/main/shared/components/base-detail/base-detail.component';
import { Grupo } from '../grupo.model';
import { GrupoService } from '../grupo.service';
import { PerfilService } from '../../perfis/perfil.service';
import { Validators, FormControl } from '@angular/forms';
import { Perfil } from '../../perfis/perfil.model';
import { fuseAnimations } from '@fuse/animations';
import { GrupoPerfilDTO } from '../../grupo-perfis/grupo-perfil-dto.model';
import { GrupoPerfilService } from '../../grupo-perfis/grupo-perfil.service';

@Component({
    selector: 'app-grupo-detail',
    templateUrl: './grupo-detail.component.html',
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class GrupoDetailComponent extends BaseDetailComponent<Grupo>
    implements OnInit {
    perfis: Perfil[];
    perfisAdicionados: GrupoPerfilDTO[];
    perfilControl = new FormControl(null, [Validators.required]);
    perfilColumns = ['descricaoPerfil', 'delegavel', 'excluir'];

    constructor(
        protected grupoService: GrupoService,
        protected perfilService: PerfilService,
        protected grupoPerfilService: GrupoPerfilService,
        protected injector: Injector
    ) {
        super(injector, new Grupo(), grupoService, GrupoDetailComponent.fromJson);
    }

    protected buildResourceForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            nome: [null,
                [
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(200)
                ]
            ],
            descricao: [null, [Validators.maxLength(250)]],
            dataInicio: [{ value: null, disabled: true }],
            dataFim: [null]
        });
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    protected afterResourceLoad(): void {
        this.carregaPerfis();
        this.listarPerfisAdicionados();
    }

    private carregaPerfis(): void {
        this.perfilService.getAll().subscribe(perfis => (this.perfis = perfis));
    }

    removerPerfil(perfil: GrupoPerfilDTO): void {
        this.grupoPerfilService.removePerfilFromGrupo(perfil).subscribe(
            () => {
                this.snackBarService.showSuccess('Perfil excluído com sucesso!');
                this.listarPerfisAdicionados();
            },
            (e) =>
                this.snackBarService.showError('Erro ao excluir perfil.',e)
        );
    }

    listarPerfisAdicionados(): void {
        this.grupoPerfilService.getAllByGrupo(this.resource).subscribe(
            perfizz => (this.perfisAdicionados = perfizz),
            (e) =>
                this.snackBarService.showError('Erro ao listar perfis do grupo.',e)
        );
    }

    adicionarPerfil(): void {
        this.grupoPerfilService
            .addPerfilToGrupo(this.resource, this.perfilControl.value)
            .subscribe(
                () => {
                    this.snackBarService.showSuccess('Perfil adicionado com sucesso!');
                    this.perfilControl.reset();
                    this.listarPerfisAdicionados();
                },
                (e) =>
                    this.snackBarService.showError('Erro ao adicionar perfil.',e)
            );
    }

    get possuiPerfisAdicionados(): boolean {
        return (
            typeof this.perfisAdicionados !== 'undefined' &&
            this.perfisAdicionados.length > 0
        );
    }

    protected creationPageTitle(): string {
        return 'Cadastro de novo grupo';
    }

    protected editionPageTitle(): string {
        const resourceName = this.resource.descricao || '';
        return 'Editando o grupo: ' + resourceName;
    }

    protected visualizationPageTitle(): string {
        const resourceName = this.resource.descricao || '';
        return 'Visualizando o grupo: ' + resourceName;
    }

    omit_special_char(val) {
        var name = this.form.get("nome").value;
        if (name !== null && name !== "") {
            return this.form.patchValue({nome: name.normalize("NFD").replace(/\W/g, '').replace(/_/g, '')})
        }
    }

    get isPermiteEdicaoNomeGrupo(): boolean {
        return this.resource.id == null;
    }

    static fromJson(json: any): Grupo {
        if (json === undefined || json === null){
            return null;
        } 
        return new Grupo(
            json.id,
            json.nome.normalize("NFD").replace(/\W/g, '').replace(/_/g, ''),
            json.descricao,
            json.ativo,
            json.dataInicio,
            json.dataFim
        );
    }
}
