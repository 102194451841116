import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {FuseConfigService} from '@fuse/services/config.service';
import {fuseAnimations} from '@fuse/animations';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {ValidateBrService} from 'angular-validate-br';
import {PessoaService} from 'app/main/core/services/pessoa.service';
import {SnackBarService} from 'app/main/shared/services/snack-bar/snack-bar.service';
import { DOCUMENT } from '@angular/common';
import { SistemaModuleInfoUtil } from 'app/main/shared/services/sistema-modulo-identifier.util';
import { table } from 'console';
import { map, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss', './hambuguer-animation.scss'],
    animations: fuseAnimations
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';
    login = '';
    isCadastroCompleto: boolean;

    /**
     * Constructor
     *
     * @param {FuseConfigService} fuseConfigService
     * @param {FormBuilder} formBuilder
     * @param route
     * @param router
     * @param authenticationService
     */
    constructor(
        private fuseConfigService: FuseConfigService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private validateBrService: ValidateBrService,
        protected snackBarService: SnackBarService,
        private pessoaService: PessoaService,
        @Inject(DOCUMENT) private document: Document,
        private translate: TranslateService
    ) {
        this.pessoaService.isCadastroCompleto().subscribe(
            data => this.isCadastroCompleto = data
        );
        // Configure the layout
        this.fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                } 
            }
        };

        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.loginForm = this.formBuilder.group({
            cpf: ['', [Validators.required, this.validateBrService.cpf]],
            password: ['', [Validators.required, Validators.minLength(6)]]
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    changeLang(key: string) {
        this.translate.use(key).subscribe(() => {
            this.translate.get('global.messages.translate-changed').subscribe((message) => {
                this.snackBarService.showSuccess(message);
            })
        });
    }

    // convenience getter for easy access to form fields
    get form(): any {
        return this.loginForm.controls;
    }

    onSubmit(): any {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.login = this.form.cpf.value.match(/\d+/g).join('');
        this.authenticationService
            .login(btoa(JSON.stringify({login: this.login, senha: this.form.password.value})))
            .pipe(switchMap( res =>
                this.authenticationService.getUser()
                .pipe(map( user => {
                    if (user.cadSimplificado) {
                        this.pessoaService.cadastroCompletoUser.next(true)
                    } else {
                        this.pessoaService.cadastroCompletoUser.next(false)
                    }
                    return user;
                })))
        )
            .subscribe(
                _ => {
                    this.getPessoa();
                },
                error => {
                    this.error = error;
                    this.snackBarService.showError(error);
                    this.loading = false;
                }
            );
    }

    private getPessoa(): void{
        // console.log('Current user decode',this.authenticationService.currentUserDecode );
        if (this.authenticationService.currentUserDecode != null) 
            this.pessoaService.getCadastroCompleto(this.authenticationService.currentUserDecode.sub)
                .subscribe(
                    _ => {
                        this.redirectUrl(this.authenticationService.redirectUrl);
                    },
                    error => {
                        this.loading = false;

                        console.log(error);
                    }
                );        
        else {
           throw Error('Não foi possivel obter informações do usuário');
        }
    }

    /***
     * Redireciona url absoluta (interna ou externa)
     * Ou faz o reverso da url de rota /{sistema}/#/{rota}  -> Url absoluta do subsistema
     */
    private redirectUrl(url: string) {
        if (url != null) {
            let urlSistemaSema = SistemaModuleInfoUtil.parseUrl(url);

            // se url de sistema do portal (absoluta)
            if (urlSistemaSema) { 
                   if (urlSistemaSema.artefatoInfo.name == 'portal' || urlSistemaSema.artefatoInfo.name == 'local')  {
                       // previne portal dentro do portal (navega rota portal)
                       return this.router.navigateByUrl( urlSistemaSema.rota, { replaceUrl: true} )
                           .then(_=>this.authenticationService.redirectUrl = null);
                   } 
                   return this.router.navigate(['/pages'], { queryParams: { _redirectUrl: urlSistemaSema.urlCompleta}})
                       .then(_=>this.authenticationService.redirectUrl = null); 
            } else {
               
                // url externa 
                let urlRota = SistemaModuleInfoUtil.reverseRouteToUrl(url);
                urlSistemaSema = SistemaModuleInfoUtil.parseUrl(urlRota );
                if (urlSistemaSema == null ) {
                   if (url && url.startsWith('http'))
                       this.document.defaultView.location.href = url;
                    
                   this.router.navigateByUrl('/dashboard');
                  
                } else {
                    // rota (convensão sobre configuração)
                    if (urlSistemaSema.artefatoInfo)
                       if (urlSistemaSema.artefatoInfo.name == 'portal' || urlSistemaSema.artefatoInfo.name == 'local')  {
                           // previne portal dentro do portal (navega rota portal)
                           return this.router.navigateByUrl( urlSistemaSema.rota )
                               .then(_=>this.authenticationService.redirectUrl = null);
                       } 
                   if (urlRota == null)
                       this.router.navigateByUrl('/dashboard');
   
                   return this.router.navigate(['/pages'], { queryParams: { _redirectUrl: encodeURIComponent(urlRota)}})
                       .then(_=>this.authenticationService.redirectUrl = null);
   
                   
                }
            }
        } else {
            this.router.navigateByUrl('/dashboard');
        }
        
  
     }
 
    
}
