
export class GrupoPerfilDTO {

    constructor(
        public idGrupo?: number,
        public idPerfil?: number,
        public descricaoPerfil?: string,
        public delegavel?: boolean,
        
    ) {}

    static fromJson(json: any): GrupoPerfilDTO {
        if (json === undefined || json === null){
            return null;
        }
        
        return new GrupoPerfilDTO(
            json.idGrupo, 
            json.idPerfil, 
            json.descricaoPerfil, 
            json.delegavel
        );
    }
}
