import {BaseModel} from 'app/main/shared/models/base.model';
import { Usuario } from 'app/main/pages/usuarios/usuario.model';

export class Imagem extends BaseModel {
    constructor(
        public id?: number,
        public nome?: string,
        public chave?: string,
        public usuario?: Usuario
    ) {
        super();
    }
    
    static fromJson(json: any): Imagem {
        if (json === undefined || json === null) {
            return null;
        }
        return new Imagem(
            json.id,
            json.nome,
            json.chave,
            Usuario.fromJson(json.usuario)
        );
    }
}
