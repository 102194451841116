import { HttpParams, HttpHeaders } from '@angular/common/http';

/**
 * Cria parametros da requisicao
 * @param req Objeto chave valor { page: 0, size: 10, sort: 'nome,asc' }
 */
export const createRequestOptions = (req?: any): HttpParams => {
    let options: HttpParams = new HttpParams();
    if (req) {
        Object.keys(req).forEach((key) => {
            if (key !== 'sort') {
                options = options.set(key, req[key]);
            }
        });
        if (req.sort) {
            req.sort.forEach((val) => {
                options = options.append('sort', val);
            });
        }
    }
    return options;
};
  