import {Component, Inject, Injector, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnackBarService} from '../../../shared/services/snack-bar/snack-bar.service';
import {Grupo} from '../grupo.model';
import { Usuario } from '../../../pages/usuarios/usuario.model';
import {BaseListComponent} from '../../../shared/components/base-list/base-list.component';
import {GrupoUsuariosListService} from '../grupo-usuarios-list.service';
import {HttpParams} from '@angular/common/http';
import { UsuarioGrupo } from '../grupo-detail/grupo-dados-usuarios-list/usuario-grupo.model';
import { GrupoUsuarioService } from 'app/main/core/services/grupo-usuario.service';

@Component({
  selector: 'app-dialog-adicionar-grupo-usuario',
  templateUrl: './dialog-adicionar-grupo-usuario.component.html',
  styleUrls: ['./dialog-adicionar-grupo-usuario.component.scss']
})

export class DialogAdicionarGrupoUsuarioComponent extends BaseListComponent<UsuarioGrupo> implements OnInit {

    formDialogAdicionar: FormGroup;
    IsWait = false;
    grupo: Grupo;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public formBuilder: FormBuilder,
                public grupoUsuarioService: GrupoUsuarioService,
                public dialogRef: MatDialogRef<DialogAdicionarGrupoUsuarioComponent>,
                protected snackBarService: SnackBarService,
                injector: Injector,
                public grupoUsuariosService: GrupoUsuariosListService) {
        super(injector, ['nome', 'cpf', 'acao'] , grupoUsuariosService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.paginator.pageSize = 50;
        this.formDialogAdicionar = this.formBuilder.group({
            pessoaIdentificacao: [null, [Validators.required, Validators.maxLength(255)]]
        });
    }

    fillFilters(httpParams: HttpParams): HttpParams {
        const pessoaIdentificacao = this.formDialogAdicionar.get('pessoaIdentificacao').value;
        if (typeof pessoaIdentificacao !== 'undefined' && pessoaIdentificacao.length > 0){
            httpParams = httpParams.set('idGrupo', this.data.grupo.id);
            httpParams = httpParams.set('descricao', pessoaIdentificacao ? pessoaIdentificacao : '');
            httpParams = httpParams.set('isPesquisaParaInsercao', '' + true);
        }
        return httpParams;
    }

    finalizarSelecao(usuarioGrupo: UsuarioGrupo): void {
        this.IsWait = true;
        const login = this.formDialogAdicionar.get('pessoaIdentificacao').value;
        const usuario: Usuario = new Usuario(usuarioGrupo.usuarioId);
        const grupo: Grupo = new Grupo(this.data.grupo.id);
        this.grupoUsuarioService.addGrupoToUsuario(grupo, usuario).subscribe(
            () => {
                this.IsWait = false;
                this.snackBarService.showSuccess('Usuário adicionado ao grupo.');
                this.dialogRef.close(true);
            },
            (e) => {
                this.IsWait = false;
                this.snackBarService.showError('Não foi possível adicionar o usuário ao grupo.', e);
            }
        );
    }

    getCpfCnpjMask(cpf_cnpj: string): string {
        return this.isCpf(cpf_cnpj) ? '000.000.000-009' : '00.000.000/0000-00';
    }

    isCpf(cpf_cnpj: string): boolean {
        return cpf_cnpj == null ? true : cpf_cnpj.length < 12 ? true : false;
    }

    pesquisar(){
        this.listUsuarioByNameOrCpf(0);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}
