import { PerfilService } from './../../perfis/perfil.service';
import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { Validators } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { BaseDetailComponent } from 'app/main/shared/components/base-detail/base-detail.component';
import { UsuarioService } from '../usuario.service';
import { Usuario } from '../usuario.model';
import { PerfilUsuarioDTO } from 'app/main/core/models/perfil-usuario-dto.model';
import { PerfilUsuarioService } from 'app/main/core/services/perfil-usuario.service';
import { Perfil } from '../../perfis/perfil.model';
import { ConfirmarExclusaoDialogComponent } from 'app/main/shared/components/confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import { Grupo } from '../../grupos/grupo.model';
import { GrupoUsuarioService } from 'app/main/core/services/grupo-usuario.service';
import { GrupoService } from '../../grupos/grupo.service';
import { GrupoUsuarioDTO } from 'app/main/core/models/grupo-usuario-dto.model';

@Component({
    selector: 'app-usuario-detail',
    templateUrl: './usuario-detail.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations
})
export class UsuarioDetailComponent extends BaseDetailComponent<Usuario> {
    
    usuario = new Usuario();
    perfisUsuario: PerfilUsuarioDTO[];
    gruposUsuario: PerfilUsuarioDTO[];
    perfis: Perfil[];
    grupos: Grupo[];
    perfil: Perfil;
    grupo: Grupo;
    perfisColumns = ['descricao', 'ativo'];
    gruposColumns = ['nome', 'descricao', 'ativo'];

    constructor(
        protected usuarioService: UsuarioService,
        protected perfilUsuarioService: PerfilUsuarioService,
        protected grupoUsuarioService: GrupoUsuarioService,
        protected perfilService: PerfilService,
        protected grupoService: GrupoService,
        protected injector: Injector
    ) {
        super(injector, new Usuario(), usuarioService, Usuario.fromJson);
    }

    afterResourceLoad(): void {
        this.loadPerfisUsuario();
        this.loadGruposUsuario();
        this.loadPerfisAtivosDisponiveisUsuario();
        this.loadGrupos();
    }

    protected buildResourceForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            nome: [
                null,
                [
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(200)
                ]
            ],
            email: [
                null,
                [
                    Validators.required,
                    Validators.maxLength(100),
                    Validators.email
                ]
            ],
            login: [null, [Validators.required]],
            dataInicio: [null],
            dataConfirmacao: [null]
        });
    }

    public loadPerfisUsuario(): void {
        this.perfilUsuarioService
            .getAllByUsuario(this.resource)
            .subscribe(perfisUsuario => {
                this.perfisUsuario = perfisUsuario;
            });
    }

    public loadGruposUsuario(): void {
        this.grupoUsuarioService
            .getAllByUsuario(this.resource)
            .subscribe(gruposUsuario => {
                this.gruposUsuario = gruposUsuario;
            });
    }

    public loadPerfisAtivosDisponiveisUsuario(): void {
        this.perfilService.getPerfisAtivosDisponiveisUsuario(this.resource).subscribe(perfis => {
            this.perfis = perfis;
        });
    }

    public loadGrupos(): void {
        this.grupoService.getAll().subscribe(grupos => {
            this.grupos = grupos;
        });
    }

    public get hasPerfisUsuario(): boolean {
        return (
            typeof this.perfisUsuario !== 'undefined' &&
            this.perfisUsuario.length > 0
        );
    }

    public get hasGruposUsuario(): boolean {
        return (
            typeof this.gruposUsuario !== 'undefined' &&
            this.gruposUsuario.length > 0
        );
    }

    addPerfil(): void {
        if (typeof this.perfil !== 'undefined' && this.perfil !== null){
            this.perfilUsuarioService
            .addPerfilToUsuario(this.perfil, this.resource)
            .subscribe(
                () => {
                    this.snackBarService.showSuccess('Perfil adicionado com sucesso!');
                    this.perfil = null;
                    this.loadPerfisUsuario();
                },
                (e) =>
                    this.snackBarService.showError('Erro ao adicionar perfil.', e)
            );
        }else{
            this.snackBarService.showAlert('Selecione o perfil a ser adicionado.');
        }
    }

    addGrupo(): void {
        if (typeof this.grupo !== 'undefined' && this.grupo !== null){
            this.grupoUsuarioService
            .addGrupoToUsuario(this.grupo, this.resource)
            .subscribe(
                () => {
                    this.snackBarService
                    .showSuccess('Grupo adicionado com sucesso!');
                    this.grupo = null;
                    this.loadGruposUsuario();
                },
                (e) =>
                    this.snackBarService
                    .showError('Erro ao adicionar grupo.',e)
            );
        }else{
            this.snackBarService.showAlert('Selecione o grupo, a ser adicionado.' );
        }
    }

    removerPerfil(perfilUsuarioDTO: PerfilUsuarioDTO): void {
        this.perfilUsuarioService
            .removePerfilFromUsuario(perfilUsuarioDTO)
            .subscribe(
                () => {
                    this.snackBarService.showSuccess('Perfil excluído com sucesso!');
                    this.loadPerfisUsuario();
                    //Bugfix 8163 - Atualizando lista de perfis ativos para o usuario após a remoção de um perfil.
                    this.loadPerfisAtivosDisponiveisUsuario();
                },
                (e) =>
                    this.snackBarService.showError('Erro ao excluir perfil.',e)
            );
    }

    removerGrupo(grupoUsuarioDTO: GrupoUsuarioDTO): void {
        this.grupoUsuarioService
            .removeGrupoFromUsuario(grupoUsuarioDTO)
            .subscribe(
                () => {
                    this.snackBarService.showSuccess('Grupo excluído com sucesso!');
                    this.loadGruposUsuario();
                },
                (e) =>
                    this.snackBarService.showError('Erro ao excluir grupo.',e)
            );
    }

    public removePerfilDialog(perfilUsuarioDTO: PerfilUsuarioDTO): void {
        const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: { label: perfilUsuarioDTO.perfil.descricao }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.removerPerfil(perfilUsuarioDTO);
            }
        });
    }

    public removeGrupoDialog(grupoUsuarioDTO: GrupoUsuarioDTO): void {
        const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: { label: grupoUsuarioDTO.grupo.descricao }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.removerGrupo(grupoUsuarioDTO);
            }
        });
    }

    protected editionPageTitle(): string {
        const resourceName = this.resource.nome || '';
        return 'Editando o usuário: ' + resourceName;
    }

    protected visualizationPageTitle(): string {
        const resourceName = this.resource.nome || '';
        return 'Visualizando o usuário: ' + resourceName;
    }
}
