import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {fuseAnimations} from '@fuse/animations';
import {FuseConfigService} from '@fuse/services/config.service';
import {AccessService} from '../access.service';
import {Router} from '@angular/router';
import {ValidateBrService} from 'angular-validate-br';
import { SnackBarService } from 'app/main/shared/services/snack-bar/snack-bar.service';

@Component({
    selector: 'forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ForgotPasswordComponent implements OnInit {
    forgotPasswordForm: FormGroup;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private accessService: AccessService,
        private validateBrService: ValidateBrService,
        private router: Router,
        protected snackBarService: SnackBarService
    ) {
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    ngOnInit(): void {
        this.forgotPasswordForm = this._formBuilder.group({
            cpf: ['', [Validators.required, this.validateBrService.cpf]]
        });
    }

    onSubmit(): void {
        if (this.forgotPasswordForm.invalid) {
            return;
        }

        const cpf = this.forgotPasswordForm.value.cpf.match(/\d+/g).join('');

        this.accessService.forgot(cpf).subscribe((email) => {
            this.router.navigate(['/auth/forgot-confirm'], {
                queryParams: {email: email.email}
            });
            }, error => {
                this.snackBarService.showError(error);
            }
        );
    }

}
