import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {SnackBarService} from '../../../../shared/services/snack-bar/snack-bar.service';
import {RepresentanteService} from '../../../../shared/services/representante.service';
import {PessoaJuridicaService} from '../../../../shared/services/pessoa-juridica.service';
import {PessoaJuridica} from '../../../../shared/models/pessoa-juridica.model';
import {BaseModel} from '../../../../shared/models/base.model';
import {ConfirmarExclusaoDialogComponent} from '../../../../shared/components/confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {PessoaFisica} from '../../../../core/models/pessoa-fisica.model';
import {DialogEditarVinculoPfPjComponent} from "./dialog-editar-vinculo-pf-pj/dialog-editar-vinculo-pf-pj.component";
import {Representante} from "../../../../shared/models/representante.model";

@Component({
    selector: 'app-empresas',
    templateUrl: './empresas.component.html',
    styleUrls: ['./empresas.component.scss']
})
export class EmpresasComponent implements OnInit {
    @Output() eventBack = new EventEmitter();
    @Input() pessoaFisica: PessoaFisica;

    cnpjControl: FormControl;
    pessoaJuridica: PessoaJuridica;
    tipoVinculoControl: FormControl;
    tiposRepresentantes = Representante.tiposRepresentante;
    representantes: Representante[];

    comparador = (a: BaseModel, b: BaseModel) =>
        a && b ? a.id === b.id : a === b

    constructor(
        private snackBarService: SnackBarService,
        private responsavelService: RepresentanteService,
        private pessoaJuridicaService: PessoaJuridicaService,
        protected dialog: MatDialog
    ) {
        this.cnpjControl = new FormControl(null, Validators.required);
        this.tipoVinculoControl = new FormControl(null, Validators.required);
    }

    ngOnInit(): void {
        this.loadEmpresasVinculadas();
    }

    public back(): void {
        this.eventBack.emit();
    }

    limparCampos(): void {
        this.pessoaJuridica = null;
        this.cnpjControl.reset();
        this.tipoVinculoControl.reset();
    }

    public pesquisar(): void {
        this.cnpjControl.markAllAsTouched();
        if (this.cnpjControl.valid) {
            if (this.representantes.some(item => item.representado.cpfCnpj === this.cnpjControl.value)){
                this.snackBarService.showAlert('Você já é representante dessa empresa');
            }else{
                this.pessoaJuridicaService.getByCnpj(this.cnpjControl.value).subscribe(result => {
                    this.pessoaJuridica = result;
                }, e => this.snackBarService.showError('CNPJ não encontrado', e));
            }
        } else {
            this.snackBarService.showAlert('Digite o CNPJ corretamente');
        }
    }

    public addRepresentante(): void {
        this.tipoVinculoControl.markAllAsTouched();
        if (this.tipoVinculoControl.valid) {
            this.responsavelService
                .create(Representante.fromJson({
                    tipoRepresentante: this.tipoVinculoControl.value,
                    representanteId: this.pessoaFisica.id,
                    representadoId: this.pessoaJuridica.pessoa.id
                })).subscribe(
                () => {
                    this.snackBarService.showSuccess('Resposável  adicionado com sucesso!');
                    this.loadEmpresasVinculadas();
                    this.limparCampos();
                },
                error => {
                    this.snackBarService.showError('Erro ao adicionar resposável.', error);
                }
            );
        }
    }

    public removeRepresentanteDialog(responsavel: Representante): void {

        const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: {label: responsavel.representado.nomeFantasia}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.responsavelService.desativaRepresentante(responsavel.representado.id, this.pessoaFisica.id).subscribe(
                    () => {
                        this.snackBarService.showSuccess('Representante desabilitado com sucesso!');
                        this.loadEmpresasVinculadas();
                    },
                    (e) => this.snackBarService.showError('Erro ao desabilitar representante.', e)
                );
            }
        });
    }

    habilitarDesabilitarResponsavel(item: Representante): void {
        if (item.ativo) {
            this.removeRepresentanteDialog(item);
        } else {
            this.responsavelService.ativaRepresentante(item.representado.id, this.pessoaFisica.id).subscribe(() => {
                this.loadEmpresasVinculadas();
            }, e => this.snackBarService.showError(e));
        }
    }

    loadEmpresasVinculadas(): void {
        this.responsavelService.getAllByRepresentante(this.pessoaFisica.id).subscribe(result => {
            this.representantes = result;
        });
    }

    editarVinculo(item: Representante): void {
        const dialogRef = this.dialog.open(DialogEditarVinculoPfPjComponent, {data: Object.assign( new Representante(), item)});
        dialogRef.afterClosed().subscribe(result => {
            if (result){
                this.loadEmpresasVinculadas();
            }
        });
    }
}
