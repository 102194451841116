import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from 'environments/environment';
import {DadosDocumentoVerificado} from '../../pages/verificar-documento/dados-documento-verificado.model';

@Injectable({
    providedIn: 'root'
})
export class DocumentoProcessoLicenciamentoService extends BaseService<DadosDocumentoVerificado> {
    constructor(protected injector: Injector) {
        super(
            '',
            injector,
            DadosDocumentoVerificado.prototype,
            DadosDocumentoVerificado.fromJson
        );
        this.urlResource = environment.URL_LICENCIAMENTO_API;
        this.urlResource += '/api/documento-processo';
    }

    verificarDocumento(codVerificador: string, codCRC: string, captcha: string): Observable<DadosDocumentoVerificado> {
        const url = `${this.urlResource}/verificar-documento`;
        return this.http
            .post(url, {codVerificador: codVerificador, codCRC: codCRC, captchaResponse: captcha})
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    readPDFByChave(chave: string): Observable<Blob> {
        const url = `${this.urlResource}/${chave}/pdf`;
        return this.http.get(url, {responseType: 'blob'}).pipe(
            map((res) => {
                    return new Blob([res], {type: 'application/pdf'});
                }
            ));
    }

}
