import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material';
import {environment} from 'environments/environment';
import {ConfirmarExclusaoDialogComponent} from '../confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import {ActivatedRoute} from '@angular/router';
import {TipoLogradouro} from 'app/main/core/models/tipo-logradouro.model';
import {Municipio} from 'app/main/core/models/municipio.model';
import {TipoLogradouroService} from 'app/main/core/services/tipo-logradouro.service';
import {MunicipioService} from 'app/main/core/services/municipio.service';
import {Endereco} from 'app/main/core/models/endereco.model';
import {EnderecoService} from 'app/main/core/services/endereco.service';
import {AuthenticationService} from '../../services/authentication.service';
import {BaseEnum} from '../../models/base.enum ';
import {Contato} from 'app/main/core/models/contatos.model';
import {PessoaService} from 'app/main/core/services/pessoa.service';
import {CurrentUser} from '../../models/current-user.model';
import {ENUM_UF_VALUES, EnumUf} from '../../models/uf.enum';
import {ViacepService} from '../../services/viacep.service';
import {CepDTO} from '../../models/cep-dto';
import {ApplicationStateService} from 'app/main/core/services/application-state.service';
import {SnackBarService} from '../../services/snack-bar/snack-bar.service';

@Component({
    selector: 'app-endereco-wizard',
    templateUrl: './endereco-wizard.component.html',
    styleUrls: ['./endereco-wizard.component.scss']
})
export class EnderecoWizardComponent implements OnInit {
    @Output() eventNext = new EventEmitter();
    @Output() eventBack = new EventEmitter();
    currentUser: CurrentUser;
    ufValues = ENUM_UF_VALUES;
    enderecoForm: FormGroup;
    enderecos: Endereco[];
    municipios: Municipio[];
    tiposLogradouro: TipoLogradouro[];
    contato: Contato;

    compareWithTipoLogradouro = TipoLogradouro.compareWith;
    compareWithMunicipio = Municipio.compareWith;

    columnDefinitions = [
        {coluna: 'tipo', showMobile: true},
        {coluna: 'logradouro', showMobile: true},
        {coluna: 'numero', showMobile: false},
        {coluna: 'bairro', showMobile: false},
        {coluna: 'complemento', showMobile: false},
        {coluna: 'municipio', showMobile: false},
        {coluna: 'cep', showMobile: false},
        {coluna: 'ativo', showMobile: true}
    ];

    constructor(
        private pessoaService: PessoaService,
        protected tipoLogradouroService: TipoLogradouroService,
        protected municipioService: MunicipioService,
        protected enderecoService: EnderecoService,
        protected formBuilder: FormBuilder,
        protected snackBarService: SnackBarService,
        protected dialog: MatDialog,
        protected activatedRoute: ActivatedRoute,
        private authenticationService: AuthenticationService,
        private viacep: ViacepService,
        private applicationStateService: ApplicationStateService
    ) {
    }

    ngOnInit(): void {
        this.buildEnderecoForm();
        this.getUsuario();
        this.loadMunicipios(environment.UF_PADRAO);
        this.loadTiposLogradouro();
    }

    getDisplayedColumns(): string[] {
        return this.applicationStateService.getDisplayedColumns(
            this.columnDefinitions
        );
    }

    getUsuario(): void {
        this.currentUser = this.authenticationService.currentUserDecode;
        this.loadResource();
    }

    protected loadResource(): void {
        this.pessoaService
            .getByPessoaFisicaCpf(this.currentUser.sub)
            .subscribe(response => {
                this.contato = response.contato;
                this.loadEnderecos();
            });
    }

    public loadTiposLogradouro(): void {
        this.tipoLogradouroService
            .getAll()
            .subscribe(tipos => {
                this.tiposLogradouro = tipos;
            });
    }

    public loadMunicipios(uf: string): void {
        this.municipioService
            .getAllByUf(uf)
            .subscribe(municipios => {
                this.municipios = municipios;
            });
    }

    public loadEnderecos(): void {
        this.enderecoService
            .getAllByContatoId(this.contato.id)
            .subscribe(enderecos => {
                this.enderecos = enderecos;
                if (enderecos.length > 0) {
                    this.enderecoForm.get('tipo').enable();
                }
            });
    }

    public buildEnderecoForm(): void {
        this.enderecoForm = this.formBuilder.group({
            id: [null],
            bairro: [
                null,
                [
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(200)
                ]
            ],
            cep: [null, [Validators.required, Validators.maxLength(10)]],
            complemento: [null, [Validators.maxLength(200)]],
            logradouro: [
                null,
                [
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(200)
                ]
            ],
            numero: [null, [Validators.required, Validators.maxLength(7)]],
            municipio: [null, [Validators.required]],
            uf: [environment.UF_PADRAO, [Validators.required]],
            tipoLogradouro: [null, [Validators.required]],
            tipo: [
                {value: 'PRINCIPAL', disabled: true},
                [Validators.required]
            ],
            contato: [null]
        });
    }

    public addEndereco(): void {
        this.enderecoForm.markAllAsTouched();
        if (this.enderecoForm.valid) {
            const id: number = this.enderecoForm.get('id').value;
            const tipo: string = this.enderecoForm.get('tipo').value;

            const enderecosTemp =
                this.enderecos !== undefined
                    ? this.enderecos.filter(
                    e => e.tipo === 'PRINCIPAL' && e.id !== id
                    )
                    : null;

            if (
                enderecosTemp !== null &&
                enderecosTemp.length > 0 &&
                tipo === 'PRINCIPAL'
            ) {
                this.snackBarService.showAlert('Já existe um endereço principal Adicionado.');
                return;
            }

            this.enderecoForm.get('tipo').enable();
            if (this.enderecoForm.get('id').value == null) {
                this.enderecoForm.patchValue({contato: this.contato});

                this.enderecoService
                    .create(Endereco.fromJson(this.enderecoForm.value))
                    .subscribe(
                        () => {
                            this.snackBarService.showSuccess('Endereço adicionado com sucesso!');
                            this.loadEnderecos();
                        },
                        (e) => {
                            this.snackBarService.showError('Erro ao adicionar endereço.', e);
                            this.enderecoForm.get('tipo').disable();
                        }
                    );
            } else {
                this.enderecoService
                    .update(Endereco.fromJson(this.enderecoForm.value))
                    .subscribe(
                        () => {
                            this.snackBarService.showSuccess('Endereço atualizado com sucesso!');
                            this.loadEnderecos();
                        },
                        (e) => {
                            this.snackBarService.showError('Erro ao alterar endereço.', e);
                            this.enderecoForm.get('tipo').disable();
                        }
                    );
            }
            this.limparForms();
        }
    }

    get textoAdicionarGravar(): string {
        return this.enderecoForm.get('id').value == null
            ? 'Adicionar'
            : 'Gravar';
    }

    public editEndereco(endereco: Endereco): void {
        this.enderecoService.getById(endereco.id).subscribe(e => {
            this.enderecoForm.patchValue(Endereco.fromJson(e));
            this.enderecoForm.patchValue({uf: e.municipio.uf});
            this.loadMunicipios(e.municipio.uf);
            this.enderecoForm.get('tipo').disable();
        });
    }

    public removeEnderecoDialog(endereco: Endereco): void {
        if (endereco.tipo === 'PRINCIPAL') {
            this.snackBarService.showAlert('Obrigatório possuir um endereço principal!');
            return;
        } else {
            const dialogRef = this.dialog.open(
                ConfirmarExclusaoDialogComponent,
                {
                    width: '300px',
                    data: {label: endereco.logradouro}
                }
            );

            dialogRef.afterClosed().subscribe(result => {
                if (!!result) {
                    this.removeEndereco(endereco);
                }
            });
        }
    }

    public removeEndereco(endereco: Endereco): void {
        this.enderecoService.removeEndereco(endereco).subscribe(
            () => {
                this.snackBarService.showSuccess('Endereço excluído com sucesso!');
                this.loadEnderecos();
            },
            (e) => this.snackBarService.showError('Erro ao excluir endereço.', e)
        );
        this.limparForms();
    }

    public get hasEnderecos(): boolean {
        return (
            typeof this.enderecos !== 'undefined' && this.enderecos.length > 0
        );
    }

    public limparForms(): void {
        this.enderecoForm.reset();
        this.enderecoForm.get('tipo').enable();
        this.enderecoForm.patchValue({uf: environment.UF_PADRAO});
        this.loadMunicipios(environment.UF_PADRAO);
    }

    get tiposEnderecos(): BaseEnum[] {
        return Endereco.tiposEnderecos;
    }

    getUfTexto(tipo: string): string {
        return EnumUf[tipo];
    }

    onSelectMunicipio(event: any): void {
        this.municipios = [];
        if (event.value !== undefined) {
            this.loadMunicipios(event.value);
        }
    }

    pesquisarCep(): void {
        const cep = this.enderecoForm.get('cep').value;

        if (cep !== undefined) {
            this.viacep.getCep(cep)
                .subscribe(c => {
                        this.preencherEndereco(c);
                    },
                    e => this.snackBarService.showError(e.errors[0])
                );
        }
    }

    private preencherEndereco(cepDTO: CepDTO): void {
        this.enderecoForm.patchValue({logradouro: cepDTO.logradouro});
        this.enderecoForm.patchValue({complemento: cepDTO.complemento});
        this.enderecoForm.patchValue({bairro: cepDTO.bairro});
        this.enderecoForm.patchValue({municipio: cepDTO.municipio});
        this.enderecoForm.patchValue({uf: cepDTO.municipio.uf});
        this.loadMunicipios(cepDTO.municipio.uf);
    }

    public get isSomenteVisualizacao(): boolean {
        return this.activatedRoute.snapshot.url[1].path === 'detail';
    }

    public next(): void {
        if (this.hasEnderecos) {
            this.eventNext.emit();
        } else {
            this.snackBarService.showAlert('Adicione no mínimo um endereço.');
        }
    }

    public back(): void {
        this.eventBack.emit();
    }

}
