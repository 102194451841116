import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CoreModule} from '../../core/core.module';
import {SharedModule} from '../../shared/shared.module';
import {GrupoDetailComponent} from './grupo-detail/grupo-detail.component';
import {GrupoListResolver, GrupoResolver} from './grupo.service';
import {GrupoListComponent} from './grupo-list/grupo-list.component';
import {AuthGuardService} from '../../shared/services/auth-guard.service';
import {AuthGuardChildService} from '../../shared/services/auth-guard-child.service';
import { GrupoDadosUsuariosListComponent } from './grupo-detail/grupo-dados-usuarios-list/grupo-dados-usuarios-list.component';
import { DialogRemoverPermissaoUsuarioComponent } from './dialog-remover-permissao-usuario/dialog-remover-permissao-usuario.component';
import { DialogAdicionarGrupoUsuarioComponent } from './dialog-adicionar-grupo-usuario/dialog-adicionar-grupo-usuario.component';
import { MatTooltipModule } from '@angular/material';

export const routes: Routes = [
    {
        path: "new",
        component: GrupoDetailComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: "grupos",
        component: GrupoListComponent,
        resolve: {
            data: GrupoListResolver
        }
    },
    {
        path: ":id/edit",
        canActivateChild: [AuthGuardChildService],
        component: GrupoDetailComponent,
        resolve: {
            data: GrupoResolver
        }
    },
    {
        path: ":id/detail",
        canActivateChild: [AuthGuardChildService],
        component: GrupoDetailComponent,
        resolve: {
            data: GrupoResolver
        }
    }
];

@NgModule({
    declarations: [
        GrupoListComponent, 
        GrupoDetailComponent,
        GrupoDadosUsuariosListComponent,
        DialogRemoverPermissaoUsuarioComponent,
        DialogAdicionarGrupoUsuarioComponent
    ],
    imports: [MatTooltipModule, CoreModule, SharedModule, RouterModule.forChild(routes)],
    entryComponents: [
        GrupoDetailComponent, 
        GrupoListComponent,
        DialogRemoverPermissaoUsuarioComponent,
        DialogAdicionarGrupoUsuarioComponent
    ],
    providers: [GrupoResolver, GrupoListResolver]
})
export class GruposModule {}