import {NgModule} from '@angular/core';
import {MatDialogModule} from '@angular/material';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {CommonModule} from '@angular/common';
import {CoreExcludeDialogComponent} from './core-exclude-dialog.component';

@NgModule({
    imports: [CommonModule, MatDialogModule, MatButtonModule, MatIconModule],
    declarations: [CoreExcludeDialogComponent],
    exports: [CoreExcludeDialogComponent],
    entryComponents: [CoreExcludeDialogComponent]
})
export class CoreComponentsModule {}
