import { HttpParams } from '@angular/common/http';
import { Component, ViewEncapsulation, Injector } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { BaseListComponent } from 'app/main/shared/components/base-list/base-list.component';
import { UsuarioService } from '../usuario.service';
import { Usuario } from '../usuario.model';

@Component({
    selector: 'app-usuario-list',
    templateUrl: './usuario-list.component.html',
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class UsuarioListComponent extends BaseListComponent<Usuario> {
    descricao = '';

    constructor(injector: Injector, service: UsuarioService) {
        super(injector, ['id', 'login', 'nome', 'email', 'buttons'], service);
    }

    fillFilters(httpParams: HttpParams): any {
        if (httpParams == null) {
            httpParams = new HttpParams();
        }

        httpParams = httpParams.append('descricao', this.descricao);
        return httpParams;
    }
}
