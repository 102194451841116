import {Injectable} from '@angular/core';
import {Profissao} from "../models/profissao.model";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../../environments/environment";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class ProfissaoService {
    private urlResource: string = environment.URL_SCORP_API + '/api/profissoes';

    constructor(
        private http: HttpClient
    ) {
    }

    getAllProfissao(): Observable<Profissao[]> {
        return this.http
            .get(this.urlResource)
            .pipe(
                map(response => this.jsonToResources(response)),
                catchError(this.handleError.bind(this))
            );
    }

    protected jsonToResources(json: any): Profissao[] {
        const resources: Profissao[] = [];
        if (json !== undefined && json !== null) {
            json.forEach((e: Profissao) => resources.push(Profissao.fromJson(e)));
        }
        return resources;
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }
}
