import {BaseModel} from 'app/main/shared/models/base.model';
import {Municipio} from './municipio.model';
import {TipoLogradouro} from './tipo-logradouro.model';
import {Contato} from './contatos.model';
import {BaseEnum} from 'app/main/shared/models/base.enum ';

export class Endereco extends BaseModel {
    constructor(
        public id?: number,
        public cep?: string,
        public bairro?: string,
        public complemento?: string,
        public logradouro?: string,
        public numero?: string,
        public municipio?: Municipio,
        public tipoLogradouro?: TipoLogradouro,
        public tipo?: string,
        public contato?: Contato
    ) {
        super();
    }

    static tiposEnderecos: BaseEnum[] = [
        {valor: 'PRINCIPAL', descricao: 'Principal'},
        {valor: 'CORRESPONDENCIA', descricao: 'Correspondência'}
    ];

    static fromJson(json: any): Endereco {
        if (json === undefined || json === null) {
            return null;
        }
        return new Endereco(
            json.id,
            json.cep ? json.cep.match(/\d+/g).join('') : null,
            json.bairro,
            json.complemento,
            json.logradouro,
            json.numero,
            json.municipio,
            TipoLogradouro.fromJson(json.tipoLogradouro),
            json.tipo,
            Contato.fromJson(json.contato)
        );
    }
}
