import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Endereco} from '../models/endereco.model';
import {environment} from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class EnderecoService extends BaseService<Endereco> {

    constructor(protected injector: Injector) {
        super('', injector, Endereco.prototype, Endereco.fromJson);
        this.urlResource = environment.URL_SCORP_API;
        this.urlResource += '/api/enderecos';
    }

    getById(id: number): Observable<Endereco> {
        const url = `${this.urlResource}/${id}`;
        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    getAllByContatoId(contatoId: number): Observable<Endereco[]> {
        const url = `${this.urlResource}/contato/${contatoId}`;

        return this.http
            .get(url)
            .pipe(
                map(response => this.jsonToResources(response)),
                catchError(this.handleError.bind(this))
            );
    }

    removeEndereco(endereco: Endereco): Observable<any> {
        const url = `${this.urlResource}/${endereco.id}`;
        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }
}
