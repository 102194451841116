import { OperacaoMenu } from "../menus/operacao-menu.model";

export class SistemaModuloDTO {
    constructor(
        public idPerfil?: number,
        public idMenu?: number,
        public idModulo?: number,
        public menuDescricao?: string,
        public sistemaDescricao?: string,
        public moduloDescricao?: string,
        public operacoes?: OperacaoMenu[],
        public operacoesPerfil?: OperacaoMenu[]
    ) {
    }

    static fromJson(json: any): SistemaModuloDTO {
        if (json === undefined || json === null) {
            return null;
        }

        return new SistemaModuloDTO(
            json.idPerfil,
            json.idMenu,
            json.idModulo,
            json.menuDescricao,
            json.sistemaDescricao,
            json.moduloDescricao,
            json.operacoes,
            json.operacoesPerfil,
        );
    }

    static fromJsons(jsonArray: any): SistemaModuloDTO[] {
        const resources = [];
        if (jsonArray === undefined || jsonArray === null) {
            return [];
        } else {
            jsonArray.forEach(json => resources.push(SistemaModuloDTO.fromJson(json)));
            return resources;
        }
    }
}


