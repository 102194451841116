import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {VerificarDocumentoComponent} from './verificar-documento.component';
import {RouterModule, Routes} from '@angular/router';
import {FlexModule} from '@angular/flex-layout';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatGridListModule} from '@angular/material/grid-list';
import {LoadingModule} from '../../shared/components/loading/loading.module';
import {environment} from '../../../../environments/environment';
import { NgHcaptchaModule } from 'ng-hcaptcha';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: '/verificar-documento'
    },
    {
        path: 'verificar-documento',
        component: VerificarDocumentoComponent
    }
];

@NgModule({
    declarations: [VerificarDocumentoComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FlexModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatIconModule,
        FormsModule,
        MatGridListModule,
        LoadingModule,
        NgHcaptchaModule.forRoot({
            siteKey: environment.SITE_KEY,
            languageCode: 'pt'
        })
         
    ]
})
export class VerificarDocumentoModule {
}
