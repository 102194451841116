import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot
} from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { Constants } from '../utils/constants';

export class DefaultGuardService {
    cadastroCompleto: boolean;

    constructor(
        private router: Router,
        public authenticationService: AuthenticationService
    ) {}

    protected isAuthenticated(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): any {
        const currentUser = this.authenticationService.currentUserValue;
        if (!currentUser) {
            this.router.navigate(['/login'], {
                queryParams: { returnUrl: state.url }
            });
            return false;
        }
        return true;
    }

    protected isAuthenticatedAndCadastroCompleto(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): any {
        const currentUser = this.authenticationService.currentUserValue;
        if (!currentUser) {
            this.router.navigate(['/login'], {
                queryParams: { returnUrl: state.url }
            });
            return false;
        }

        this.authenticationService.cadastroCompleto.subscribe(
            data => {
                if (!data){
                    this.router.navigate([Constants.PATH_WIZARD]);
                }
                return data;
            }
        );
        
        return true;
    }
}
