import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material';
import {ConfirmarExclusaoDialogComponent} from '../confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import {EmailService} from 'app/main/core/services/email.service';
import {Telefone} from 'app/main/core/models/telefone.model';
import {TelefoneService} from 'app/main/core/services/telefone.service';
import {AuthenticationService} from '../../services/authentication.service';
import {Contato} from 'app/main/core/models/contatos.model';
import {BaseEnum} from '../../models/base.enum ';
import {CurrentUser} from '../../models/current-user.model';
import {PessoaService} from 'app/main/core/services/pessoa.service';
import {Email} from 'app/main/core/models/email.model';
import { SnackBarService } from '../../services/snack-bar/snack-bar.service';

@Component({
    selector: 'app-contato-wizard',
    templateUrl: './contato-wizard.component.html',
    styleUrls: ['./contato-wizard.component.scss']
})
export class ContatoWizardComponent implements OnInit {
    @Output() eventNext = new EventEmitter();
    @Output() eventBack = new EventEmitter();
    maskCell = '(00) 0000-00009';
    telefoneForm: FormGroup;
    telefones: Telefone[];
    telefonesColumns = ['tipo', 'ddi', 'ddd', 'numero', 'ativo'];
    emailForm: FormGroup;
    emailsColumns = ['tipo', 'email', 'ativo'];
    emails: Email[];
    currentUser: CurrentUser;
    contato: Contato;
    activatedRoute: any;
    isSomenteVisualizacao = false;

    constructor(
        private pessoaService: PessoaService,
        private telefoneService: TelefoneService,
        private emailService: EmailService,
        private formBuilder: FormBuilder,
        private snackBarService: SnackBarService,
        private dialog: MatDialog,
        private authenticationService: AuthenticationService
    ) {
    }

    ngOnInit(): void {
        this.buildEmailForm();
        this.buildTelefoneForm();
        this.getUsuario();
    }

    getUsuario(): void {
        this.currentUser = this.authenticationService.currentUserDecode;
        this.loadResource();
    }

    protected loadResource(): void {
        this.pessoaService
            .getByPessoaFisicaCpf(this.currentUser.sub)
            .subscribe(response => {
                this.contato = response.contato;
                this.loadEmails();
                this.loadTelefones();
            });
    }

    public buildEmailForm(): void {
        this.emailForm = this.formBuilder.group({
            id: [null],
            email: [
                null,
                [
                    Validators.required,
                    Validators.maxLength(100),
                    Validators.email
                ]
            ],
            tipo: [null, [Validators.required]],
            contato: [null]
        });
    }

    public buildTelefoneForm(): void {
        this.telefoneForm = this.formBuilder.group({
            id: [null],
            ddd: [null, [Validators.required, Validators.maxLength(4)]],
            ddi: ['55', [Validators.required, Validators.maxLength(4)]],
            tipo: [null, [Validators.required]],
            numero: [
                null,
                [
                    Validators.required,
                    Validators.compose([
                        Validators.required,
                        Validators.minLength(9),
                        Validators.maxLength(10)
                    ])
                ]
            ],
            contato: [null]
        });
    }

    public loadTelefones(): void {
        this.telefoneService
            .getAllByContatoId(this.contato.id)
            .subscribe(telefones => {
                this.telefones = telefones;
            });
    }

    public loadEmails(): void {
        this.emailService
            .getAllByContatoId(this.contato.id)
            .subscribe(emails => {
                this.emails = emails;
            });
    }

    public containNumero(): boolean {
        if (
            this.telefones.some(
                telefone =>
                    telefone.numero ===
                    this.telefoneForm
                        .get('numero')
                        .value.match(/\d+/g)
                        .join('')
            )
        ) {
            this.snackBarService.showAlert('Telefone já adicionado.');
            return true;
        }
        return false;
    }

    public containEmail(): boolean {
        if (
            this.emails.some(
                email => email.email === this.emailForm.get('email').value
            )
        ) {
            this.snackBarService.showAlert('E-mail já adicionado.');
            return true;
        }
        return false;
    }

    public addTelefone(): void {
        this.telefoneForm.markAllAsTouched();
        if (this.telefoneForm.valid) {
            this.telefoneForm.patchValue({contato: this.contato});
            if (this.telefoneForm.get('id').value == null) {
                if (this.containNumero()) {
                    return;
                }
                this.telefoneService
                    .create(Telefone.fromJson(this.telefoneForm.value))
                    .subscribe(
                        () => {
                            this.snackBarService.showSuccess('Telefone adicionado com sucesso!');
                            this.loadTelefones();
                        },
                        (e) => this.snackBarService.showError('Erro ao adicionar telefone.',e)
                    );
            } else {
                this.telefoneService
                    .update(Telefone.fromJson(this.telefoneForm.value))
                    .subscribe(
                        () => {
                            this.snackBarService.showSuccess('Telefone atualizado com sucesso!');
                            this.loadTelefones();
                        },
                        (e) => this.snackBarService.showError('Erro ao alterar telefone.',e)
                    );
            }
            this.cleanForms();
        }
    }

    public editTelefone(telefone: Telefone): void {
        this.telefoneService.getById(telefone.id).subscribe(t => {
            this.telefoneForm.patchValue(Telefone.fromJson(t));
        });
    }

    public removeTelefoneDialog(telefone: Telefone): void {
        if (this.telefones.length < 2) {
            this.snackBarService.showAlert('Obrigatório possuir pelo menos um telefone!');
            return;
        } else {
            const dialogRef = this.dialog.open(
                ConfirmarExclusaoDialogComponent,
                {
                    width: '300px',
                    data: {label: telefone.numero}
                }
            );

            dialogRef.afterClosed().subscribe(result => {
                if (!!result) {
                    this.removeTelefone(telefone);
                }
            });
        }
    }

    public removeTelefone(telefone: Telefone): void {
        this.telefoneService.removeTelefone(telefone).subscribe(
            () => {
                this.snackBarService.showSuccess('Telefone excluído com sucesso!');
                this.loadTelefones();
            },
            (e) => this.snackBarService.showError('Erro ao excluir telefone.',e)
        );
        this.cleanForms();
    }

    public addEmail(): void {
        this.emailForm.markAllAsTouched();
        if (this.emailForm.valid) {
            this.emailForm.patchValue({contato: this.contato});
            if (this.emailForm.get('id').value == null) {
                if (this.containEmail()) {
                    return;
                }
                this.emailService.create(this.emailForm.value).subscribe(
                    () => {
                        this.snackBarService.showSuccess('E-mail adicionado com sucesso!');
                        this.loadEmails();
                    },
                    (e) => this.snackBarService.showError('Erro ao adicionar e-mail.',e)
                );
            } else {
                this.emailService.update(this.emailForm.value).subscribe(
                    () => {
                        this.snackBarService.showSuccess('E-mail atualizado com sucesso!');
                        this.loadEmails();
                    },
                    (e) => this.snackBarService.showError('Erro ao alterar e-mail.',e)
                );
            }
            this.emailForm.reset();
        }
    }

    public editEmail(email: Email): void {
        this.emailService.getById(email.id).subscribe(e => {
            this.emailForm.patchValue(Email.fromJson(e));
        });
    }

    public removeEmailDialog(email: Email): void {
        if (this.emails.length < 2) {
            this.snackBarService.showAlert('Obrigatório possuir pelo menos um email!');
            return;
        } else {
            const dialogRef = this.dialog.open(
                ConfirmarExclusaoDialogComponent,
                {
                    width: '300px',
                    data: {label: email.email}
                }
            );

            dialogRef.afterClosed().subscribe(result => {
                if (!!result) {
                    this.removeEmail(email);
                }
            });
        }
    }

    public removeEmail(email: Email): void {
        this.emailService.removeEmail(email).subscribe(
            () => {
                this.snackBarService.showSuccess('E-mail excluído com sucesso!');
                this.loadEmails();
            },
            (e) => this.snackBarService.showError('Erro ao excluir e-mail.',e)
        );
        this.cleanForms();
    }

    public get hasTelefones(): boolean {
        return (
            typeof this.telefones !== 'undefined' && this.telefones.length > 0
        );
    }

    public get hasEmails(): boolean {
        return typeof this.emails !== 'undefined' && this.emails.length > 0;
    }

    public get isCompleted(): boolean {
        return this.hasEmails && this.hasTelefones;
    }

    public cleanForms(): void {
        this.telefoneForm.reset();
        this.emailForm.reset();
    }

    onChangeCelular(): void {
        if (this.telefoneForm.get('numero').value.length >= 10) {
            this.maskCell = '00000-0000';
        } else {
            this.maskCell = '0000-00009';
        }
    }

    get tiposTeleones(): BaseEnum[] {
        return Telefone.tiposTelefones;
    }

    get tiposEmails(): BaseEnum[] {
        return Email.tiposEmails;
    }

    public next(): void {
        if (this.isCompleted) {
            this.eventNext.emit();
        } else if (!this.hasEmails) {
            this.snackBarService.showAlert('Adicione ao menos um email.');
        } else if (!this.hasTelefones) {
            this.snackBarService.showAlert('Adicione ao menos um telefone.');
        }
    }

    public back(): void {
        this.eventBack.emit();
    }
}
