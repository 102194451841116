import {Pipe, PipeTransform} from '@angular/core';
import {BaseEnum} from 'app/main/shared/models/base.enum ';
import {BaseModel} from "../models/base.model";

@Pipe({
    name: 'removerAdicionadosPipe'
})
export class RemoverAdicionadosPipe implements PipeTransform {

    transform(lista: any[], listaAdicionados: any[]): any[] {
        if (lista && lista.length > 0) {
            if (!listaAdicionados || listaAdicionados.length == 0) {// se a segunda lista adicionados for vazia, não executa verificações
                return lista;
            }

            if (RemoverAdicionadosPipe.instanceOfBaseEnum(lista[0])) {// Se a primeira lista for de BASEENUM
                if (listaAdicionados[0] instanceof BaseModel) {//se a lista de adicionados for de BASEMODEL
                    return lista.filter(item => !listaAdicionados.some(itemAdicionado => (itemAdicionado as BaseModel).compareFromBaseEnum(item)));
                } else if (RemoverAdicionadosPipe.instanceOfBaseEnum(listaAdicionados[0])) {// se a lista de adicionados for de BASEENUM
                    return lista.filter(item => !listaAdicionados.some(itemAdicionado => item === itemAdicionado));
                }
            } else if (lista[0] instanceof BaseModel) {// se a primeira lista for de BASEMODEL
                if (listaAdicionados[0] instanceof BaseModel) {//se a lista de adicionados for de BASEMODEL
                    return lista.filter(item => !listaAdicionados.some(itemAdicionado => (itemAdicionado as BaseModel).compare(item)));
                } else if (RemoverAdicionadosPipe.instanceOfBaseEnum(listaAdicionados[0])) {// se a lista de adicionados for de BASEENUM
                    return lista.filter(item => !listaAdicionados.some(itemAdicionado => (item as BaseModel).compareFromBaseEnum(itemAdicionado)));
                }
            }
        }
        return [];
    }

    private static instanceOfBaseEnum(object: any): object is BaseEnum {
        return 'valor' in object && 'descricao' in object;
    }
}