import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { ValidateBrService } from 'angular-validate-br';
import { Municipio } from 'app/main/core/models/municipio.model';
import { Pais } from 'app/main/core/models/pais.model';
import { PessoaFisica } from 'app/main/core/models/pessoa-fisica.model';
import { TipoLogradouro } from 'app/main/core/models/tipo-logradouro.model';
import { CepDTO } from 'app/main/shared/models/cep-dto';
import { ENUM_UF_VALUES, EnumUf } from 'app/main/shared/models/uf.enum';
import { SnackBarService } from 'app/main/shared/services/snack-bar/snack-bar.service';
import { ViacepService } from 'app/main/shared/services/viacep.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RegisterService } from './register.service';
import { PaisService } from 'app/main/core/services/pais.service';
import { environment } from 'environments/environment';
import { MunicipioService } from 'app/main/core/services/municipio.service';
import { TipoLogradouroService } from 'app/main/core/services/tipo-logradouro.service';
import { RegisterInfoDialog } from './register-info-dialog/register-info-dialog.component';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { BaseEnum } from 'app/main/shared/models/base.enum ';

@Component({
    selector: 'register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class RegisterComponent implements OnInit, OnDestroy {
    registerForm: FormGroup;
    private usuario: any;
    @Output() eventNext = new EventEmitter();
    maxDate = moment().subtract(16, 'years');
    compareWith = Pais.compareWith;
    ufValues = ENUM_UF_VALUES;
    pessoaFisica: PessoaFisica;
    paises: Pais[];
    maskCell = '00000-0000';
    municipios = Municipio[''];
    tiposLogradouro = TipoLogradouro[''];
    compareWithMunicipio = Municipio.compareWith;
    compareWithTipoLogradouro = TipoLogradouro.compareWith;
    estadosCivis: BaseEnum[] = PessoaFisica.estadosCivis;
    sexos: BaseEnum[] = PessoaFisica.sexos;
    message: string;
    title: string;
    fechar: string

    private _unsubscribeAll: Subject<any>;

    constructor(
        private registerService: RegisterService,
        private router: Router,
        protected snackBarService: SnackBarService,
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private validateBrService: ValidateBrService,
        private viacep: ViacepService,
        private paisService: PaisService,
        private municipioService: MunicipioService,
        private tipoLogradouroService: TipoLogradouroService,
        private readonly dialog: MatDialog,
        private translate: TranslateService
    ) {
        // Configure the layout.
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        // Set traducoes iniciais
        this.message = this.translate.instant('register.criar-conta.info-receita.mensagem');
        this.title = this.translate.instant('register.criar-conta.info-receita.titulo');        
        this.fechar = this.translate.instant('register.criar-conta.info-receita.fechar');        
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.registerForm = this._formBuilder.group({
            cpf: ['', [Validators.required, this.validateBrService.cpf]],
            email: ['', [Validators.required, Validators.email]],
            nome: [
                '',
                [
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(200),
                ],
            ],
            password: ['', [Validators.required, Validators.minLength(6)]],
            passwordConfirm: [
                '',
                [Validators.required, confirmPasswordValidator],
            ],
            terms: ['', [Validators.requiredTrue]],
            
            // Desabilitado a opção de cadastro simpliicado conforme solicitado na tarefa #8797 SGD
            cadSimplificado: [false],
            
            pais: ['', [Validators.required]],
            passaporte: ['', [Validators.required]],
            rg: ['', [Validators.required]],
            dataNascimento: [null, Validators.required],
            estadoCivil: [null, Validators.required],
            sexo: [null, Validators.required],
            mae: ['', Validators.required],
            numTelefoneContato: [null, Validators.required],
            cep: [null, [Validators.required, Validators.maxLength(10)]],
            uf: [environment.UF_PADRAO, [Validators.required]],
            municipio: [null, Validators.required],
            bairro: [
                null,
                [
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(200),
                ],
            ],
            logradouro: [
                null,
                [
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(200),
                ],
            ],
            tipoLogradouro: [
                '',
                [Validators.required, Validators.maxLength(7)],
            ],
            numero: ['', [Validators.required, Validators.maxLength(7)]],
            complemento: ['', [Validators.maxLength(200)]],
            ddd: ['', [Validators.required, Validators.maxLength(4)]],
            ddi: ['55', [Validators.required]],
        });

        // Update the validity of the 'passwordConfirm' field
        // when the 'password' field changes
        this.registerForm
            .get('password')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.registerForm
                    .get('passwordConfirm')
                    .updateValueAndValidity();
            });

        this.loadPaises();
        this.loadTiposLogradouro();

        // pegando a idioma da máquina do usuário
        // let lang = 'pt';
        // const lng = localStorage.getItem('lang');
        // console.log(lng);

        // if (lng) {
        //     lang = lng;
        // } else {
        //     let browserLang = this.translate.getBrowserLang();
        //     lang = browserLang.match(/en|pt|es/) ? browserLang : 'en';
        // }

        // localStorage.setItem('lang', lang);

        // this.translate.use(lang);

        // Traduzindo as combos de seleções de estado civil e sexo
        this.translate.onLangChange.subscribe({
            next: (lang) => {
                localStorage.setItem('lang', lang.lang);

                // combo de seleção para estado civil sendo traduzido
                this.estadosCivis = this.estadosCivis.map((estado) => {
                    try {
                        estado.descricao =
                            lang.translations['register']['criar-conta']['estado-civis'][estado.valor];
                    } catch (e) {
                        estado.descricao =
                            'register.criar-conta.estado-civis.' + estado.valor;
                    } finally {
                        return estado;
                    }
                });

                // combo de seleção para sexo sendo traduzido
                this.sexos = this.sexos.map((sexo) => {
                    try {
                        sexo.descricao =
                            lang.translations['register']['criar-conta'][
                                'sexos'
                            ][sexo.valor];
                    } catch (e) {
                        sexo.descricao =
                            'register.criar-conta.sexos.' + sexo.valor;
                    } finally {
                        return sexo;
                    }
                });                

                // Traduzindo mensagem de informação da receita federal quando ao CPF
                this.message = lang.translations['register']['criar-conta']['info-receita']['mensagem'];
                this.title = lang.translations['register']['criar-conta']['info-receita']['titulo'];
                this.fechar = lang.translations['register']['criar-conta']['info-receita']['fechar'];
            },
        });

        
    }

    changeLang(key: string) {
        this.translate.use(key).subscribe(() => {
            this.translate.get('global.messages.translate-changed').subscribe((message) => {
                this.snackBarService.showSuccess(message);
            })
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    register(): void {
        const login = this.registerForm.get('cpf').value.match(/\d+/g).join('');
        const email = this.registerForm.get('email').value;
        const nome = this.registerForm.get('nome').value;
        const senha = this.registerForm.get('password').value;

        // Desabilitado a opção de cadastro simpliicado conforme solicitado na tarefa #8797 SGD
        const cadSimplificado = this.registerForm.get('cadSimplificado').value;
        
        const pais = this.registerForm.get('pais').value ? this.registerForm.get('pais').value : null;
        const passaporte = this.registerForm.get('passaporte').value;
        const rg = this.registerForm.get('rg').value;
        const dataNascimento = this.registerForm.get('dataNascimento').value;
        const estadoCivil = this.registerForm.get('estadoCivil').value;
        const sexo = this.registerForm.get('sexo').value;
        const mae = this.registerForm.get('mae').value;
        const ddd = this.registerForm.get('ddd').value;
        const ddi = this.registerForm.get('ddi').value;
        const numTelefoneContato =
            this.registerForm.get('numTelefoneContato').value;
        const cep = this.registerForm.get('cep').value;
        const uf = this.registerForm.get('uf').value;
        const municipioDTO = this.registerForm.get('municipio').value
            ? this.registerForm.get('municipio').value
            : null;
        const bairro = this.registerForm.get('bairro').value;
        const logradouro = this.registerForm.get('logradouro').value;
        const tipoLogradouro = this.registerForm.get('tipoLogradouro').value
            ? this.registerForm.get('tipoLogradouro').value
            : null;
        const numero = this.registerForm.get('numero').value;
        const complemento = this.registerForm.get('complemento').value;

        this.registerService
            .post({
                login,
                senha,
                email,
                nome,
                cadSimplificado,
                pais,
                passaporte,
                rg,
                dataNascimento,
                estadoCivil,
                sexo,
                mae,
                ddd,
                ddi,
                numTelefoneContato,
                cep,
                uf,
                municipioDTO,
                bairro,
                logradouro,
                tipoLogradouro,
                numero,
                complemento,
            })
            .subscribe(
                (u) => {
                    this.usuario = u;
                    this.router.navigate(['/auth/mail-confirm'], {
                        queryParams: { email: email },
                    });
                },
                (e) => this.snackBarService.showError(e)
            );
    }

    get dataNascimento(): any {
        return this.registerForm.get('dataNascimento');
    }  

    getUfTexto(tipo: string): string {
        return EnumUf[tipo];
    }

    private loadPaises(): void {
        this.paisService.getAll().subscribe((paises) => {
            this.paises = paises;
        });
    }

    pesquisarCep(): void {
        const cep = this.registerForm.get('cep').value;

        if (cep !== undefined) {
            this.viacep.getCep(cep).subscribe(
                (c) => {
                    this.preencherEndereco(c);
                },
                (e) => this.snackBarService.showError(e.errors[0])
            );
        }
    }

    onChangeNumeroCelular(): void {
        if (this.registerForm.get('numTelefoneContato').value.length >= 10) {
            this.maskCell = '0000-0000';
        } else {
            this.maskCell = '00000-0000';
        }
    }

    onSelectMunicipio(event: any): void {
        this.municipios = [];
        if (event.value !== undefined) {
            this.loadMunicipios(event.value);
        }
    }

    public loadMunicipios(uf: string): void {
        this.municipioService.getAllByUf(uf).subscribe((municipios) => {
            this.municipios = municipios;
        });
    }

    public loadTiposLogradouro(): void {
        this.tipoLogradouroService.getAll().subscribe((tipos) => {
            this.tiposLogradouro = tipos;
        });
    }

    private preencherEndereco(cepDTO: CepDTO): void {
        this.registerForm.patchValue({ logradouro: cepDTO.logradouro });
        this.registerForm.patchValue({ complemento: cepDTO.complemento });
        this.registerForm.patchValue({ bairro: cepDTO.bairro });
        this.registerForm.patchValue({ municipio: cepDTO.municipio });
        this.registerForm.patchValue({ uf: cepDTO.municipio.uf });
        this.loadMunicipios(cepDTO.municipio.uf);
    }

    isEstrangeiro(): boolean {
        const pais = this.registerForm.get('pais').value;
        if (pais.nome !== 'Brasil') {
            return true;
        } else {
            return false;
        }
    }

    msgInformarLinkReceitaFederal(): void {
        const pais = this.registerForm.get('pais').value;       

        if (pais.id !== 1) {
            const dialogRef = this.dialog.open(RegisterInfoDialog, {
                width: '90%',
                maxHeight: '700px',
                data: {
                    icon: 'info',
                    title: this.title,
                    message: this.message,
                    close: this.fechar
                },
            });
            dialogRef.afterClosed().subscribe((result) => {});
            this.registerForm.get('rg').disable();
            this.registerForm.get('passaporte').enable();
        } else {
            this.registerForm.get('rg').enable();
            this.registerForm.get('passaporte').disable();
        }
    }

    // Desabilitado a opção de cadastro simpliicado conforme solicitado na tarefa #8797 SGD
    isContaSimplificada(): boolean {
        if (this.registerForm.get('cadSimplificado').value == true) {
            return true;
        } else {
            return false;
        }
    }
}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (
    control: AbstractControl
): ValidationErrors | null => {
    if (!control.parent || !control) {
        return null;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if (!password || !passwordConfirm) {
        return null;
    }

    if (passwordConfirm.value === '') {
        return null;
    }

    if (password.value === passwordConfirm.value) {
        return null;
    }

    return { passwordsNotMatching: true };
};
