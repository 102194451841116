import { NgModule } from "@angular/core";
import { Routes } from "@angular/router";
import { IFrameComponent } from "./iframe.component";



@NgModule({
    declarations: [IFrameComponent],
    exports: [IFrameComponent],
    entryComponents: [IFrameComponent]
})
export class IFrameModule {}
