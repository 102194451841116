import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RegisterService {
    endpoint = environment.URL_GATEWAY + '/api/auth/register';

    constructor(private http: HttpClient) {}

    post(user: any): Observable<any> {
        return this.http.post<Observable<any>>(this.endpoint, user);
    }
}
