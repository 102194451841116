import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {LoadingService} from './loading.service';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
    constructor(private loadingService: LoadingService) {

    }

    static RUNNING = 0;

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!LoadingService.isExcludedRequest(req)) {
            this.loadingService.setValue(true);
            LoadingInterceptor.RUNNING++;
        }
        return next.handle(req).pipe(
            map(event => {
                if (event instanceof HttpResponse) {

                    if (!LoadingService.isExcludedRequest(req)) {
                        LoadingInterceptor.RUNNING--;
                    }
                    this.setLoadingFalseIfNoRequestRunning();
                }
                return event;
            }), catchError(err => {
                if (!LoadingService.isExcludedRequest(req)) {
                    LoadingInterceptor.RUNNING--;
                }
                this.setLoadingFalseIfNoRequestRunning();
                return throwError(err);
            })
        );
    }

    private setLoadingFalseIfNoRequestRunning(): void {
        if (LoadingInterceptor.RUNNING === 0) {
            this.loadingService.setValue(false);
        }
    }
}
