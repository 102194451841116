import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Documento } from 'app/main/core/models/documento.model';
import { DocumentoService } from 'app/main/core/services/documento.service';
import { SnackBarService } from '../../services/snack-bar/snack-bar.service';

@Component({
    selector: 'app-documento-dialog',
    templateUrl: './documento-dialog.component.html',
})
export class DocumentoDialogComponent implements OnInit {
    
    form: FormGroup;
    IsWait: boolean = false;
    isSomenteVisualizacao = false;
    file;
    isValid = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) protected data: Documento,
        public dialogRef: MatDialogRef<DocumentoDialogComponent>,
        public documentoService: DocumentoService,
        public formBuilder: FormBuilder,
        protected snackBarService: SnackBarService
    ) {}

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            nome: [this.data.template.descricao, [Validators.required, Validators.maxLength(255)]],
            arquivo: [null, [Validators.required]]
        });
    }

    onFileSelect(event): void {
        if (!event.target.files[0].size) {
            this.snackBarService.showAlert('Arquivo com tamanho inválido.');
            document.getElementById('input-arquivo')['value'] = null;
            return;
        }
        if (event.target.files.length === 1) {
            const file = event.target.files[0];
            this.file = file;
            this.form.get('arquivo').setValue(file);
        } else {
            this.form.get('arquivo').reset();
        }
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }
    confirm(): void {
        this.IsWait = true;
        const multipartFile = this.form.get('arquivo').value;
        this.validarPdf();
        if (this.isValid) {
            (this.data.id === null
            ? this.documentoService.createMultipart(this.data, multipartFile)
            : this.documentoService.updateMultipart(this.data, multipartFile)
        ).subscribe(
            () => {
                this.IsWait = false;
                this.dialogRef.close(true);
            },
            (e) => {
                this.IsWait = false;
                this.snackBarService.showError(
                    'Não foi possível salvar o registro.',e
                );
            }
        );
        }

        this.isValid = true;
    }

    get nome(): string {
        return this.data.template.descricao;
    }

    get titleDialog(): string {
        return this.nome === '' ? 'Anexar ' : 'Alterar ';
    }
    
    public async validarPdf(): Promise<void> {
        if (this.file.type !== 'application/pdf') {
            this.IsWait = false;
            this.snackBarService.showAlert(
                'Valido somente arquivo PDF.'
            );
            this.isValid = false;
        } else {
            await this.validarTamanhoArquivo();
        }
    }

    private async validarTamanhoArquivo(): Promise<void> {
        if (this.data.template.tamanhoDocumento === 'TREZENTOS_KB') {
            const tamanhoKb = (Math.round(this.file.size * 100 / 1024) / 100);
            if (tamanhoKb > 300) {
                this.isValid = false;
                this.mensagemArquivo(1);
            }
        } else {
            const tamanhoMega = (Math.round(this.file.size * 100 / (1024 * 1024)) / 100);
            if (tamanhoMega > this.getTamanhoEmMB(this.data.template.tamanhoDocumento)) {
                this.isValid = false;
                this.mensagemArquivo(1);
            }
        }
        // execulta validação tamanho pagina
        if (this.isValid) {
            await this.validarTamanhoPagina();
        }
    }

    public async validarTamanhoPagina(): Promise<void> {
        let qtdPaginas = 0;
        const reader = new FileReader();
        reader.readAsBinaryString(this.file);
        qtdPaginas = await new Promise((resolve, reject) => {
            reader.onload = () => {
                if (typeof reader.result === 'string') {
                    resolve(reader.result.match(/\/Type[\s]*\/Page[^s]/g).length);
                }
            };
        });
        if (this.data.template.tamanhoPagina === 'TREZENTOS_KB') {
            this.validarTamanhoEmKb(qtdPaginas);
        } else {
            const tamanhoArquivoMega = (Math.round(this.file.size * 100 / (1024 * 1024)) / 100);
            const tamanhoMedioPagina = tamanhoArquivoMega / qtdPaginas;
            const tamanhoMedioPaginaMega = (Math.round(tamanhoMedioPagina * 100 / (1024 * 1024)) / 100);
            if (tamanhoMedioPaginaMega > this.getTamanhoEmMB(this.data.template.tamanhoPagina)) {
                this.isValid = false;
                this.mensagemArquivo(0);
            }
        }
    }

    public validarTamanhoEmKb(qtdPaginas): void {
        if (this.data.template.tamanhoPagina === 'TREZENTOS_KB') {
            const kb = (Math.round(this.file * 100 / 1024) / 100);
            const tamanhoMedioPagina = kb / qtdPaginas;
            if (tamanhoMedioPagina > 300) {
                this.isValid = false;
                this.mensagemArquivo(0);
            }
        }
    }

    public mensagemArquivo(tipoMensagem: number): void {
        this.IsWait = false;
        let textoMensagem: string;
        tipoMensagem === 1 ? textoMensagem = 'Tamanho do arquivo  ultrapassou o limite máximo.' : textoMensagem = 'Tamanho médio das paginas ultrapassou o limite máximo.';
        this.snackBarService.showAlert(
            textoMensagem,
        );
    }

    getTamanhoEmMB(constante: string): number {
        switch (constante) {
            case 'UM_MEGA':
                return 1;
            case 'DOIS_MEGA':
                return 2;
            case 'TRES_MEGA':
                return 3;
            case 'QUATRO_MEGA':
                return 4;
            case 'CINCO_MEGA':
                return 5;
            case 'DEZ_MEGA':
                return 10;
            case 'QUINZE_MEGA':
                return 15;
            default:
                return 1;
        }
    }

}
