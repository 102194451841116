import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {PessoaFisicaProfissao} from "../models/profissao-pessoa-fisica.model";
import {environment} from "../../../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {catchError, map} from "rxjs/operators";
import {PessoaFisicaProfissaoDto} from "../models/pessoa-fisica-profissao-dto.model";

@Injectable({
    providedIn: 'root'
})
export class PessoaFisicaProfissaoService {
    private urlResource: string = environment.URL_SCORP_API + '/api/pessoa-fisica-profissao';

    constructor(
        private http: HttpClient
    ) {}

    vincularPessoaProfissao(pessoaFisicaProfissaoDto: PessoaFisicaProfissaoDto): Observable<PessoaFisicaProfissao> {
        return this.http
            .post(this.urlResource, pessoaFisicaProfissaoDto)
            .pipe(
                map(PessoaFisicaProfissao.fromJson.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    getProfissoesPessoa(idPessoa): Observable<PessoaFisicaProfissao[]> {
        const url = `${this.urlResource}/pessoa-fisica/${idPessoa}`;
        return this.http
            .get(url)
            .pipe(
                map(response => this.jsonToResources(response)),
                catchError(this.handleError.bind(this))
            );
    }

    delete(id: number): Observable<any> {
        const url = `${this.urlResource}/${id}`;
        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }

    protected jsonToResources(json: any): PessoaFisicaProfissao[] {
        const resources: PessoaFisicaProfissao[] = [];
        if (json !== undefined && json !== null) {
            json.forEach((e: PessoaFisicaProfissao) => resources.push(PessoaFisicaProfissao.fromJson(e)));
        }
        return resources;
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }
}
