import { Pessoa } from 'app/main/core/models/pessoa.model';
import { Injectable, Injector } from '@angular/core';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PessoaService {
    private http: HttpClient;
    private urlResource = environment.URL_SCORP_API;
    private cadastroCompletoSubject: BehaviorSubject<boolean>;
    public cadastroCompleto: Observable<boolean>;
    public cadastroCompletoUser: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private pessoa: Pessoa;

    constructor(private injector: Injector) {
        this.http = injector.get(HttpClient);
        this.urlResource += '/api/pessoas';
        this.cadastroCompletoSubject = new BehaviorSubject<boolean>(this.getCadastroCompletoLocal());
    }

    getCadastroCompletoLocal(): boolean {
        return JSON.parse(localStorage.getItem('cadastroCompleto'));
    }

    isCadastroCompleto(): Observable<boolean> {
        return this.cadastroCompletoSubject.asObservable();
    }

    getByPessoaFisicaCpf(cpf: string): Observable<Pessoa> {
        const url = `${this.urlResource}/pessoa-fisica/${cpf}`;

        return this.http.get(url).pipe(
            map(response => Pessoa.fromJson(response)),
            catchError(this.handleError.bind(this))
        );
    }

    getCadastroCompleto(cpf: string): Observable<Pessoa> {
        const url = `${this.urlResource}/pessoa-fisica/${cpf}`;

        return this.http.get(url).pipe(
            map(response => {
                this.cadastroCompletoSubject.next(null);
                this.pessoa = Pessoa.fromJson(response);
                localStorage.removeItem('cadastroCompleto');
                localStorage.setItem(
                    'cadastroCompleto',
                    JSON.stringify(this.pessoa.cadastroCompleto)
                );
                this.cadastroCompletoSubject.next(this.pessoa.cadastroCompleto);

                return this.pessoa;
            }),
            catchError(this.handleError.bind(this))
        );
    }

    completarCadastro(pessoa: Pessoa): Observable<Pessoa> {
        const url = `${this.urlResource}/completar-cadastro/${pessoa.id}`;
        return this.http.put(url, pessoa).pipe(
            map(() => pessoa),
            catchError(this.handleError)
        );
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }
}
