import { Injectable, Injector } from "@angular/core";
import { ListResolver } from 'app/main/shared/resolvers/list-resolver';
import { RoutingStateService } from 'app/main/core/services/routing-state.service';
import { DetailResolver } from 'app/main/shared/resolvers/detail-resolver';
import { BaseService } from 'app/main/shared/services/base.service';
import { Modulo } from './modulo.model';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class ModuloListResolver extends ListResolver<Modulo> {
    constructor(service: ModuloService) {
        super(service);
    }
}

@Injectable()
export class ModuloResolver extends DetailResolver<Modulo> {
    constructor(
        service: ModuloService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: "root"
})
export class ModuloService extends BaseService<Modulo> {
 
    constructor(protected injector: Injector) {
        super(
            "/api/modulos",
            injector,
            Modulo.prototype,
            Modulo.fromJson
        );
    }

    getAllbyIdSistema(idSistema?: number): Observable<Modulo[]> {
        var url = this.urlResource + "/sistema/";
        if (idSistema !== undefined) {
           url += idSistema;
        }
        return this.http.get(url).pipe(
           map(this.jsonToResources.bind(this)),
           catchError(this.handleError.bind(this))
        )
     }

}
