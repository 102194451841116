import { Component, ViewEncapsulation } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';

export  interface IPageError {
    code?: number, message?: string, link?: string
}

@Component({
    selector     : 'app-page-error',
    templateUrl  : './page-error.component.html',
    styleUrls    : ['./error.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PageErrorComponent
{
    error: IPageError;
    
    constructor(private _fuseConfigService: FuseConfigService) {
        
        this.error = {};

        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };  
    }

}
