import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { FuseSharedModule } from "@fuse/shared.module";
import { PasswordConfirmComponent } from "./password-confirm.component";

const routes = [
    {
        path: "auth/password-confirm",
        component: PasswordConfirmComponent
    }
];

@NgModule({
    declarations: [PasswordConfirmComponent],
    imports: [RouterModule.forChild(routes), MatIconModule, FuseSharedModule]
})
export class PasswordConfirmModule {}
