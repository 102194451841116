import { Component, Injector, ViewEncapsulation } from '@angular/core';
import { GrupoService } from '../grupo.service';
import { BaseListComponent } from 'app/main/shared/components/base-list/base-list.component';
import { Grupo } from '../grupo.model';
import { HttpParams } from '@angular/common/http';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-grupo-list',
  templateUrl: './grupo-list.component.html',
  styleUrls: ['./grupo-list.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class GrupoListComponent extends BaseListComponent<Grupo> {

  nome: string;

    constructor(injector: Injector, service: GrupoService) {
        super(injector, ['nome', 'descricao', 'ativo'], service);
    }

    fillFilters(httpParams: HttpParams): HttpParams {
      if (typeof this.nome !== 'undefined' && this.nome.length > 0) {
          httpParams = httpParams.set('nome', this.nome);
      }
      return httpParams;
    }

}
