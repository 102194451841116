import {Injectable, Injector} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {BaseService} from '../../shared/services/base.service';
import {catchError, map} from 'rxjs/operators';
import { UsuarioGrupo } from './grupo-detail/grupo-dados-usuarios-list/usuario-grupo.model';


@Injectable({
    providedIn: 'root'
})
export class GrupoUsuariosListService extends BaseService<UsuarioGrupo> {

    constructor(
        injector: Injector,
    ) {
        super(
            '/api/grupos-usuario',
            injector,
            UsuarioGrupo.prototype,
            UsuarioGrupo.fromJson
        );
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }

    excluir(grupoId: number, usuarioId: number): Observable<any> {
        const url = `${this.urlResource}/grupos/${grupoId}/usuarios/${usuarioId}`;
        return this.http
            .delete(url).pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

}

