import { Component, ViewEncapsulation } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';

@Component({
    selector     : 'error-404',
    templateUrl  : './404/error-404.component.html',
    styleUrls    : ['./error.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class Error404Component
{ 
    constructor(private _fuseConfigService: FuseConfigService) {
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };  
    }
    
}
