import {Injectable} from '@angular/core';

export interface Column {
  coluna: string;
  showMobile: boolean;
}

@Injectable()
export class ApplicationStateService {

  private isMobileResolution: boolean;

  constructor() {
    if (window.innerWidth < 768) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }

  public getIsMobileResolution(): boolean {
    return this.isMobileResolution;
  }

  getDisplayedColumns(columnDefinitions: Column[]): string[] {
    return columnDefinitions
    .filter(cd => !this.isMobileResolution || cd.showMobile)
    .map(cd => cd.coluna);
 }

}