import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { BaseDetailComponent } from 'app/main/shared/components/base-detail/base-detail.component';
import { ConfirmarExclusaoDialogComponent } from '../../../shared/components/confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import { Modulo } from '../../modulos/modulo.model';
import { ModuloService } from '../../modulos/modulo.service';
import { Sistema } from '../../sistemas/sistema.model';
import { SistemaService } from '../../sistemas/sistema.service';
import { DialogAdicionarPerfilMenuComponent } from '../dialog-adicionar-perfil-menu/dialog-adicionar-perfil-menu.component';
import { DialogSelecionarOperacoesPerfilComponent } from '../dialog-selecionar-operacoes-perfil/dialog-selecionar-operacoes-perfil.component';
import { PerfilMenuDTO } from '../perfil-menu-dto.model';
import { Perfil } from '../perfil.model';
import { PerfilService } from '../perfil.service';
import { SistemaModuloDTO } from '../sistema-modulo-dto.model';

@Component({
    selector: 'app-perfil-detail',
    templateUrl: './perfil-detail.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations
})
export class PerfilDetailComponent extends BaseDetailComponent<Perfil> {

    sistemaControl = new FormControl(null, Validators.required);
    moduloControl = new FormControl(null, Validators.required);
    perfisMenus: PerfilMenuDTO[] = [];
    sistemas: Sistema[];
    modulos: Modulo[];
    currentModuloId: any;
    currentPerfilId: any;
    isWait = false;
    menuAtribuidoPerfil: SistemaModuloDTO[] = [];
    menusDisponivelParaAtribuicaoPerfil: SistemaModuloDTO[] = [];
    constructor(
        private perfilService: PerfilService,
        private sistemaService: SistemaService,
        private moduloService: ModuloService,

        injector: Injector
    ) {
        super(injector, new Perfil(), perfilService, Perfil.fromJson);
    }

    afterResourceLoad(): void {
        this.carregaSistemas();
        this.carregarMenusAtribuidoPerfil();
        this.currentPerfilId = this.resource.id;
    }

    protected buildResourceForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            chaveSegPerfil: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
            descricao: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
            delegavel: [false, [Validators.required]],
            dataInicio: [{value: null, disabled: true}],
            dataFim: [null]
        });
    }

    private carregaSistemas(): void {
        this.sistemaService
            .getAll()
            .subscribe(sistemas => (this.sistemas = sistemas));
    }

    private carregarPerfisMenus(perfilId?:any, moduloId?: any): void {
        this.perfilService.getPerfilMenuDTOByPerfilAndModulo(perfilId || this.resource.id, moduloId || this.moduloControl.value.id).subscribe(
            perfisMenu => this.perfisMenus = perfisMenu
        );
    }

    onSelectSistema(event: any): void {
        this.modulos = [];
        this.perfisMenus = [];
        this.menusDisponivelParaAtribuicaoPerfil = [];
        if (event.value !== undefined) {
            this.moduloService
                .getAllbyIdSistema(event.value.id)
                .subscribe(modulos => (this.modulos = modulos));
        }
    }

    onSelectModulo(event: any): void {
        this.perfisMenus = [];
        console.log('onSelectModulo', event);
        this.currentModuloId = event.value.id;
        if (event.value !== undefined) {
            this.carregarMenusAtribuidoPerfil();
            this.carregarMenusDisponivelParaAtribuicaoPerfil();
        }
    }

    editarPerfilMenu(perfilMenuDTO: PerfilMenuDTO) {
        const dialogRef = this.dialog.open(DialogSelecionarOperacoesPerfilComponent, {
            width: '600px',
            data: perfilMenuDTO
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                console.log('editarPerfilMenu.result',result);
                // this.carregarPerfisMenus(result.perfilId, result.moduloId);
                this.carregarMenusAtribuidoPerfil(result.perfilId);
            }
        });
    }

    protected creationPageTitle(): string {
        return 'Cadastro de novo perfil';
    }

    protected editionPageTitle(): string {
        const resourceName = this.resource.descricao || '';
        return 'Editando o perfil: ' + resourceName;
    }

    protected visualizationPageTitle(): string {
        const resourceName = this.resource.descricao || '';
        return 'Visualizando o perfil: ' + resourceName;
    }
    private carregarMenusAtribuidoPerfil(perfilId?: any): void{
        this.isWait = true;
        this.perfilService.getMenusAtribuidoPerfil(perfilId || this.resource.id).subscribe(
            menuAtribuidoPerfil => {
                                    this.menuAtribuidoPerfil = menuAtribuidoPerfil;
                                    this.isWait = false;
            }, () => this.isWait = false

        );
    }

    private carregarMenusDisponivelParaAtribuicaoPerfil(perfilId?: any, moduloId?: any): void{
        this.isWait = true;
        let perfilIdCheck = perfilId || this.currentPerfilId || (this.resource && this.resource.id ? this.resource.id : null);
        let moduloIdCheck =  moduloId || this.currentModuloId || ( this.moduloControl && this.moduloControl.value ? this.moduloControl.value.id : null );
        if (!perfilIdCheck || !moduloIdCheck) {
            this.carregarMenusAtribuidoPerfil();
        } else
            this.perfilService.getMenusDisponivelParaAtribuicaoPerfil(perfilIdCheck,moduloIdCheck).subscribe(
                menusDisponivelParaAtribuicaoPerfil => {
                    this.menusDisponivelParaAtribuicaoPerfil = menusDisponivelParaAtribuicaoPerfil;
                    this.isWait = false;
                }, () => this.isWait = false

            );
    }

    desvincularMenu(sistemaModuloDTO: SistemaModuloDTO): void{
        const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '600px',
            data: { label: sistemaModuloDTO.menuDescricao }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.perfilService.desvincularMenu(sistemaModuloDTO).subscribe(
                    () => {
                        this.carregarMenusDisponivelParaAtribuicaoPerfil(sistemaModuloDTO.idPerfil, sistemaModuloDTO.idMenu);
                        this.carregarMenusAtribuidoPerfil(sistemaModuloDTO.idPerfil);
                        this.snackBarService.showSuccess('Registro removido com sucesso!');
                    },
                    () => {
                        this.snackBarService.showError('Erro ao adicionar menu!');
                    }
                );
            }
        });
    }

    vincularMenu(sistemaModuloDTO: SistemaModuloDTO): void {
        // const dialogRef = this.dialog.open(DialogConfirmacaoComponent, {
        const dialogRef = this.dialog.open(DialogAdicionarPerfilMenuComponent, {
            width: '600px',
            // data: { sistemaModuloDTO: sistemaModuloDTO }
            data:  sistemaModuloDTO
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                        this.carregarMenusDisponivelParaAtribuicaoPerfil();
                        this.carregarMenusAtribuidoPerfil();

            }
        });
    }



}
