import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { NavbarComponent } from 'app/layout/components/navbar/navbar.component';
import { MatButtonModule, MatIconModule } from '@angular/material';
import { NavbarVerticalModule } from './vertical/style-1/style-1.module';

@NgModule({
    declarations: [
        NavbarComponent
    ],
    imports     : [
        FuseSharedModule,
        MatButtonModule,
        MatIconModule,
        
        NavbarVerticalModule
    ],
    exports     : [
        NavbarComponent
    ]
})
export class NavbarModule
{
}
